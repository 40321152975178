import React from "react";
import s from "../assets/scss/fieldsGrid.module.scss";

export default function FieldsGrid({ items = [{ icon: "", text: "" }] }) {
  return (
    <div className={s.fieldsGrid}>
      {items.map((item, i) => {
        return (
          <div className={s.itemWrapper} key={i}>
            <div className={s.iconWrapper}>
              <img className={s.icon} src={item.icon} alt="Icon" />
            </div>
            <h5 className={s.text}>{item.text}</h5>
          </div>
        );
      })}
    </div>
  );
}
