import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import Hero from "../../../components/Hero";
import ImageWithText from "../../../components/ImageWithText";
import FeatureCards from "../../../components/FeatureCards";
import { alignmentType } from "../../../utils/types";
import FooterNav from "../../../components/FooterNav";
import { appRoutes } from "../../../utils/routes";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";

import bannerImgV from "../../../assets/img/hero/dell-sunucu-cozumleri.jpg";
import imgMiddle from "../../../assets/img/dell-sunucu-alt.jpg";

export default function DellServer() {
  const cards = [
    {
      title: { part1: "Uyarlanabilir", part2: "Bilgi İşlem" },
      text: "İhtiyaç anında kolayca ölçeklenebilen, en son teknolojik gelişmeleri optimize etmek için özel olarak tasarlanmış bir sunucu platformudur.",
    },
    {
      title: { part1: "Otonom Bilgi", part2: "İşlem Altyapısı" },
      text: "Birlikte ve bağımsız olarak çalışan, belirlenen parametrelere uygun akıllı sistemlerle iş fırsatlarına daha hızlı yanıt verebilme.",
    },
    {
      title: { part1: "Proaktif", part2: "Dayanıklılık" },
      text: "Güvenli etkileşimler ve olası tehditleri tahmin etme yeteneği için özel olarak tasarlanan altyapı ile güvenli dijital dönüşüm.",
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Donanım Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.hardware.children);
    navStore.setTitle("Diğer donanım çözümlerini inceleyin");
    navStore.setNavTitle("Diğer donanım çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.hardware.children.dellServer.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        title={
          <>
            Dell Sunucu <br /> Çözümleri
          </>
        }
        desc={
          <>
            Dell Technologies, tasarımdan itibaren tüm tedarik zinciri ve tüm
            yaşam döngüsü boyunca güvenliği ön planda tutan sunucu çözümleri
            sunuyor.
          </>
        }
        bottom={{
          title: (
            <>
              Dell Technologies, sunucu portföyü genelinde kriptografik olarak
              doğrulanmış donanım bütünlüğü, dinamik sistem kilitleme, güçlü
              firma yazılımı ve önyükleme koruması sağlayan{" "}
              <span>tek sunucu üreticisidir.</span>
            </>
          ),
          text: {
            part1: null,
            part2: null,
          },
        }}
      ></Hero>
      <ImageWithText
        image={imgMiddle}
        alignment={alignmentType.left}
        singleText={
          <>
            En basit iş yüklerinden en karmaşık iş yüklerine kadar tüm
            ihtiyaçları karşılıyor ve kesintisiz çalışma süresi sağlıyor. Üst
            düzey ölçeklenebilir işlemci, bellek ve dahili depolama alanı ile
            sektörün en güçlü sunucularını işletmelerin hizmetine sunuyor.
          </>
        }
      ></ImageWithText>
      <FeatureCards
        greeting={"Dell PowerEdge Sunucu Çözümlerinin Avantajları"}
        items={cards}
      ></FeatureCards>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer donanım çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.hardware.children}
          hideIndex={
            appRoutes.solutions.children.hardware.children.dellServer.type
          }
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
