import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";
import FooterNav from "../../../components/FooterNav";
import Hero from "../../../components/Hero";
import ProductInfo from "../../../components/ProductInfo";
import { appRoutes } from "../../../utils/routes";

import shape from "../../../assets/img/shapes/shape-email.svg";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";

import bannerImgV from "../../../assets/img/hero/eposta-guvenligi.jpg";
import bannerVideo from "../../../assets/video/hero/eposta-guvenligi.mp4";

export default function EmailSecurity() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Siber Güvenlik Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.cyberSecurity.children);
    navStore.setTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setNavTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.cyberSecurity.children.emailSecurity.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        video={bannerVideo}
        title={
          <>
            Eposta Güvenliği <br /> Çözümleri
          </>
        }
        desc={
          <>
            Fidye yazılımları ve diğer zararlı yazılımları sistemlere
            bulaştırmak için siber saldırganların en sık kullandığı yöntemlerden
            biri epostalardır.
          </>
        }
        bottom={{
          title: (
            <>
              İstenmeyen epostalar çalışanların verimliliğinin ve üretkenliğinin
              azalmasına neden olur.
            </>
          ),
          text: {
            part1: (
              <>
                Bu nedenle istenmeyen postalar, fidye yazılımları ve diğer
                zararlı yazılımların sistemlere bulaşmasını önlemek için e-posta
                güvenliği çözümleri işletmeler açısından kritik önem taşıyor.
              </>
            ),
            part2: null,
          },
        }}
      ></Hero>
      <ProductInfo
        title={<>Symantec Messaging Gateway</>}
        desc={
          <>
            İş eposta güvenliğinin ihlal edilmesi, fidye yazılımı ve istenmeyen
            eposta gibi tehditleri önler.
          </>
        }
        texts={{
          part1: (
            <>
              Üst düzey tehdit koruması ve istenmeyen postaları önleme
              özellikleriyle Symantec Messaging Gateway çözümü, işletmelerin
              mesajlaşma ortamlarını güvenli hale getirmelerine olanak tanıyor.
            </>
          ),
          part2: (
            <>
              Ayrıca gönderenin kimliğinin doğrulanması, yazım hataları ve
              kimlik sahtekarlıklarını önlemek için yapay zekayı kullanan
              sistemiyle, işletmelerin iletişimlerini güvenli bir şekilde devam
              ettirebilmelerini ve itibarlarını korumalarını da sağlar.
            </>
          ),
        }}
        icon={shape}
      ></ProductInfo>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer siber güvenlik çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.cyberSecurity.children}
          hideIndex={
            appRoutes.solutions.children.cyberSecurity.children.emailSecurity
              .type
          }
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
