import s from "./assets/scss/app.module.scss";

import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import DetailLayout from "./pages/DetailLayout";
import MagnetCursor from "./components/MagnetCursor";
// import Preloader from "./components/Preloader";

import { Route, Routes, useLocation } from "react-router-dom";
import { appRoutes } from "./utils/routes";
import { WithSmooth } from "./hocs/WithSmooth";

import {
  HomeCyberSecurity,
  Antivirus,
  DataBreach,
  EmailSecurity,
  TrackingAndLog,
  TwoStepVerification,
  Encryption,
  HomeNetwork,
  CiscoNetwork,
  HomeHardware,
  DellHyper,
  DellServer,
  DellStorage,
  HomeVirtual,
  Desktop,
  Infrastructure,
  VRealizeAndCloud,
  HomeManagement,
  FileOrbis,
} from "./pages/solutions/";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { Advisory, Recruitment, Setup, Technical } from "./pages/services";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import {
  BackupExec,
  DataInsights,
  EDiscovery,
  EnterpriseVault,
  EnterpriseVaultCloud,
  InfoScale,
  MergeOne,
  NetBackup,
  NetBackupAppliance,
  NetBackupItAnalytics,
  NetBackupSaasProtection,
  HomeVeritas,
  VeritasLayout,
} from "./pages/veritas";
import { useEffect, useRef, useState } from "react";
import gsap, { Power1 } from "gsap";
import StickyNav from "./components/StickyNav";
import Modal from "./components/Modal";
import { useModalStore } from "./stores/modalStore";
import { AnimatePresence } from "framer-motion";
import useWindowSize from "./hooks/useWindowSize";
import { breakpointTypes } from "./utils/types";
import Preloader from "./components/Preloader";
import NotFound from "./pages/NotFound";
import ZohoLiveChat from "./components/ZohoLiveChat";

function App() {
  // SOLUTIONS
  const solutions = appRoutes.solutions;
  const cyberSecurity = solutions.children.cyberSecurity;
  const network = solutions.children.network;
  const hardware = solutions.children.hardware;
  const virtual = solutions.children.virtual;
  const management = solutions.children.management;

  // SERVICES
  const services = appRoutes.services;
  const setup = services.children.setup;
  const recruitment = services.children.recruitment;
  const technical = services.children.technical;
  const advisory = services.children.advisory;

  // VERITAS
  const veritas = appRoutes.veritas;
  const availability = veritas.children.availability;
  const compliance = veritas.children.compliance;
  const dataProtection = veritas.children.dataProtection;
  // const veritasIndex = veritas.children.index;
  const insights = veritas.children.insights;

  const appRef = useRef(null);
  const windowSize = useWindowSize();
  const { pathname } = useLocation();

  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);

  const modal = useModalStore();

  useEffect(() => {
    const transitionElements = Array.from(
      document.querySelectorAll("[data-route-transition]")
    );

    if (location !== displayLocation) {
      gsap.to(transitionElements, {
        autoAlpha: 0,
        duration: 0.4,
        scale: () => (windowSize.width > breakpointTypes.tablet ? 0.995 : 1),
        ease: Power1.easeInOut,

        onComplete: () => {
          window.scrollTo(0, 0);

          gsap.to(transitionElements, {
            autoAlpha: 1,
            scale: 1,
            duration: 0.4,
            delay: 0.6,
            ease: Power1.easeInOut,
          });
          setDisplayLocation(location);
        },
      });
    }
  }, [location, displayLocation, windowSize.width]);

  useEffect(() => {
    const handleLiveChat = () => {
      const zoho = document.querySelector("#zsiqbtn");

      console.log(
        window.pageYOffset,
        document.body.scrollHeight - window.innerHeight,
        document.body.scrollHeight,
        window.innerHeight
      );
      if (
        window.pageYOffset + window.innerHeight >
        document.body.scrollHeight - window.innerHeight
      ) {
        zoho.style.opacity = "0";
        zoho.style.visibility = "hidden";
      } else {
        zoho.style.opacity = "1";
        zoho.style.visibility = "visible";
      }
    };

    document.addEventListener("scroll", handleLiveChat);
    return () => {};
  }, []);

  return (
    <>
      <WithSmooth location={displayLocation}>
        <div className={s.app} ref={appRef} data-route-transition>
          <Header />
          <Routes location={displayLocation}>
            <Route index element={<Home />} />
            <Route path={appRoutes.about.path} element={<About />} />
            <Route path={appRoutes.contact.path} element={<Contact />} />
            <Route path="blog" element={<Blog />}></Route>
            <Route path="blog/:blogId" element={<BlogDetail />} />

            {/* CYBERSECURITY */}
            <Route path={cyberSecurity.path} element={<DetailLayout />}>
              <Route index element={<HomeCyberSecurity />} />
              <Route
                path={cyberSecurity.children.antivirus.path}
                element={<Antivirus />}
              />
              <Route
                path={cyberSecurity.children.dataBreach.path}
                element={<DataBreach />}
              />
              <Route
                path={cyberSecurity.children.emailSecurity.path}
                element={<EmailSecurity />}
              />
              <Route
                path={cyberSecurity.children.trackingAndLog.path}
                element={<TrackingAndLog />}
              />
              <Route
                path={cyberSecurity.children.twoStepVerification.path}
                element={<TwoStepVerification />}
              />
              <Route
                path={cyberSecurity.children.encryption.path}
                element={<Encryption />}
              />
            </Route>

            {/* NETWORK */}
            <Route path={network.path} element={<DetailLayout />}>
              <Route index element={<HomeNetwork />} />
              <Route
                path={network.children.ciscoNetwork.path}
                element={<CiscoNetwork />}
              />
            </Route>

            {/* HARDWARE */}
            <Route path={hardware.path} element={<DetailLayout />}>
              <Route index element={<HomeHardware />} />
              <Route
                path={hardware.children.dellServer.path}
                element={<DellServer />}
              />
              <Route
                path={hardware.children.dellStorage.path}
                element={<DellStorage />}
              />
              <Route
                path={hardware.children.dellHyper.path}
                element={<DellHyper />}
              />
            </Route>

            {/* VIRTUAL */}
            <Route path={virtual.path} element={<DetailLayout />}>
              <Route index element={<HomeVirtual />} />
              <Route
                path={virtual.children.desktop.path}
                element={<Desktop />}
              />
              <Route
                path={virtual.children.infrastructure.path}
                element={<Infrastructure />}
              />
              <Route
                path={virtual.children.vRealizeAndCloud.path}
                element={<VRealizeAndCloud />}
              />
            </Route>

            {/* MANAGEMENT */}
            <Route path={management.path} element={<DetailLayout />}>
              <Route index element={<HomeManagement />} />
              <Route
                path={management.children.fileOrbis.path}
                element={<FileOrbis />}
              />
            </Route>

            {/* SERVICES */}
            <Route path={services.path}>
              <Route path={setup.path} element={<DetailLayout />}>
                <Route index element={<Setup />} />
              </Route>

              <Route path={technical.path} element={<DetailLayout />}>
                <Route index element={<Technical />} />
              </Route>

              <Route path={advisory.path} element={<DetailLayout />}>
                <Route index element={<Advisory />} />
              </Route>

              <Route path={recruitment.path} element={<DetailLayout />}>
                <Route index element={<Recruitment />} />
              </Route>
            </Route>

            {/* VERITAS */}
            <Route path={veritas.path} element={<VeritasLayout />}>
              <Route index element={<HomeVeritas />} />

              {/* AVAILABILITY */}
              <Route path={"availability"}>
                <Route
                  path={availability.children.infoscale.path}
                  element={<InfoScale />}
                ></Route>
              </Route>

              {/* COMPLIANCE */}
              <Route path={"compliance"}>
                <Route
                  path={compliance.children.eDiscovery.path}
                  element={<EDiscovery />}
                ></Route>
                <Route
                  path={compliance.children.enterpriseVaultCloud.path}
                  element={<EnterpriseVaultCloud />}
                ></Route>

                <Route
                  path={compliance.children.enterpriseVault.path}
                  element={<EnterpriseVault />}
                ></Route>
                <Route
                  path={compliance.children.merge1.path}
                  element={<MergeOne />}
                ></Route>
              </Route>

              {/* DATA PROTECTION */}
              <Route path={"data-protection"}>
                <Route
                  path={dataProtection.children.backupExec.path}
                  element={<BackupExec />}
                ></Route>
                <Route
                  path={dataProtection.children.netBackup.path}
                  element={<NetBackup />}
                ></Route>
                <Route
                  path={dataProtection.children.netBackupAppliance.path}
                  element={<NetBackupAppliance />}
                ></Route>
                <Route
                  path={dataProtection.children.netBackupSaasProtection.path}
                  element={<NetBackupSaasProtection />}
                ></Route>
              </Route>

              {/* INSIGHTS */}
              <Route path={"insights"}>
                <Route
                  path={insights.children.dataInsights.path}
                  element={<DataInsights />}
                ></Route>
                <Route
                  path={insights.children.netBackupItAnalytics.path}
                  element={<NetBackupItAnalytics />}
                ></Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
          {!pathname.includes(appRoutes.contact.path) && <Footer />}
          {/* <Preloader></Preloader> */}
        </div>
      </WithSmooth>
      <StickyNav></StickyNav>
      <AnimatePresence>{modal.visible && <Modal></Modal>}</AnimatePresence>
      <Preloader></Preloader>
      <MagnetCursor />

    </>
  );
}

export default App;
