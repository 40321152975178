export const alignmentType = {
  left: "LEFT",
  center: "CENTER",
  right: "RIGHT",
};

export const productInfoType = {
  dualDesc: "DUAL_DESC",
  dualText: "DUAL_TEXT",
};

export const breakpointTypes = {
  mobile: 640,
  tabletMini: 768,
  tablet: 1024,
  desktop: 1440,
  widescreen: 1920,
};
