import React, { useEffect } from "react"

import GridIcons from "../../../components/GridIcons"
import HeroVeritas from "../../../components/HeroVeritas"
import HorizontalImageText from "../../../components/HorizontalImageText"
import PinnedInfo from "../../../components/PinnedInfo"
import TitleAndText from "../../../components/TitleAndText"

import enterpriseVaultCloud from "../../../assets/lottie/veritas/enterpriseVaultCloud.json"

import gurl from "../../../assets/img/veritas/backup-exec-banner.jpg"
import useBreadcrumbStore from "../../../stores/breadcrumbStore"

import graph from "../../../assets/icons/veritas/graph.svg"
import secure from "../../../assets/icons/veritas/secure.svg"
import whole from "../../../assets/icons/veritas/whole.svg"

import backup from "../../../assets/icons/veritas/backup.svg"
import multiDevice from "../../../assets/icons/veritas/multi-device.svg"
import recovery from "../../../assets/icons/veritas/recovery.svg"

import video from "../../../assets/video/bg-slider.mp4"

import infographic from "../../../assets/pdf/IG_ransomware_backup_exec_V1385.pdf"
import techDoc from "../../../assets/pdf/WP-ransomware_best_practice_V1008.pdf"

export default function BackupExec() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Data Protection", sub: "Backup Exec" })
  }, [setBc])

  const gridItems = [
    {
      title: { icon: graph, text: "Basit" },
      items:
        "Kullanımı kolay arabirim aracılığıyla tüm yedekleme ve kurtarma etkinliklerini hızla takip edin ve izleyin.",
    },
    {
      title: { icon: secure, text: "Güvenli" },
      items:
        "İki faktörlü kimlik doğrulama ile güvenliği katmanlı hale getirin ve kötü amaçlı kod enjeksiyonlarını önleyin.",
    },
    {
      title: { icon: whole, text: "Tümleşik" },
      items: "Aynı kullanıcı konsolu üzerinden VMware, Microsoft ve Linux platformlarını sorunsuz bir şekilde koruyun.",
    },
  ]

  const gridItems2 = [
    {
      title: { icon: backup, text: "NetBackup" },
      items: "Dünyanın 1 numaralı kurumsal yedekleme ve kurtarma çözümü.",
    },
    {
      title: {
        icon: multiDevice,
        text: "Masaüstü ve Dizüstü Bilgisayar Seçeneği",
      },
      items: "Günümüzün coğrafi olarak çeşitli iş yükleri için kanıtlanmış, otomatikleştirilmiş koruma.",
    },
    {
      title: { icon: recovery, text: "Sistem Kurtarma" },
      items:
        "Sunucular, masaüstü bilgisayarlar ve dizüstü bilgisayarlar için imaj tabanlı yedekleme ve olağanüstü durum kurtarma.",
    },
  ]

  const veritasSliderItems = [
    {
      title: "Microsoft 365 Güvenliği",
      media: null,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Exchange ve OneDrive koruma ve kurtarma",
          "Backup Exec ile tam entegrasyon",
          "Microsoft 365 verileri için özel koruma",
        ],
        btnText: null,
      },
    },
    {
      title: "Güvenlik ve Uyumluluk",
      media: null,
      pdf: infographic,
      detail: {
        text: null,
        list: [
          "Yedekleme verilerini fidye yazılımı saldırılarından korur",
          "Verilerin her konumda uyumlu olmasını sağlar",
          "Verilerin GDPR Guard ile uyumlu olmasını sağlar",
          "Kredi kartı işlem verilerini güvenli bir şekilde saklar",
        ],
        btnText: "İnfografiği İncele",
      },
    },
    {
      title: "Bulut ortamındaki verilerin korunması",
      media: null,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Hem AWS hem de Azure'a yönelik dağıtılabilir pazaryeri şablonları",
          "Tüm büyük bulut tedarikçileri için sertifikalı bulut bağlayıcıları",
          "Tüm AWS bulut depolama katmanları için destek",
          "Hat içi veri tekilleştirme ile optimize edilmiş depolama maliyetleri ve bant genişliği",
        ],
        btnText: null,
      },
    },
    {
      title: "Sanal İş Yükü Koruması",
      media: video,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Backup Exec Accelerator ile sonsuz artımlı sanal makine yedeklemeleri",
          "Genişletilmiş hiper tümleşik ortam desteği",
          "Yeni sanal makinelerin otomatik olarak bulunması ve korunması",
          "Sanal makinelerin anında kurtarılması",
        ],
        btnText: "Videoyu İzleyin",
      },
    },
    {
      title: "Akıllı Entegrasyonlar",
      media: null,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Veritas Usage Insights aracılığıyla tüm veri ekosisteminin konsolide raporları",
          "NetBackup SaaS Koruması ile Microsoft 365, Google Cloud ve Salesforce dahil olmak üzere SaaS iş yükleriniin ve uygulamalarının korunması",
          "Anında bulut tabanlı olağanüstü durum kurtarma için Azure Site Recovery ile entegrasyon",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={<>Veritas - Küçük ve orta ölçekli işletmelere yönelik veri yedekleme ve kurtarma.</>}
        lottie={enterpriseVaultCloud}
      ></HeroVeritas>
      <HorizontalImageText
        image={gurl}
        title={<>Verilerinizi fiziksel, sanal veya bulut ortamında nerede olursa olsun koruyun.</>}
        text={
          <>
            İşletmelerde yöneticilerin birçok işi üstlenmesi gerekiyor. Ancak yedekleme ve kurtarmanın, yöneticilerin
            üstlenmesi gereken işlerden biri olmaması gerekiyor. Backup Exec, iş açısından kritik verilerin asla
            kaybolma, çalınma veya bozulma riskiyle karşı karşıya kalmamasını sağlayan basit ve güçlü bir çözüm sunuyor.
            Ayrıca işletmelerin veri yönetimi ihtiyaçları arttıkça, Backup Exec bu ihtiyaçları karşılamak için sorunsuz
            bir şekilde ölçeklenebiliyor ve uzun vadede güven ve maliyet tasarrufu sağlıyor.
          </>
        }
      ></HorizontalImageText>
      <TitleAndText
        title={<>BackupExec, dünya genelinde 45 binin üzerinde işletme tarafından kullanılıyor.</>}
        text={<>Çok noktalı ürünlere olan ihtiyacı ortadan kaldırın ve siber dayanıklılığınızı güçlendirin.</>}
      ></TitleAndText>
      <GridIcons vertical={true} items={gridItems}></GridIcons>
      <PinnedInfo detailsHeight={"40vh"} items={veritasSliderItems}></PinnedInfo>
      <TitleAndText title={<>İş açısından kritik verileri tüm ortamlarda koruyun.</>}></TitleAndText>
      <GridIcons verticalTitle={true} items={gridItems2}></GridIcons>
      <TitleAndText
        smallText={<>Teknik Doküman</>}
        title={<>Backup Exec ile fidye yazılımlarına karşı direnci artırma.</>}
        btnText={<>Dokümanı İnceleyin</>}
        url={techDoc}
        bgColor={"#002855"}
        fontColor={"#ffffff"}
      ></TitleAndText>
    </>
  )
}
