import React, { useEffect, useRef } from "react"

import Scrollbar from "smooth-scrollbar"
import gsap from "gsap"
import { useResizeDetector } from "react-resize-detector"

import { useStickyNavStore } from "../stores/stickyNavStore"
import useWindowSize from "../hooks/useWindowSize"
import { breakpointTypes } from "../utils/types"

import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const WithSmooth = ({ children, location }) => {
  const scrollerRef = useRef(null)
  const selectorRef = useRef(null)
  const q = gsap.utils.selector(selectorRef)
  const { isOpen, toggle } = useStickyNavStore()
  const smooth = useRef(null)
  const windowSize = useWindowSize()

  const onResize = () => {
    ScrollTrigger.refresh()
  }

  useResizeDetector({ targetRef: selectorRef, onResize })

  const checkArray = (arr) => {
    return Array.isArray(arr) && arr.length !== 0
  }

  // ANIMATIONS
  const initAnimations = (smoothInstance) => {
    //Pinned Section
    if (windowSize.width > breakpointTypes.tablet) {
      if (checkArray(q("[data-pinned]"))) {
        let pinnedTL
        const pinned = document.querySelectorAll("[data-pinned]")
        const pinnedItems = document.querySelectorAll("[data-pinned-item]")
        const pinnedItemContents = document.querySelectorAll("[data-pinned-item-content]")
        const pinnedItemVideoWrappers = document.querySelectorAll("[data-pinned-video-wrapper]")

        const tl = (item, i) => {
          pinnedTL = gsap.timeline({
            scrollTrigger: {
              trigger: pinned,
              start: () => `top -${item.clientHeight * i}`,
              end: () => `+=${item.clientHeight}`,
              scrub: 1,
              invalidateOnRefresh: true,
              // markers: true,
            },
          })
        }

        pinnedItems?.forEach((item, i) => {
          if (i > 0) {
            gsap.set(item, {
              zIndex: i + 1,
              yPercent: () => (i === 0 ? -5 : 200),
            })

            tl(item, i)

            pinnedTL
              .add("fadeOut")
              .to(
                item,
                {
                  yPercent: 4 * i,
                },
                "fadeOut"
              )
              .to(
                pinnedItems[i - 1],
                {
                  opacity: 0.1 + i * 0.3,
                  scale: 0.8 + i * 0.05,
                },
                "fadeOut"
              )
              .to(
                pinnedItemContents[i - 1],
                {
                  opacity: 0,
                },
                "fadeOut"
              )
              .to(
                pinnedItemVideoWrappers[i - 1],
                {
                  opacity: 0,
                },
                "fadeOut"
              )
          }
        })

        ScrollTrigger.create({
          trigger: q("[data-pinned]"),
          scrub: true,
          pin: true,
          invalidateOnRefresh: true,
          start: () => "center center",
          end: () => "+=" + (q("[data-pinned-item]").length + 1) * q("[data-pinned-item]")[0].clientHeight,
          onEnter: () => {
            gsap.to("body", {
              background: "#002855",
            })
          },
          onLeave: () => {
            gsap.to("body", {
              background: "#ffffff",
            })
          },
          onEnterBack: () => {
            gsap.to("body", {
              background: "#002855",
            })
          },
          onLeaveBack: () => {
            gsap.to("body", {
              background: "#ffffff",
            })
          },
        })
      }
    }

    // HOME SPHERE
    if (q("[data-sphere-wrapper]").length !== 0) {
      gsap.set(q("[data-sphere-wrapper]"), {
        perspective: 600,
      })

      const tl = gsap.timeline({ paused: true })

      tl.add("s")
        .fromTo(
          q("[data-sphere-text]"),
          { rotateX: -10, rotateY: -10, scale: 0.7 },
          {
            rotateX: 35,
            rotateY: 50,
            scale: 1.1,
          },
          "s"
        )
        .fromTo(
          q("[data-sphere]"),
          { scale: 0.8, xPercent: -10, yPercent: 10 },
          { scale: 1.1, xPercent: 0, yPercent: 0 },
          "s"
        )

      ScrollTrigger.create({
        animation: tl,
        trigger: q("[data-sphere-wrapper]"),
        scrub: 1,
        // markers: true,
      })
    }

    // HOME ELLIPSIS
    if (q("[data-ellipsis]").length !== 0) {
      gsap.to(q("[data-ellipsis]"), {
        yPercent: -20,
        scrollTrigger: {
          trigger: q("[data-ellipsis]"),
          scrub: 1,
          // markers: true,
        },
      })
    }

    // LOGOS
    if (checkArray(q("[data-reference-logos]"))) {
      gsap.from(q("[data-company-logo]"), {
        yPercent: 300,
        scale: 1.5,
        stagger: 0.05,
        scrollTrigger: {
          trigger: q("[data-reference-logos]"),
          toggleActions: "play none none none",
          // markers: true,
        },
      })

      gsap.from(q("[data-logo-wr]"), {
        yPercent: 100,
        scale: 0,
        duration: 0.4,
        stagger: 0.05,
        scrollTrigger: {
          start: "start center",
          trigger: q("[data-reference-logos]"),
          toggleActions: "play none none none",
          // markers: true,
        },
      })
    }

    // CONTACT FORM BG
    if (q("[data-contact]").length) {
      gsap.from(q("[data-bg]"), {
        scaleY: 0,
        scrollTrigger: {
          trigger: q("[data-contact]"),
          scrub: 1,
          // markers: true,
        },
      })
    }

    // PARALLAX RECTS
    if (windowSize.width > breakpointTypes.mobile) {
      if (checkArray(q("[data-parallax-rect-inner-wrapper]"))) {
        gsap.set(q("[data-parallax-rect-inner]"), {
          scale: 1.2,
        })

        gsap.to(q("[data-parallax-rect-inner]"), {
          scale: 1,
          scrollTrigger: {
            trigger: q("[data-parallax-rect-inner-wrapper]"),
            scrub: 1,
            // markers: true,
          },
        })
      }

      if (checkArray(q("[data-parallax-rect-outer-wrapper]"))) {
        gsap.set(q("[data-parallax-rect-outer-wrapper]"), {
          yPercent: 0,
        })

        gsap.to(
          q("[data-parallax-rect-outer-wrapper]"),

          {
            yPercent: -20,
            scrollTrigger: {
              trigger: q("[data-parallax-rect-outer-wrapper]"),
              scrub: 1,
              // markers: true,
            },
          }
        )
      }
    }

    // REVEAL WRAPPER
    if (checkArray(q("[data-reveal]")) && windowSize.width > breakpointTypes.tablet) {
      const height = document.querySelector("[data-reveal]").clientHeight

      gsap.set(q("[data-reveal]"), {
        yPercent: -50,
      })

      gsap.set(q("[data-overlay]"), {
        opacity: 0.99,
      })

      gsap.to(q("[data-reveal]"), {
        translateZ: -100,
        yPercent: 0,
        ease: "none",
        scrollTrigger: {
          trigger: q("[data-reveal]"),
          start: "center bottom",
          end: () => `center bottom-=${height}`,
          scrub: true,
          // markers: true,
        },
      })

      gsap.to(q("[data-overlay]"), {
        translateZ: -100,
        opacity: 0,
        ease: "none",
        scrollTrigger: {
          trigger: q("[data-reveal]"),
          start: "center bottom",
          end: () => `center bottom-=${height}`,
          scrub: true,
          onEnter: () => {
            gsap.to(q("[data-overlay]"), {
              pointerEvents: "auto",
            })
          },
          onLeave: () => {
            gsap.to(q("[data-overlay]"), {
              pointerEvents: "none",
            })
          },
          onEnterBack: () => {
            gsap.to(q("[data-overlay]"), {
              pointerEvents: "auto",
            })
          },
          onLeaveBack: () => {
            gsap.to(q("[data-overlay]"), {
              pointerEvents: "none",
            })
          }, // markers: true,
        },
      })
    }

    // MARQUEE
    if (checkArray(q("[data-marquee-slide]"))) {
      let currentScroll = 0
      let isScrollingDown = true

      gsap.set(q("[data-marquee-slide]"), { xPercent: -100 })
      const marqueeTL = gsap
        .to(q("[data-marquee-slide-inner]"), {
          xPercent: -100,
          repeat: -1,
          duration: 20,
          ease: "linear",
        })
        .totalProgress(0.5)

      const handleDirChange = (offset) => {
        isScrollingDown = offset.y || offset > currentScroll ? true : false

        gsap.to(marqueeTL, {
          timeScale: isScrollingDown ? -1 : 1,
        })

        currentScroll = offset.y || offset
      }

      if (smoothInstance) {
        smoothInstance?.addListener(({ offset }) => {
          handleDirChange(offset)

          gsap.to(q("[data-marquee-slide]"), {
            x: offset.y.toFixed(2) + "px",
            ease: "linear",
          })
        })
      } else {
        document.addEventListener("scroll", () => {
          handleDirChange(window.pageYOffset)

          gsap.to(q("[data-marquee-slide]"), {
            x: window.pageYOffset + "px",
            ease: "linear",
          })
        })
      }
    }

    // PARALLAX ITEMS
    if (windowSize.width > breakpointTypes.mobile) {
      if (checkArray(q("[data-parallax]"))) {
        const parallaxItems = q("[data-parallax]")

        parallaxItems.forEach((item) => {
          gsap.to(item, {
            yPercent: () =>
              -100 *
              (item.dataset.speed ? item.dataset.speed : 0.4) *
              (item.dataset.direction ? item.dataset.direction : 1),
            scrollTrigger: {
              trigger: item,
              scrub: true,
            },
          })
        })
      }
    }

    // SCALING IMG
    if (windowSize.width > breakpointTypes.mobile) {
      if (checkArray(q("[data-scaling-img-wrapper]")) && checkArray(q("[data-scaling-img]"))) {
        const itemWrappers = q("[data-scaling-img-wrapper]")
        const items = q("[data-scaling-img]")

        gsap.set(q("[data-scaling-img]"), {
          scale: 1.2,
        })

        itemWrappers.forEach((item, i) => {
          gsap.to(items[i], {
            scale: 1,
            scrollTrigger: {
              trigger: item,
              scrub: 1.1,
            },
          })
        })

        // gsap.set(q("[data-scaling-img-wrapper]"), {
        //   xPercent: 10,
        // });

        // itemWrappers.forEach((item, i) => {
        //   gsap.to(item, {
        //     xPercent: -10,
        //     scrollTrigger: {
        //       yPercent: 0,
        //       trigger: item,
        //       scrub: 3,
        //       markers: true,
        //     },
        //   });
        // });
      }
    }

    // PINNED INFO
    // if (
    //   checkArray(q("[data-pinned-info]")) &&
    //   checkArray(q("[data-pinned-info-item]")) &&
    //   checkArray(q("[data-pinned-info-detail]"))
    // ) {
    //   gsap.set(q("[data-pinned-info-item]"), {
    //     opacity: 0.2,
    //   });

    //   q("[data-pinned-info-detail]").forEach((detail, i) => {
    //     gsap.set(detail, {
    //       opacity: 0,
    //     });
    //   });

    //   gsap.set(q("[data-pinned-info-indicator]"), {
    //     transformOrigin: "0 0",
    //     scaleY: 0,
    //   });

    //   q("[data-pinned-info-item]").forEach((item, i) => {
    //     gsap.to(item, {
    //       scrollTrigger: {
    //         anticipatePin: true,
    //         pinSpacer: true,
    //         start: () =>
    //           `top+=${
    //             (windowSize.height / q("[data-pinned-info-item]").length) * i
    //           } center`,
    //         end: `top+=${
    //           (windowSize.height / q("[data-pinned-info-item]").length) *
    //           (i + 1)
    //         } center`,
    //         trigger: item,
    //         scrub: true,
    //         // markers: true,
    //         onEnter: () => {
    //           gsap.to(item, {
    //             opacity: 1,
    //           });
    //           gsap.to(q("[data-pinned-info-detail]")[i], {
    //             opacity: 1,
    //           });
    //         },
    //         onLeave: () => {
    //           gsap.to(item, {
    //             opacity: 0.2,
    //           });
    //           gsap.to(q("[data-pinned-info-detail]")[i], {
    //             opacity: 0,
    //           });
    //         },
    //         onEnterBack: () => {
    //           gsap.to(item, {
    //             opacity: 1,
    //           });
    //           gsap.to(q("[data-pinned-info-detail]")[i], {
    //             opacity: 1,
    //           });
    //         },
    //         onLeaveBack: () => {
    //           gsap.to(item, {
    //             opacity: 0.2,
    //           });
    //           gsap.to(q("[data-pinned-info-detail]")[i], {
    //             opacity: 0,
    //           });
    //         },
    //       },
    //     });
    //   });

    //   ScrollTrigger.create({
    //     anticipatePin: true,
    //     trigger: q("[data-pinned-info]"),
    //     scrub: true,
    //     pin: true,
    //     start: "center center",
    //     snap: 1 / (3 * 2 - 1),
    //     // pinSpacing: true,
    //     // invalidateOnRefresh: true,
    //     // markers: true,
    //   });
    // }
  }

  useEffect(
    (smoothInstance = smooth.current) => {
      const stickyItem = document.querySelector("[data-sticky-item]")
      const footerNav = document.querySelector("[data-footer-nav]")

      let once = true

      const controlVisibility = () => {
        console.log("TODO: REMOVE LISTENER")
        if (footerNav && smoothInstance.isVisible(footerNav)) {
          if (once) {
            if (stickyItem) stickyItem.style.opacity = 0
            if (isOpen) toggle()
            once = false
          }
        } else {
          if (!once) {
            if (stickyItem) stickyItem.style.opacity = 1
            once = true
          }
        }
      }

      if (stickyItem) {
        smoothInstance?.addListener(controlVisibility)
      } else {
        smoothInstance?.removeListener(controlVisibility)
      }

      // TODO: REMOVE LISTENER

      // const handleSticky = (smoothInstance) => {
      //   // STICKY ITEM
      // };

      // handleSticky(smooth.current);

      return () => {
        smoothInstance?.removeListener(controlVisibility)
      }
    },
    [isOpen, smooth.current]
  )

  useEffect(() => {
    if (windowSize.width <= breakpointTypes.tablet) {
      initAnimations(null)
      return
    }

    // Init smooth scroll
    const scroller = scrollerRef.current

    if (smooth.current) {
      smooth.current.destroy()
      console.log("Smooth Destroyed")
    }

    smooth.current = Scrollbar.init(scroller, {
      damping: 0.088,
      delegateTo: document,
      alwaysShowTracks: true,
      renderByPixels: true,
    })

    console.log("Smooth Initialized")

    ScrollTrigger.scrollerProxy("[data-scroller]", {
      scrollTop(value) {
        if (arguments.length) {
          smooth.current.scrollTop = value
        }
        return smooth.current.scrollTop
      },
    })

    smooth.current.addListener(ScrollTrigger.update)
    ScrollTrigger.defaults({ scroller: scroller })

    // detect overflow-x
    const docWidth = smooth.current.limit.x
    // console.log(docWidth);
    ;[].forEach.call(document.querySelectorAll("*"), function (el) {
      if (el.offsetWidth > docWidth) {
        console.log("overflow x", el)
      }
    })

    initAnimations(smooth.current)

    // Only necessary to correct marker position - not needed in production
    if (document.querySelector(".gsap-marker-scroller-start")) {
      const markers = gsap.utils.toArray('[class *= "gsap-marker"]')

      smooth.current?.addListener(({ offset }) => {
        gsap.set(markers, { marginTop: -offset.y })
      })
    }

    const clearAnims = () => {
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill()
      })

      // This in case a scroll animation is active while the route is updated
      gsap.killTweensOf(window)
    }

    return () => {
      clearAnims()
      // smooth.current?.destroy();
      // console.log("Smooth Destroyed");
    }
  }, [location, windowSize.width])

  useEffect(() => {
    smooth.current?.scrollTo(0, 0, 0)
  }, [location])

  return (
    <div ref={selectorRef}>
      {windowSize.width >= breakpointTypes.tablet ? (
        <div data-scroller ref={scrollerRef}>
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export { WithSmooth }
