import React, { useState } from "react";
import s from "../assets/scss/imageWithText.module.scss";

import { alignmentType } from "../utils/types";
import videoPlay from "../assets/img/play.svg";
import { useRef } from "react";

export default function ImageWithText({
  alignment = alignmentType.right,
  title = "",
  text = "",
  singleText = "",
  image = "",
  icon = "",
  video = "",
}) {
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideo = () => {
    console.log(videoRef.current);

    if (videoRef.current.playing) {
      setIsPlaying(false);
      videoRef.current.pause();
    } else if (videoRef.current.paused) {
      setIsPlaying(true);
      videoRef.current.play();
    } else {
      setIsPlaying(true);
      videoRef.current.play();
    }
  };

  return (
    <section
      className={`${s.imageWithText} ${
        alignment === alignmentType.left
          ? s.left
          : alignment === alignmentType.center
          ? s.center
          : alignment === alignmentType.right
          ? s.right
          : ""
      }`}
    >
      <div className={s.mediaWrapper}>
        {video ? (
          <div className={s.videoWrapper}>
            <div
              className={s.thumb}
              style={{
                transition: "0.4s all ease",
                opacity: isPlaying ? "0" : "1",
                visibility: isPlaying ? "hidden" : "visible",
              }}
            >
              <img
                className={s.play}
                src={videoPlay}
                alt="Thumbnail"
                onClick={handleVideo}
              />
            </div>
            <video
              ref={videoRef}
              className={s.video}
              src={video}
              controls
              style={{
                opacity: !isPlaying ? "0" : "1",
                visibility: !isPlaying ? "hidden" : "visible",
              }}
            />
          </div>
        ) : (
          <div className={s.imgWrapper} data-scaling-img-wrapper>
            <img
              data-scaling-img
              className={s.img}
              src={image}
              alt="Technology Company"
            />
          </div>
        )}

        {icon && (
          <div className={s.iconWrapper}>
            <img
              data-parallax
              data-speed="0.4"
              src={icon}
              alt="Parallax Visual"
              className={s.icon}
            />
          </div>
        )}
      </div>
      <div className={s.textWithTitle}>
        <h3 className={s.title}>{title}</h3>
        <p className={s.text}>{text}</p>
      </div>
      <p className={s.singleText}>{singleText}</p>
    </section>
  );
}
