import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import s from "../assets/scss/dropdown.module.scss";

import cx from "classnames";

const Dropdown = forwardRef(
  (
    {
      options = [{ name: "" }],
      handleF,
      defaultOption = "",
      icon = "",
      contactPage = false,
    },
    ref
  ) => {
    const dropdownHeaderRef = useRef(null);
    const dropdownBodyRef = useRef(null);

    const [isOpen, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const toggleDropdown = () => setOpen(!isOpen);

    useImperativeHandle(ref, () => ({
      closeDropdown() {
        setOpen(false);
      },
      resetDropdown() {
        setSelectedItem("");
        handleItemClick("");
        setOpen(false);
      },
      updateDropdown(item) {
        setSelectedItem(item);
      },
    }));

    const handleItemClick = (name) => {
      selectedItem !== name && setSelectedItem(name);
      handleF(name);
      toggleDropdown();
    };

    useEffect(() => {
      const closeOpenMenus = (e) => {
        if (
          dropdownBodyRef.current &&
          isOpen &&
          !dropdownBodyRef.current.contains(e.target) &&
          !dropdownHeaderRef.current.contains(e.target)
        ) {
          setOpen(false);
        }
      };

      document.addEventListener("mousedown", closeOpenMenus);

      return () => {
        document.removeEventListener("mousedown", closeOpenMenus);
      };
    }, [isOpen]);

    return (
      <>
        {options && (
          <div className={cx(s.dropdown, { [s.contactPage]: contactPage })}>
            <div
              className={s.dropdownHeader}
              onClick={toggleDropdown}
              ref={dropdownHeaderRef}
            >
              <p className={s.headerText}>
                {selectedItem
                  ? options.find((item) => item.name === selectedItem).name
                  : defaultOption}
              </p>
              <div
                className={s.headerIcon}
                style={{
                  transition: "0.4s transform ease",
                  transform: isOpen ? "rotateX(-180deg)" : "rotateX(0)",
                }}
              >
                {icon}
              </div>
            </div>
            <div
              ref={dropdownBodyRef}
              className={cx(s.dropdownBody, { [s.open]: isOpen })}
              onBlur={() => {
                setOpen(false);
              }}
            >
              <div className={s.bg}></div>
              <div
                className={cx(s.dropdownItem, s.defaultOption)}
                onClick={() => {
                  setSelectedItem("");
                  handleItemClick("");
                  toggleDropdown();
                }}
              >
                {defaultOption}
              </div>
              {options &&
                options.map((option, i) => (
                  <div
                    className={s.dropdownItem}
                    onClick={() => handleItemClick(option.name)}
                    key={i}
                  >
                    {option.name}
                  </div>
                ))}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default Dropdown;
