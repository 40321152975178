import React, { useEffect } from "react"

import GridIcons from "../../../components/GridIcons"
import HeroVeritas from "../../../components/HeroVeritas"
import HorizontalImageText from "../../../components/HorizontalImageText"
import PinnedInfo from "../../../components/PinnedInfo"
import TitleAndText from "../../../components/TitleAndText"

import enterpriseVaultCloud from "../../../assets/lottie/veritas/enterpriseVaultCloud.json"

import gurl from "../../../assets/img/veritas/saas-banner.jpg"
import useBreadcrumbStore from "../../../stores/breadcrumbStore"

import flex from "../../../assets/icons/veritas/flex.svg"
import operational from "../../../assets/icons/veritas/operational.svg"
import durability from "../../../assets/icons/veritas/durability.svg"

import technicalDocument from "../../../assets/pdf/WP_nsp_security_overview_V1328.pdf"

export default function NetBackupSaasProtection() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Data Protection", sub: "NetBackup SaaS Protection" })
  }, [setBc])

  const gridItems = [
    {
      title: { icon: flex, text: "Esnek Destek" },
      items: "Yerel konnektörlerle popüler SaaS ortamlarını tüm ölçeklerde kolayca destekleyin.",
    },
    {
      title: { icon: operational, text: "Operasyonel Verimlilik" },
      items: "Basit kontroller ve modern kullanıcı arayüzü, dağıtımı, uyumluluğu ve kurtarmayı kolaylaştırır.",
    },
    {
      title: { icon: durability, text: "Siber Dayanıklılık" },
      items: "Sıfıra yakın RPO’lar ve RTO’lar ile iş açısından kritik verilerin kolayca kurtarılmasını sağlayın.",
    },
  ]

  const veritasSliderItems = [
    {
      title: "Kapsamlı kurtarma",
      media: null,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Öğeleri, klasörleri, posta kutularını veya siteleri ayrıntılı, çok düzeyli kurtarma ile geri yükleyin",
          "Tek bir işlemle birden çok posta kutusunu eski durumuna getirin",
          "Verileri tercih edilen bir konuma (orijinal konumu, başka bir bulut konumu veya şirket içi) kurtarın",
          "Ek bir etkin lisans bulundurmaya gerek kalmadan bir çalışanın ayrılmasından sonra Microsoft 365 hesaplarında depolanan verilere erişimi koruyun",
          "Anlık geri yüklemeler veya güvenli bağlantı üzerinden kopyalama seçeneği ile fidye yazılımlarının veya veri kayıplarının kurbanı olmaktan kaçının",
        ],
        btnText: null,
      },
    },
    {
      title: "Performans ve ölçeklenebilirlik",
      media: null,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Bir günde terabaytlarca veriyi hızlıca toplayın",
          "Petabaytlarca veriye ve milyarlarca nesneye ölçeklenebilen yedekleme deposu",
          "Artımlı yedekleme işlerini daha sık çalıştırın, hem kurtarma noktası hedeflerini (RPO'lar) hem de kurtarma süresi hedeflerini (RTO'lar) azaltın",
          "Site koleksiyonları için sürekli veri korumasıyla (gerçek zamanlıya yakın yedeklemeler) artımlı yedeklemeleri kullanın",
          "Birden çok bölge ve etki alanı genelinde ölçeklendirin",
        ],
        btnText: null,
      },
    },
    {
      title: "Kurumsal düzeyde güçlü güvenlik",
      media: null,
      pdf: technicalDocument,
      detail: {
        text: "Kullanılmayan depolama alanını belirlemek ve geri almak için binlerce benzersiz veri noktası toplayın.",
        list: [
          "Tek kiracılı mimari",
          "Azure Active Directory (AD) ile sıkı entegrasyon",
          "Uçtan uca şifreleme, yedekleme verileri aktarım sırasında ve beklemedeyken şifrelenir",
          "Çok ayrıntılı rol tabanlı erişim denetimi (RBAC), hangi kullanıcılara hangi veriler üzerinde hangi ayrıcalıkların verileceğini belirleyin",
          "Ek özellikler arasında IP adresi izin verilenler ve izin verilmeyenler listeleri, özel VNet, erişim kontrol listeleri (ACL'ler) ve çok faktörlü kimlik doğrulama bulunur",
        ],
        btnText: "İlgili Teknik Dökümanı İndirin",
      },
    },
    {
      title: "Otomatik uyumluluk yaptırımları",
      media: null,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Tüm yedekleme verileri, SOC-2 Tip II uyumlu veri merkezlerindeki SOC 2 uyumlu bir platformda (değişiklik veya silmeyi önleyen) değişmez depolamada depolanır",
          "Anahtar kelimeler, ifadeler, yakınlık veya alaka düzeyi sıralaması gibi birden çok faktöre dayalı aramaların yanı sıra Boolean ve/veya normal ifadeler dahil olmak üzere gelişmiş keşif yetenekleri",
          "Veri egemenliği düzenlemelerine uymayı kolaylaştıran otomatik veri yerleşimi kontrolleri",
          "Verilerin gerekli zaman dilimi boyunca korunmasını sağlayan, çok sayıda özelleştirilebilir kritere dayalı esnek saklama politikaları",
          "Aynı anda birden fazla veri kaynağında arama yapma ve hızlı ve kolay bir şekilde yasal bekletme süreleri uygulama",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={<>Veritas - SaaS uygulama verileri için çok katmanlı koruma.</>}
        lottie={enterpriseVaultCloud}
      ></HeroVeritas>
      <HorizontalImageText
        imgH
        image={gurl}
        title={<>Kritik SaaS uygulamalarının siber dayanıklılığını artırın.</>}
        text={
          <>
            SaaS tedarikçileri, kendi bulut tabanlı verilerini korumanın ve güvenceye almanın işletmelerin
            sorumluluğunda olduğu anlamına gelen bir “paylaşılan sorumluluk modelini” benimsemişlerdir. NetBackup SaaS
            Protection, tek bir sezgisez arayüz aracılığıyla önde gelen SaaS iş uygulamalarına yönelik tam olarak
            yönetilen, uygun maliyetli, otomatikleştirilmiş bir BaaS (Backup as a Service) hizmeti sağlayan tümleşik
            veri yönetimi ve koruma çözümü sunuyor.
          </>
        }
      ></HorizontalImageText>
      <TitleAndText
        title={<>Bulut yatırımlarının değerini en üst düzeye çıkarın.</>}
        text={<>Veritas’ın entegre yaklaşımı, performans ve maliyet tasarrufu sağlıyor.</>}
      ></TitleAndText>
      <GridIcons vertical={true} items={gridItems}></GridIcons>
      <PinnedInfo items={veritasSliderItems}></PinnedInfo>
      <TitleAndText
        title={<>Altyapınızdaki iş yüklerini koruyun ve yönetin.</>}
        text={
          <>
            NetBackup SaaS Protection, Microsoft 365, Google Workspace, Box ve Slack gibi önde gelen SaaS iş
            uygulamalarına sorunsuz bir şekilde entegre oluyor. Veritas’ın entegre ürün portföyü benzersiz bir esneklik
            ve kontrol sağlıyor.
          </>
        }
      ></TitleAndText>
    </>
  )
}
