import React, { useEffect } from "react"
import GridIcons from "../../../components/GridIcons"

import HeroVeritas from "../../../components/HeroVeritas"
import HorizontalImageText from "../../../components/HorizontalImageText"
import PinnedInfo from "../../../components/PinnedInfo"
import TitleAndText from "../../../components/TitleAndText"

import lottie from "../../../assets/lottie/veritas/enterpriseVault.json"
// import gurl from "../../../assets/img/veritas/enterprise-vault-banner.jpg";

import server from "../../../assets/icons/veritas/server.svg"
import archive from "../../../assets/icons/veritas/archive.svg"
import publicIcon from "../../../assets/icons/veritas/public.svg"
import find from "../../../assets/icons/veritas/find.svg"

import finance from "../../../assets/icons/veritas/finance.svg"
import medicine from "../../../assets/icons/veritas/medicine.svg"
import energy from "../../../assets/icons/veritas/energy.svg"
import useBreadcrumbStore from "../../../stores/breadcrumbStore"

export default function EnterpriseVault() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Compliance", sub: "Enterprise Vault" })
  }, [setBc])

  const gridItems = [
    {
      title: { icon: server, text: "Her Şeyi Toplayın" },
      items: "Sosyal medya, ekip işbirliği, e-posta, anlık mesajlaşma, sohbet, sesli iletişim ve daha fazlası.",
    },
    {
      title: { icon: archive, text: "Her Yerde Arşivleyin" },
      items:
        "Geniş dağıtım seçeneği yelpazesi: Şirket içi, karma, kendi kiracınızda dağıtma ve yerel çok kiracılı SaaS.",
    },
    {
      title: { icon: find, text: "Önemli Olan Şeyleri Bulun" },
      items: "Denetim, gizlilik ve yasal zorunluluklar için ilgili verileri hızla bulun.",
    },
  ]

  const gridItems2 = [
    {
      title: { icon: finance, text: "Finans" },
      items: "SEC, FINRA, CFTC, SOX, APRA, MiFID II, IIROC, ESMA",
    },
    {
      title: { icon: medicine, text: "İlaç ve Sağlık" },
      items: "FDA, HIPPA, FDCA, ARRA",
    },
    {
      title: { icon: publicIcon, text: "Kamu ve Özel" },
      items: "FOIA, FISMA, CCPA, GDPR",
    },
    {
      title: { icon: energy, text: "Enerji" },
      items: "FERC, DOE, NERC, CFTC, Eyalet Regülasyonları",
    },
  ]

  const veritasSliderItems = [
    {
      title: "İleri Düzey Denetim",
      media: null,
      pdf: null,
      detail: {
        text: "Bu güçlü yeni uyumluluk aracı, elektronik iletişimlerin izlenmesiyle ilişkili maliyetleri ve çabaları azaltıyor.",
        list: [
          "Kullanıcıların belirli verileri daha iyi tanımlamasına olanak tanıyan 200'den fazla politika ve 1.000'den fazla önceden oluşturulmuş düzen içeren sınıflandırmaya dayalı denetim",
          "Dil algılama ve duygu analizi için yapay zeka ile akıllı inceleme",
          "İnceleme sırasında öğeleri alaka düzeyi açısından etiketleme ve acil uyarılarla başa çıkmak için önem derecesini artırmaya yönelik yerleşik iş akışları",
        ],
        btnText: null,
      },
    },
    {
      title: "120’nin Üzerinde İçerik Kaynağı",
      media: null,
      pdf: null,
      detail: {
        text: "Enterprise Vault, endüstrinin en güçlü besleme motoru Veritas Merge1 ile entegre oluyor.",
        list: [
          "Eşsiz görünürlük ve kontrol sağlayan Merge1 ile tam entegrasyon",
          "Sosyal medya, ekip işbirliği, anlık mesajlaşma ve hatta sesli iletişim kaynakları",
          "Düzenli olarak eklenen yeni içerik kaynakları ve işlevler",
        ],
        btnText: null,
      },
    },
    {
      title: "Kritik Bilgileri Sınıflandırın",
      media: null,
      pdf: null,
      detail: {
        text: "İçerik verilerini sınıflandırın, bağlamlandırın ve buna göre arşivleyin.",
        list: [
          "Yüzlerce önceden yapılandırılmış politika ve düzenden faydalanın",
          "Denetimi, aramayı ve bulmayı hızlandırmak için öğeleri meta verilerle etiketleyin",
          "Kişisel verileri, hassas verileri, COVID-19 verilerini, fidye yazılımlarını ve daha fazlasını bulun",
        ],
        btnText: null,
      },
    },
    {
      title: "Esnek Dağıtım",
      media: null,
      pdf: null,
      detail: {
        text: "Enterprise Vault, verileri herhangi bir ortamda arşivleme esnekliği sağlar.",
        list: [
          "Verileri şirket içinde, genel bulutta veya hibrit bir yapılandırma kullanarak saklayın",
          "Mevcut şirket içi depolamadan yararlanın ve ölçeklendirme için verileri buluta taşıyın",
          "Veri depolama yerini ve süresini belirleyen politikaları tanımlayın",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={<>Veritas - Kolay bulma için otomatik uyumluluk arşivlemesi.</>}
        lottie={lottie}
      ></HeroVeritas>
      <HorizontalImageText
        video
        imgH
        title={<>Regülasyonlarla ilgili ihtiyaçları karşılar.</>}
        text={
          <>
            Enterprise Vault, tüm iletişim platformları genelinde bilgileri toplar, bu bilgileri şirket içinden buluta
            sorunsuz bir şekilde geçirir ve uyumluluğu sağlamak için en alakalı içerikleri otomatik olarak tanımlar.
            Dünya genelinde üst düzey regülasyonlara tabi işletmeler, derin içgörüler ve eyleme dönüştürülebilir zeka
            için Enterprise Vault’a güveniyor.
          </>
        }
      ></HorizontalImageText>
      <TitleAndText title={<>Uyumluluğu kolay ve doğru bir şekilde optimize edin.</>}></TitleAndText>
      <GridIcons vertical={true} items={gridItems}></GridIcons>
      <PinnedInfo detailsHeight={"40vh"} items={veritasSliderItems}></PinnedInfo>
      <TitleAndText
        smallText={<>Gartner Peer Insights Customers’ Choice (Müşterinin Seçimi)</>}
        title={
          <>
            Gartner Peer Insights’da Veritas, Kurumsal Bilgi Arşivleme kategorisinde 2021 Customers’ Choice ödülüne hak
            kazandı.
          </>
        }
        btnText={<>Raporu Okuyun</>}
        bgColor={"#002855"}
        fontColor={"#ffffff"}
      ></TitleAndText>
      <TitleAndText
        title={<>Önemli düzenleyici kurumlarla uyumluluk sağlayın.</>}
        text={
          <>
            Kurumsal yönetişim için iletişimleri koruyun, yasal BT için e-keşif araştırmalarını destekleyin ve diğer tüm
            sektör regülasyonlarını karşılayın.
          </>
        }
      ></TitleAndText>
      <GridIcons items={gridItems2}></GridIcons>
    </>
  )
}
