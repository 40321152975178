import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import Hero from "../../../components/Hero";
import ImageWithText from "../../../components/ImageWithText";
import FooterNav from "../../../components/FooterNav";

import { alignmentType } from "../../../utils/types";
import { appRoutes } from "../../../utils/routes";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";

import bannerImgV from "../../../assets/img/hero/dell-donanim-cozumleri.jpg";
import imgMiddle from "../../../assets/img/donanim-alt.jpg";

export default function Hardware() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Donanım Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.hardware.children);
    navStore.setTitle("Donanım çözümlerini inceleyin");
    navStore.setNavTitle("Donanım çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.network.children.index.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        title={
          <>
            Donanım <br /> Çözümleri
          </>
        }
        desc={
          <>
            Günümüzde teknolojiler eskisinden çok daha hızlı gelişiyor ve
            değişiyor. Bu, dizüstü bilgisayarlardan sunuculara kadar altyapının
            daha kısa sürelerle yenilenmesi anlamına geliyor.
          </>
        }
        bottom={{
          title: (
            <>
              İş sürekliliği açısından büyük önem taşıyan sunucular, dizüstü
              bilgisayarlar, masaüstü bilgisayarlar ve diğer donanımların
              ihtiyaçlar doğrultusunda doğru bir şekilde belirlenmesi ve
              seçilmesi gerekmektedir.
            </>
          ),
          text: {
            part1: null,
            part2: null,
          },
        }}
      ></Hero>
      <ImageWithText
        image={imgMiddle}
        alignment={alignmentType.right}
        title={
          <>
            Çok fazla çeşitliliğin olduğu ve böylesine kritik olan bir konuda
            doğru seçimin yapılması uzmanlık gerektiriyor.
          </>
        }
        text={
          <>
            Ateon, yapılan performans analizleri sonucunda ortaya çıkan
            ihtiyaçlar doğrultusunda işletmeler için en uygun donanım
            çözümlerini belirliyor ve işletmelerin kendi işlerine odaklanarak
            zamandan ve maliyetten tasarruf etmelerine yardımcı oluyor. Bu
            sayede işletmelerin toplam sahip olma maliyetlerini önemli ölçüde
            azaltıyor ve BT yaşam döngüsü boyunca teknoloji giderlerini
            hafifletiyor.
          </>
        }
      ></ImageWithText>
      <RevealWrapper>
        <FooterNav
          title={<>Donanım çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.hardware.children}
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
