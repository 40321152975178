import React from "react"
import s from "../../assets/scss/veritasHome.module.scss"

import cx from "classnames"

import HeroVeritas from "../../components/HeroVeritas"
import enterpriseVaultCloud from "../../assets/lottie/veritas/enterpriseVaultCloud.json"
import gurl from "../../assets/img/veritas/veritas-main-alt.jpg"
import heroImg from "../../assets/img/veritas-hero.png"
import parallaxRect from "../../assets/img/veritas-home-rect.png"
import parallaxLock from "../../assets/img/veritas-home-lock.png"
import parallaxBar from "../../assets/img/veritas-home-bar.png"

export default function VeritasHome() {
  return (
    <>
      <HeroVeritas
        title={<>Veritas - Birleşik Veri Yönetiminin Gücü</>}
        subTitle={
          <>
            Tüm kurumsal verilerinizi ve uygulamalarınızı uçtan çekirdeğe ve çoklu buluta kadar her ölçekte sorunsuz bir
            şekilde yönetin ve koruyun.
          </>
        }
        lottie={enterpriseVaultCloud}
        bcVisible={false}
        titleL={true}
      ></HeroVeritas>
      <div className={s.hero}>
        <div className={s.imgWrapper} data-scaling-img-wrapper>
          <img className={s.img} src={heroImg} alt="Person with Laptop" data-scaling-img />
        </div>

        <div className={cx(s.iconWrapper, s.rect)} data-parallax data-speed="0.3">
          <img className={s.icon} src={parallaxRect} alt="" />
        </div>

        <div className={cx(s.iconWrapper, s.lock)} data-parallax data-speed="0.4">
          <img className={s.icon} src={parallaxLock} alt="" />
        </div>

        <div className={cx(s.iconWrapper, s.bar)} data-parallax data-speed="1.5">
          <img className={s.icon} src={parallaxBar} alt="" />
        </div>
      </div>
      <div className={s.detail}>
        <p className={s.textL}>
          Dünyanın önde gelen teknoloji şirketlerinden biri olan Veritas, kurumsal müşterilerine son 30 yılda meydana
          gelen tüm teknolojik yıkıcı değişimlerde rehberlik etti.
        </p>
        <div className={s.imgWithText}>
          <div className={s.imgWrapper} data-scaling-img-wrapper>
            <img className={s.img} src={gurl} alt="Girl With Laptop" data-scaling-img />
          </div>

          <div className={s.textWrapper}>
            <p className={s.textS}>
              Veri yönetimi ve korumasına yönelik entegre yaklaşımıyla müşterilerine eşsiz çok yönlülük, yüksek
              performans ve maliyet tasarrufu sağladı. <strong>Fortune Global 500</strong> şirketlerinin{" "}
              <strong>yüzde 87’si</strong> Veritas çözümlerini kullanıyor ve müşterilerine en kapsamlı teknoloji
              ekosistemini sunuyor. Müşterilerin karşılaştıkları ve karşılaşabilecekleri büyük veri zorluklarının
              kolayca üstesinden gelmelerine olanak tanıyor.
            </p>
            <p className={s.textS}>
              Veritas, küçükten büyüğe tüm şirketlerin iş görev <strong>kritik verilerini</strong> yönetmenin ve
              korumanın karmaşıklığını yenmesine yardımcı olmak için benzersiz çözümler sunuyor. Entegre ürün portföyü
              ile uç noktalardan veri merkezlerine ve buluta kadar{" "}
              <strong>yüksek performanslı ve çok yönlü tümleşik bir veri yönetimi deneyimi sunuyor.</strong>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
