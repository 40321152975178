import React, { useEffect } from "react";
import s from "../assets/scss/animatedLogo.module.scss";

import { ReactComponent as AteonLogo } from "../assets/img/ateon-logo-header.svg";
import { ReactComponent as InfoTech } from "../assets/img/information-technologies.svg";
import gsap, { Expo } from "gsap";
import { useRef } from "react";
import useLogoStore from "../stores/logoStore";

export default function AnimatedLogo() {
  const animatedLogoRef = useRef(null);
  const q = gsap.utils.selector(animatedLogoRef);
  const logoAnimated = useLogoStore((state) => state.animated);
  const logoTL = useRef(null);

  useEffect(() => {
    if (sessionStorage.getItem("preloaderShown" || "")) {
      return;
    }

    const easeFunc = Expo.easeInOut;

    gsap.set(
      [
        ...q("[data-name='h-c-a']"),
        ...q("[data-name='h-c-t']"),
        ...q("[data-name='h-c-e']"),
        ...q("[data-name='h-c-o']"),
        ...q("[data-name='h-c-n']"),
        ...q("[data-name='info-tech']"),
      ],
      {
        yPercent: 200,
      }
    );

    const delay = -1;

    logoTL.current = gsap
      .timeline({ paused: true })
      .add("s")
      .to(
        [
          ...q("[data-name='h-c-a']"),
          ...q("[data-name='h-c-t']"),
          ...q("[data-name='h-c-e']"),
          ...q("[data-name='h-c-o']"),
          ...q("[data-name='h-c-n']"),
        ],
        {
          yPercent: 0,
          delay,
          stagger: 0.05,
          duration: 2,
          ease: easeFunc,
        },
        "s"
      )
      .to(
        q("[data-info-tech]"),
        {
          yPercent: 0,
          delay: () => delay + 1.75,
          duration: 1,
          ease: easeFunc,
          onComplete: () => {
            sessionStorage.setItem("preloaderShown", true);
          },
        },
        "s"
      );
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("preloaderShown" || "")) {
      return;
    }

    if (logoAnimated) logoTL.current.play();
  }, [logoAnimated]);

  return (
    <div className={s.animatedLogo} ref={animatedLogoRef}>
      <div className={s.oWrapper1}>
        <AteonLogo className={s.logo}></AteonLogo>
      </div>
      <div className={s.oWrapper2}>
        <InfoTech data-info-tech className={s.infoTech}></InfoTech>
      </div>
    </div>
  );
}
