import React from "react";
import s from "../assets/scss/heroVeritas.module.scss";
import BreadCrumb from "./BreadCrumb";

import Lottie from "lottie-react";
import cx from "classnames";

import veritasTextlogo from "../assets/img/veritas-text-logo.svg";

export default function HeroVeritas({
  title = null,
  titleL = null,
  subTitle = null,
  lottie = null,
  bcVisible = true,
}) {
  return (
    <section className={s.heroVeritas}>
      <div className={s.top}>
        <div className={s.left}>
          <div className={s.iconWrapper}>
            <img className={s.icon} src={veritasTextlogo} alt="Veritas Logo" />
          </div>
          <BreadCrumb
            visible={bcVisible}
            color="#1A1818"
            bordered={false}
          ></BreadCrumb>
          {title && (
            <h1 className={cx(s.title, { [s.titleL]: titleL })}>{title}</h1>
          )}
          {subTitle && <h2 className={s.subTitle}>{subTitle}</h2>}
        </div>
        {lottie && (
          <div className={s.lottieWrapper}>
            <Lottie
              autoplay={true}
              className={s.lottie}
              animationData={lottie}
              loop={false}
            />
          </div>
        )}
      </div>
      <div className={s.bottom}></div>
    </section>
  );
}
