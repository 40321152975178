import React, { useEffect } from "react";
import s from "../../assets/scss/services.module.scss";

import HeroServices from "../../components/HeroServices";
import OrderedGrid from "../../components/OrderedGrid";
import SolutionsList from "../../components/SolutionsList";
import ContactUs from "../../components/ContactUs";
import FooterNav from "../../components/FooterNav";
import { appRoutes } from "../../utils/routes";
import RevealWrapper from "../../components/RevealWrapper";
import useBreadcrumbStore from "../../stores/breadcrumbStore";
import TextL from "../../components/TextL";
import cubeWhite from "../../assets/video/cube-white.mp4";
import { useStickyNavStore } from "../../stores/stickyNavStore";

import heroImg from "../../assets/img/hero-services/kurulum.jpg";

export default function Setup() {
  const gridItems = [
    {
      text: "Dizüstü ve masaüstü bilgisayarların kurulumu ve yönetimi",
      visible: true,
    },
    {
      text: "Sunucu kurulumu ve yönetimi",
      visible: true,
    },
    {
      text: "Sanallaştırma çözümleri",
      clip: "topRight",
      visible: true,
    },
    {
      text: "Yedekleme ünitelerinin kurulumu ve yönetimi",
      visible: true,
    },
    {
      text: "Anahtar (Switch), Yönlendiriciler (Router) ve Erişim Noktaları dahil olmak üzere kablolu ve kablosuz ağ donanımlarının ve ağların kurulumu ve yönetimi",
      visible: true,
    },
    {
      text: "Güvenlik duvarı, Antivirus, IPS ve diğer güvenlik donanımları ve yazılımlarının kurulumu ve yönetimi",
      visible: true,
    },
    {
      text: "Depolama çözümlerinin kurulumu ve yönetimi",
      visible: true,
    },
    {
      text: "Olağanüstü durum kurtarma ürün ve çözümlerinin kurulumu ve yönetimi",
      visible: true,
    },
    {
      text: "Veri kurtarma",
      clip: "bottomRight",
      visible: true,
    },
  ];

  const solutions = [
    {
      title: "Proje Hazırlığı",
      desc: "Proje hazırlığı kapsamında işletmenin BT ihtiyaçlarını belirledikten sonra uygun ürün ve çözümlerin tespit edilebilmesi için analiz kısmına geçiliyor.",
    },
    {
      title: "Analiz & Planlama",
      desc: "Analiz kısmında ihtiyaçlar ve mevcut durum ayrıntılı bir şekilde ele alındıktan sonra projelendirme çalışması yapılarak planlama adımı başlıyor.",
    },
    {
      title: "Uyarlama",
      desc: "Ürün ve çözümlerin belirlenen plan dahilinde kurulumu yapılıyor ve şirket süreçlerine uyarlanıyor.",
    },
    {
      title: "Proje Teslimi",
      desc: "Kurulum tamamlandıktan ve sistemlerin sorunsuz bir şekilde çalışması sağlandıktan sonra proje teslim ediliyor.",
    },
    {
      title: "Destek",
      desc: "İşletmenin isteği doğrultusunda bakım ve destek hizmetleri de Ateon tarafından yapılabiliyor.",
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Hizmetler" });
    navStore.setChildren(appRoutes.services.children);
    navStore.setTitle("Diğer hizmetlerimizi inceleyin");
    navStore.setNavTitle("Diğer hizmetlerimizi inceleyin");
    navStore.setHiddenChild(appRoutes.services.children.setup.type);
  }, [setBc]);

  return (
    <main className={s.services}>
      <HeroServices
        image={heroImg}
        title={<>Kurulum Hizmetleri</>}
        video={cubeWhite}
        desc={
          <>
            Donanımlardan yazılımlara BT altyapısının kesintisiz ve düzgün
            çalışabilmesi için ürün ve çözümlerin proje kapsamında doğru bir
            şekilde kurulumu ve işler hale getirilmesi büyük önem taşıyor.
          </>
        }
        bottom={{
          title: (
            <>
              Tecrübeli çalışanlarımız, güçlü altyapımız ve sektör lideri
              ürünlerle kurulumları dahil olmak üzere uçtan uca anahtar teslimi
              çözümler sunuyoruz.
            </>
          ),
          texts: {
            part1: (
              <>
                Ateon olarak İster tek seferlik, ister uzun süreli projeler
                olsun, ağ donanımlarından sunuculara, depolama sistemlerinden
                güvenlik çözümlerine, buluta, yedekleme ve olağanüstü durum
                kurtarmaya kadar tek bir ürünün ya da eksiksiz bir çözümün
                sorunsuz bir şekilde kullanıma alınmasını sağlayan kurulum
                hizmetleri sunuyoruz.
              </>
            ),
            part2: null,
          },
        }}
      ></HeroServices>
      <SolutionsList
        preText={
          <>
            BT altyapısında ve süreçlerinde yapılan iyileştirmelerin faydaları
          </>
        }
        items={solutions}
        leftAligned={true}
      ></SolutionsList>
      <OrderedGrid
        preText={<>Geniş kapsamda kurulum hizmetleri verdiğimiz alanlar.</>}
        items={gridItems}
        chess={true}
        row={3}
        col={3}
      ></OrderedGrid>
      <TextL
        items={[
          <>
            Şirketiniz için en iyi fiyat ve performansa sahip, ihtiyaçlarınıza
            en uygun çözümlerin kurulumu ve yönetimi için bizimle iletişime
            geçebilir ve uzman ekiplerimizden profesyonel destek alabilirsiniz.
          </>,
        ]}
      ></TextL>
      <RevealWrapper>
        <ContactUs
          text={
            <>
              Her biri sektör lideri tedarikçilerimizin ürünleriyle en karmaşık
              sunuculara kadar tüm ihtiyaçlarınızı eksiksiz bir şekilde
              karşılayabiliyoruz.
            </>
          }
        ></ContactUs>
      </RevealWrapper>
      <FooterNav
        title={<>Diğer hizmetlerimizi inceleyin</>}
        items={appRoutes.services.children}
        hideIndex={appRoutes.services.children.setup.type}
      ></FooterNav>
    </main>
  );
}
