import React from "react";
import s from "../assets/scss/titleAndText.module.scss";
import AnimatedButton from "./AnimatedButton";

export default function TitleAndText({
  smallText = null,
  title = null,
  text = null,
  btnText = null,
  bgColor = "#ffffff",
  fontColor = "#1A1818",
  url = "",
}) {
  return (
    <section className={s.titleAndText} style={{ background: bgColor }}>
      <div className={s.textWrapper}>
        {smallText && (
          <small className={s.small} style={{ color: fontColor }}>
            {smallText}
          </small>
        )}
        {title && (
          <h3
            className={s.title}
            style={{ color: fontColor, marginBottom: !text && "0" }}
          >
            {title}
          </h3>
        )}
        {text && (
          <p className={s.text} style={{ color: fontColor }}>
            {text}
          </p>
        )}
      </div>
      {btnText && (
        <a href={url} target="_blank" rel="noreferrer" className={s.btnWrapper}>
          <AnimatedButton text={btnText} color={fontColor}></AnimatedButton>
        </a>
      )}
    </section>
  );
}
