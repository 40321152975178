import create from "zustand";

const useStore = create((set, get) => ({
  nav: {
    children: null,
    hiddenChild: null,
    title: null,
    navTitle: null,
  },
  isOpen: false,
  setChildren: (children) =>
    set((state) => (state.nav = { ...state.nav, children })),
  setHiddenChild: (hiddenChild) =>
    set((state) => (state.nav = { ...state.nav, hiddenChild })),
  setTitle: (title) => set((state) => (state.nav = { ...state.nav, title })),
  setNavTitle: (navTitle) =>
    set((state) => (state.nav = { ...state.nav, navTitle })),
  toggle: (state) => set({ isOpen: !get().isOpen }),
}));

export const useStickyNavStore = useStore;
