import React, { useEffect } from "react";
import s from "../assets/scss/modal.module.scss";

import { useModalStore } from "../stores/modalStore";
import IconClose from "./Icon/IconClose";

import { motion } from "framer-motion";
import { useRef } from "react";

export default function Modal() {
  const modalRef = useRef(null);
  const modal = useModalStore();

  const close = () => {
    modal.setVisibility(false);
  };

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: {
      duration: 0.6,
      ease: "easeInOut",
    },
  };

  const disableScroll = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const ref = modalRef.current;

    ref.addEventListener("wheel", disableScroll);
    document.body.style.overflow = "hidden";

    return () => {
      ref.removeEventListener("wheel", (e) => e.preventDefault());
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <motion.div
      ref={modalRef}
      key={modal.visible}
      {...animation}
      className={s.modal}
    >
      <div className={s.bg} onClick={close}></div>
      <div className={s.close} onClick={close}>
        <IconClose fill={"#ffffff"}></IconClose>
      </div>
      <div className={s.content}>{modal.content}</div>
    </motion.div>
  );
}
