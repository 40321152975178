import React, { useEffect, useRef } from "react";
import s from "../assets/scss/animatedButton.module.scss";

import gsap, { Power4 } from "gsap";
import useCursorStore from "../stores/cursorStore";
import { CustomEase } from "gsap/all";

import cx from "classnames";
import useWindowSize from "../hooks/useWindowSize";
import { breakpointTypes } from "../utils/types";

gsap.registerPlugin(CustomEase);

export default function AnimatedButton({
  text = "",
  color = "#1a1818",
  sm = false,
}) {
  const btnRef = useRef(null);
  const btnTL = useRef(null);
  let q = gsap.utils.selector(btnRef);
  CustomEase.create("bp", ".16,1,.3,.1");

  const windowSize = useWindowSize();

  useEffect(() => {
    btnTL.current = gsap.timeline({ paused: true });
    const easeFunc = Power4.easeInOut;

    btnTL.current
      .add("s")
      .to(
        q("[data-icon-wrapper]"),
        {
          scale: 1.2,
          duration: 0.4,
          ease: easeFunc,
        },
        "s"
      )
      .to(
        q("[data-icon-wrapper]"),
        {
          background: "#00509d",
          border: "1px solid #00509d",
          duration: 0.2,
          ease: easeFunc,
        },
        "s"
      )
      .to(
        q("[data-icon]"),
        {
          autoAlpha: 0,
          duration: 0.2,
          ease: easeFunc,
        },
        "s"
      )
      .fromTo(
        q("[data-icon-2]"),
        {
          xPercent: -100,
          scale: 1,
          autoAlpha: 0,
        },
        {
          xPercent: 0,
          scale: 1.2,
          autoAlpha: 1,
          duration: 0.4,
          ease: easeFunc,
        },
        "s"
      )
      .to(
        q("[data-text]"),
        {
          xPercent: 10,
          duration: 0.4,
          ease: easeFunc,
        },
        "s"
      );
  }, []);

  const hideCursor = useCursorStore((state) => state.hide);

  const animate = (e) => {
    btnTL.current.play();
    hideCursor();

    // const rect = e.currentTarget.getBoundingClientRect();
    // console.log(e.currentTarget);
    // gsap.to(q("[data-button-bg]"), {
    //   x: e.clientX - rect.left,
    //   y: e.clientY - rect.top,
    //   scale: 90,
    //   duration: 10,
    // });
  };

  const animateR = () => {
    btnTL.current.reverse();
    hideCursor();

    // gsap.to(q("[data-button-bg]"), {
    //   scale: 0,
    //   x: 0,
    //   y: 0,
    // });
  };

  return (
    <div
      className={cx(s.btn, {
        [s.sm]: sm && windowSize.width > breakpointTypes.tablet,
      })}
      ref={btnRef}
    >
      <div
        className={s.iconWrapper}
        data-icon-wrapper
        onMouseEnter={
          windowSize.width > breakpointTypes.tablet ? animate : null
        }
        onMouseLeave={
          windowSize.width > breakpointTypes.tablet ? animateR : null
        }
        style={{ border: `1px solid ${color}` }}
      >
        <span
          data-button-bg
          className={s.bg}
          // style={{
          //   position: "fixed",
          //   top: `${mousePosition.clientY}px`,
          //   left: `${mousePosition.clientX}px`,
          // }}
        ></span>
        <svg
          className={s.icon}
          data-icon
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 12.717 12.717"
        >
          <path
            id="Path_6643"
            data-name="Path 6643"
            d="M.914,8.993H8.808a.17.17,0,0,0,.183-.153V.945A.2.2,0,0,0,8.808.763H7.071v5L1.311,0,0,1.342,5.761,7.1h-5V8.841a.144.144,0,0,0,.152.153Z"
            transform="translate(0 6.358) rotate(-45)"
            fill={color || "#000"}
            fillRule="evenodd"
          />
        </svg>
        <svg
          className={s.icon2}
          data-icon-2
          xmlns="http://www.w3.org/2000/svg"
          width="12.717"
          height="12.717"
          viewBox="0 0 12.717 12.717"
        >
          <path
            id="Path_6643"
            data-name="Path 6643"
            d="M.914,8.993H8.808a.17.17,0,0,0,.183-.153V.945A.2.2,0,0,0,8.808.763H7.071v5L1.311,0,0,1.342,5.761,7.1h-5V8.841a.144.144,0,0,0,.152.153Z"
            transform="translate(0 6.358) rotate(-45)"
            fill="#ffffff"
            fillRule="evenodd"
          />
        </svg>
        {/* <img src={arrow} alt="Arrow" className={s.icon} data-icon />
        <img src={arrow} alt="Arrow" className={s.icon2} data-icon-2 /> */}
      </div>
      <div className={s.textWrapper}>
        <p className={s.text} data-text style={{ color: color }}>
          {text}
        </p>
      </div>
    </div>
  );
}
