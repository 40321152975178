import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import shape from "../../../assets/img/shapes/shape-boxes.svg";

import Hero from "../../../components/Hero";
import ProductInfo from "../../../components/ProductInfo";
import TechDetails from "../../../components/TechDetails";
import SolutionsList from "../../../components/SolutionsList";
import FooterNav from "../../../components/FooterNav";
import RevealWrapper from "../../../components/RevealWrapper";

import { productInfoType } from "../../../utils/types";
import { appRoutes } from "../../../utils/routes";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";

import bannerImgV from "../../../assets/img/hero/sanallastirma-masaustu.jpg";
import tech1 from "../../../assets/img/masaustu_alt_1.jpg";
import tech2 from "../../../assets/img/masaustu_alt_2.jpg";
import tech3 from "../../../assets/img/masaustu_alt_3.jpg";

export default function Desktop() {
  const details = [
    {
      image: tech1,
      title: { part1: <>Sanal Masaüstü Altyapısı</>, part2: <>(VDI)</> },
      text: [
        <>
          Masaüstü bilgi işlem modelini, şirket içi veri merkezlerinde veya
          bulut ortamında sanal makinelerde çalışan sanal masaüstü oturumları
          olarak simüle ediyor. Bu modeli kullanmayı tercih eden işletmeler,
          masaüstü sanallaştırma sunucusunu, şirket içindeki diğer uygulama
          sunucuları gibi yönetebiliyor.
        </>,
        <>
          <strong>
            Tüm son kullanıcı bilgi işlem yükleri, kullanıcılardan veri
            merkezine geri taşındığından, VDI oturumlarını çalıştırmak için
            sunucuların ilk kurulumu, son kullanıcı cihazlarını sürekli yenileme
            gibi ihtiyaçları ortadan kaldıran önemli bir yatırım olarak öne
            çıkıyor.
          </strong>
        </>,
      ],
    },
    {
      image: tech2,

      title: { part1: <>Uzak Masaüstü Hizmetleri</>, part2: <> (RDS)</> },
      text: [
        <>
          Tam işlevli bir <strong>Windows, Mac</strong> veya{" "}
          <strong>Linux</strong> masaüstü yerine sınırlı sayıda uygulamanın
          sanallaştırılması gerektiğinde kullanılan bir çözümdür.
        </>,
        <>
          Bu modelde uygulamalar, kendi işletim sistemini çalıştıran yerel
          cihaza aktarılır.
          <strong>
            Yalnızca uygulamalar sanallaştırıldığından RDS sistemleri sanal
            makine başına daha yüksek bir kullanıcı yoğunluğu sunabiliyor.
          </strong>
        </>,
      ],
    },
    {
      image: tech3,

      title: { part1: <>Hizmet Olarak Masaüstü</>, part2: <> (DaaS)</> },
      text: [
        <>
          Masaüstü sanallaştırma yükünü servis sağlayıcılara kaydırıyor ve{" "}
          <strong>
            işletmelerin üzerindeki BT yükünü büyük ölçüde azaltıyor.
          </strong>
        </>,
        <>
          BT giderlerini sermaye giderlerinden operasyonel giderlere taşımak
          isteyen işletmeler için büyük bir fırsat sağlıyor.
        </>,
      ],
    },
  ];

  const advantages = [
    {
      title: <>Kolay Görüntü (image) Yönetimi</>,
      desc: (
        <>
          Masaüstü görüntülerini Horizon ortamlarında, şirket içi altyapıda ve
          bulut ortamında merkezi olarak yönetip dağıtmak, sanal masaüstü ve
          uygulama görüntüleri oluşturma ve bakımını yapma süresini ve
          maliyetlerini azaltıyor. Güncellemeleri veya görüntü geri almaları
          kolayca düzenlenebiliyor, görüntü değişiklikleri takip edilebiliyor ve
          görüntülerin birden çok konuma çoğaltılması otomatik hale geliyor.
        </>
      ),
    },
    {
      title: <>Uygulama Yönetimi</>,
      desc: (
        <>
          Uygulamayı bir kere paketleyerek hem şirket içi altyapıda hem de
          bulutta birden çok Horizon ortamında dağıtılabiliyor. Bu, uygulama
          dağıtımını basitleştirirken görüntü (image) sayısını azaltıyor ve
          karmaşıklığı ortadan kaldırıyor.
        </>
      ),
    },
    {
      title: <>Merkezi İzleme</>,
      desc: (
        <>
          Kullanıcı oturumları, sanal masaüstleri ve uygulamaların durumu gerçek
          zamanlı olarak takip edilebiliyor ve tüm bunlar merkezi olarak
          yönetilebiliyor.
        </>
      ),
    },
    {
      title: <>Çoklu İşletim Sistemi Desteği</>,
      desc: (
        <>
          Horizon ile Windows ve Linux kaynakları birden fazla veri merkezi
          üzerinden kolayca sunulabiliyor.
        </>
      ),
    },
    {
      title: <>Esnek Hibrit ve Çok Bulutlu Dağıtım Seçenekleri</>,
      desc: (
        <>
          Masaüstleri ve uygulamalar, Microsoft Azure, VMware Cloud, Google
          Cloud ve IBM Cloud gibi bulut ortamlarında ve şirket içi altyapılarda
          devreye alınabiliyor ve yönetilebiliyor.
        </>
      ),
    },
  ];

  const advantages2 = [
    {
      title: <>Tüm Cihazlardan Kolay Erişim</>,
      desc: (
        <>
          VMware Horizon Client ile sanal masaüstlerine ve uygulamalara Windows,
          macOS, Linux, iOs, Android ve Chromebook gibi birçok işletim sistemi
          ve cihazdan kolayca ulaşılabiliyor. Aynı zamanda web tarayıcılar
          üzerinden de ulaşmak mümkün.
        </>
      ),
    },
    {
      title: <>İyileştirilmiş Kullanıcı Deneyimi</>,
      desc: (
        <>
          Kullanıcıların uygulamalara ve masaüstlerine sorunsuz bir şekilde
          erişebilmeleri kullanıcı deneyimini en üst düzeye çıkarıyor.
          Kullanıcılar yalnızca kendilerine tahsis edilen kaynaklara ulaşıyor ve
          işlerini yapmak için ihtiyaç duydukları her şey parmaklarının ucuna
          kadar geliyor.
        </>
      ),
    },
    {
      title: <>Tümleşik İletişim ve İş Birliği ile Optimize Edilen Deneyim</>,
      desc: (
        <>
          Microsoft Teams, Zoom, Cisco WebEx ve diğer iletişim ve işbirliği
          araçları için optimize edilmiş ses ve video desteğiyle daha iyi bir
          kullanıcı deneyimi ve üretkenliği sunuyor. Oturum işbirliği, birden
          çok kullanıcının aynı masaüstünü gerçek zamanlı olarak görüntülemesine
          ve değiştirmesine olanak tanıyor.
        </>
      ),
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Sanallaştırma Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.virtual.children);
    navStore.setTitle("Diğer sanallaştırma çözümlerini inceleyin");
    navStore.setNavTitle("Diğer sanallaştırma çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.virtual.children.desktop.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        whiteBg={true}
        title={
          <>
            Masaüstü <br /> Sanallaştırma
          </>
        }
        desc={
          <>
            Masaüstü sanallaştırma, verilere, uygulamalara ve işletim
            sistemlerine farklı yerlerden ve farklı zamanlarda erişim imkanı
            sunan sanal bir masaüstü altyapısıdır.
          </>
        }
        bottom={{
          title: (
            <>
              Masaüstü sanallaştırmanın Sanal Masaüstü Altyapısı{" "}
              <span style={{ textDecoration: "none" }}>(VDI)</span>, Uzak
              Masaüstü Hizmetleri{" "}
              <span style={{ textDecoration: "none" }}>(RDS)</span> ve Hizmet
              Olarak Masaüstü{" "}
              <span style={{ textDecoration: "none" }}>(DaaS)</span> olmak üzere
              üç farklı çeşidi bulunuyor.
            </>
          ),
          text: {
            part1: null,
            part2: null,
          },
        }}
      ></Hero>
      <TechDetails items={details}></TechDetails>
      <ProductInfo
        icon={shape}
        title={"VMWare Horizon"}
        type={productInfoType.dualDesc}
        dualDesc={{
          part1: {
            title: (
              <>
                Uzaktan ve hibrit çalışmanın yaygınlaşmasıyla birlikte günümüz
                çalışanları artık çok daha hareketli ve her yerden her zaman
                çalışma ihtiyacı duyuyor.
              </>
            ),
            text: (
              <>
                Bilgisayarlardan, tabletlerden ve akıllı telefonlardan verimli
                bir şekilde çalışabilmeleri gerekiyor. VMware Horizon, BT
                departmanlarının şirket içi altyapılarda veya bulut ortamında
                sanal masaüstleri ve uygulamalar çalıştırabilmelerine ve bunları
                uzaktan teslim edebilmelerine olanak tanıyor
              </>
            ),
          },
          part2: {
            title: (
              <>
                VMware Horizon, çalışanların uygulamalara ve masaüstlerine her
                yerden her zaman erişebilmelerine olanak tanıyan dijital çalışma
                alanları sağlıyor.
              </>
            ),
            text: (
              <>
                Çözüm, buluta hazır çevik bir temel, sınıfının en iyisi modern
                yönetim ve günümüzün her yerden ulaşılabilen çalışma alanını
                güçlendiren uçtan uca bir güvenlik sunuyor.
              </>
            ),
          },
        }}
      ></ProductInfo>
      <SolutionsList
        preText={<>VMware Horizon’un işletmelere sağladığı avantajlar.</>}
        blueBg={true}
        items={advantages}
      ></SolutionsList>
      <SolutionsList
        preText={<>VMware Horizon’un son kullanıcılara sağladığı avantajlar.</>}
        items={advantages2}
      ></SolutionsList>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer sanallaştırma çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.virtual.children}
          hideIndex={appRoutes.solutions.children.virtual.children.desktop.type}
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
