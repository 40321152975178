import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import shape from "../../../assets/img/shapes/shape-sphere-in-box.svg";

import Hero from "../../../components/Hero";
import ProductInfo from "../../../components/ProductInfo";
import FeatureCards from "../../../components/FeatureCards";
import FooterNav from "../../../components/FooterNav";
import RevealWrapper from "../../../components/RevealWrapper";

import { productInfoType } from "../../../utils/types";
import { appRoutes } from "../../../utils/routes";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";

import bannerImgV from "../../../assets/img/hero/sanallastirma-altyapi.jpg";

export default function Infrastructure() {
  const advantages = [
    {
      title: { part1: <>Daha Basit</>, part2: <>Operasyonlar</> },
      text: (
        <>
          İhtiyaç anında kolayca ölçeklenebilen, en son teknolojik gelişmeleri
          optimize etmek için özel olarak tasarlanmış bir sunucu platformudur.
        </>
      ),
    },
    {
      title: { part1: <>Otonom Bilgi</>, part2: <>İşlem Altyapısı</> },
      text: (
        <>
          Birlikte ve bağımsız olarak çalışan, belirlenen parametrelere uygun
          akıllı sistemlerle iş fırsatlarına daha hızlı yanıt verebilme.
        </>
      ),
    },
    {
      title: { part1: <>Proaktif</>, part2: <>Dayanıklılık</> },
      text: (
        <>
          Güvenli etkileşimler ve olası tehditleri tahmin etme yeteneği için
          özel olarak tasarlanan altyapı ile güvenli dijital dönüşüm.
        </>
      ),
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Sanallaştırma Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.virtual.children);
    navStore.setTitle("Diğer sanallaştırma çözümlerini inceleyin");
    navStore.setNavTitle("Diğer sanallaştırma çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.virtual.children.infrastructure.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        whiteBg={true}
        title={
          <>
            Altyapı <br /> Sanallaştırma
          </>
        }
        desc={
          <>
            Altyapı sanallaştırma, BT altyapılarını daha az karmaşık hale
            getirerek daha verimli ve daha uygun maliyetlerle sürdürülmesini
            sağlayan bir çözümdür.
          </>
        }
        bottom={{
          title: (
            <>
              Düşük maliyetler, daha verimli olağanüstü durum kurtarma
              yetenekleri, artırılmış BT çevikliği, yüksek performans ve daha
              hızlı kaynak sağlama gibi çok sayıda avantajı beraberinde getirir.
            </>
          ),
          text: {
            part1: (
              <>
                Sunuculardan ağlara ve depolamaya kadar tüm altyapıyı
                sanallaştırmak mümkündür.
              </>
            ),
            part2: null,
          },
        }}
      ></Hero>
      <ProductInfo
        title={"VMWare vSphere"}
        type={productInfoType.dualDesc}
        icon={shape}
        dualDesc={{
          part1: {
            title: (
              <>
                VMware vSphere, VMware'in veri merkezlerini işlemci, depolama ve
                ağ kaynakları içeren toplu bilgi işlem altyapılarına dönüştüren
                sanallaştırma platformudur.
              </>
            ),
            text: (
              <>
                vSphere, bu altyapıları tümleşik bir işletim ortamı olarak
                yönetilmesine olanak tanıyor ve bu ortama katılan veri
                merkezlerini yönetmek için çeşitli araçlar sağlıyor. Konteyner
                tabanlı uygulama geliştirme desteğine sahip modern vSphere
                altyapısı ile karmaşık modern uygulamalar, geleneksel
                uygulamalar ve sanal makineler kadar kolay yönetilebiliyor.
              </>
            ),
          },
          part2: {
            title: (
              <>
                Yeniden tasarlanan Kubernetes sayesinde vSphere üzerinde 70
                milyon ve üzeri iş yükü modernize edilebiliyor.
              </>
            ),
            text: (
              <>
                vSphere ve Tanzu ile mevcut altyapı üzerinde mevcut kurumsal
                uygulamaların yanı sıra modern konteyner tabanlı uygulamalar da
                çalıştırılabiliyor.
              </>
            ),
          },
        }}
      ></ProductInfo>
      <FeatureCards
        greeting={<>VMware vSphere Çözümünün Avantajları</>}
        items={advantages}
      ></FeatureCards>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer sanallaştırma çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.virtual.children}
          hideIndex={
            appRoutes.solutions.children.virtual.children.infrastructure.type
          }
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
