import React from "react";
import { Link } from "react-router-dom";
import s from "../assets/scss/contactUs.module.scss";

import AnimatedButton from "./AnimatedButton";

export default function ContactUs({ text = "text" }) {
  return (
    <section className={s.contactUs}>
      {/* <div className={s.overlay}></div> */}
      <h5 className={s.text}>{text}</h5>
      <Link to="/iletisim" className={s.btnWrapper}>
        <AnimatedButton text="Bize Ulaşın" color="#ffffff"></AnimatedButton>
      </Link>
    </section>
  );
}
