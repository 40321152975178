import React, { useEffect } from "react";
import s from "../../assets/scss/services.module.scss";

import HeroServices from "../../components/HeroServices";
import heroBg from "../../assets/img/bg-advisory.png";
import RevealWrapper from "../../components/RevealWrapper";
import ContactUs from "../../components/ContactUs";
import FooterNav from "../../components/FooterNav";
import { appRoutes } from "../../utils/routes";
import useBreadcrumbStore from "../../stores/breadcrumbStore";
import layerBlue from "../../assets/video/layer-white.mp4";
import { useStickyNavStore } from "../../stores/stickyNavStore";

import heroImg from "../../assets/img/hero-services/danismanlik.jpg";

export default function Advisory() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Hizmetler" });
    navStore.setChildren(appRoutes.services.children);
    navStore.setTitle("Hizmetlerimizi inceleyin");
    navStore.setNavTitle("Diğer hizmetlerimizi inceleyin");
    navStore.setHiddenChild(appRoutes.services.children.advisory.type);
  }, [setBc]);

  return (
    <main className={s.services}>
      <HeroServices
        image={heroImg}
        title={<>Danışmanlık Hizmetleri</>}
        video={layerBlue}
        bg={heroBg}
        desc={
          <>
            İşletmelerin BT altyapısı ve kaynaklarını nasıl daha iyi
            kullanabilecekleri dahil olmak üzere iş uygulamaları, üretkenlik
            uygulamaları, ağ, depolama, siber güvenlik ve destek araçlarından en
            iyi şekilde faydalanabilmelerine yardımcı oluyoruz.
          </>
        }
        bottom={{
          title: (
            <>
              Yürüttüğümüz detaylı analizler ve hazırladığımız raporlar
              sayesinde müşterilerimiz BT altyapılarının durumunu ve BT
              süreçlerini nasıl daha iyi hale getirebileceklerini görüyorlar.
            </>
          ),
          texts: {
            part1: (
              <>
                Danışmanlık hizmetlerimizi verirken iş sürekliliğinin
                sağlanması, süreçlerin hızlandırılması, verimliliğin
                artırılması, operasyonel maliyetlerin azaltılması, kurumsal
                olgunluğun artırılarak olağanüstü durum senaryolarına hazır
                olmak gibi önemli kriterleri göz önünde bulunduruyoruz.
              </>
            ),
            part2: (
              <>
                İşletmelerin BT altyapılarından maksimum verim elde etmeleri,
                maliyetleri azaltmaları ve kullanıcı deneyimini iyileştirmeleri
                için yönetim sistemlerini global standartlara ve süreçlere nasıl
                uygun hale getirebilecekleri konusuna önerilerde bulunuyoruz.
              </>
            ),
          },
        }}
      ></HeroServices>
      <RevealWrapper>
        <ContactUs
          text={
            <>
              Günümüzde yalnızca çözüme odaklanan birçok danışmanlık şirketinin
              aksine işletmeye özel derinlemesine stratejilerle konuya farklı
              bir bakış açısı getiriyoruz.
            </>
          }
        ></ContactUs>
      </RevealWrapper>
      <FooterNav
        title={<>Diğer hizmetlerimizi inceleyin</>}
        items={appRoutes.services.children}
        hideIndex={appRoutes.services.children.advisory.type}
      ></FooterNav>
    </main>
  );
}
