import React from "react";
import s from "../assets/scss/contact.module.scss";
import ContactForm from "../components/ContactForm";
import IconLinkedin from "../components/Icon/IconLinkedin";

import cx from "classnames";
import AnimatedButton from "../components/AnimatedButton";
import useCursorStore from "../stores/cursorStore";

import cerez from "../assets/pdf/cerez-politikasi.pdf";
import kvkk from "../assets/pdf/websitesi-aydinlatma-metni.pdf";
import ZohoLiveChat from "../components/ZohoLiveChat";

export default function Contact() {
  const { circle } = useCursorStore();

  return (
    <main className={s.contact}>
      <div className={s.top}>
        <div className={s.text}>
          <h1>Sorularınız mı var?</h1>
          <h1>Bize ulaşın &#128075;</h1>
        </div>
        <div className={s.formWrapper}>
          <ContactForm contactPage />
        </div>
      </div>

      <div className={s.middle}>
        <div className={s.addressWrapper}>
          <div className={s.col}>
            <div className={s.links}>
              <p className={cx(s.link, s.address)}>
                FSM Mah.,Balkan Cad. <br /> JUSTWork Ofis Kampüs NO:62A
              </p>
              <a
                className={s.map}
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/Ateon+Bilgi+Teknolojileri/@41.0233974,29.1270844,15z/data=!4m5!3m4!1s0x0:0x8cecab889355e119!8m2!3d41.0233974!4d29.1270844"
              >
                <AnimatedButton sm text="Yol Tarifi Al"></AnimatedButton>
              </a>
            </div>
          </div>
        </div>

        <div className={s.right}>
          <div className={s.col}>
            <h6 className={s.title}>Telefon:</h6>
            <div className={s.links}>
              <a
                onMouseEnter={() => circle()}
                onMouseLeave={() => circle()}
                className={cx(s.link, s.phone)}
                href="tel:02169122566"
              >
                0216 912 25 66
              </a>
            </div>
          </div>
          <div className={cx(s.col, s.email)}>
            <h6 className={s.title}>Eposta:</h6>
            <div className={s.links}>
              <a
                onMouseEnter={() => circle()}
                onMouseLeave={() => circle()}
                className={cx(s.link, s.email)}
                href="mailto:bilgi@ateon.com.tr"
              >
                bilgi@ateon.com.tr
              </a>
            </div>
          </div>
        </div>
        {/* <div className={s.socialMobile}>
          <a
            href="https://www.linkedin.com/company/ateon-bt"
            target="_blank"
            rel="noreferrer"
          >
            <IconLinkedin fill="#fff" className={s.icon}></IconLinkedin>
          </a>
        </div> */}
      </div>

      <div className={s.bottom}>
        <div className={s.copyright}>
          <small className={s.cText}>
            <span className={s.text}>© Ateon Bilgi Teknolojileri 2022</span>{" "}
            <span className={s.seperator}>|</span>{" "}
            <span className={s.text}>Tüm Hakları Saklıdır</span>{" "}
            <span className={s.seperator}>|</span>{" "}
            <span className={s.link}>
              Made by{" "}
              <a
                onMouseEnter={() => circle()}
                onMouseLeave={() => circle()}
                href="https://justdesignfx.com"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline" }}
              >
                JUST DESIGN FX®
              </a>
            </span>{" "}
          </small>
        </div>
        <div className={s.privacy}>
          <a
            href={kvkk}
            target="_blank"
            rel="noreferrer"
            onMouseEnter={() => circle()}
            onMouseLeave={() => circle()}
            className={s.text}
          >
            KVKK Aydınlatma Metni
          </a>
          {/* <span
            onMouseEnter={() => circle()}
            onMouseLeave={() => circle()}
            className={s.text}
          >
            Gizlilik Politikası
          </span> */}
          <a
            href={cerez}
            target="_blank"
            rel="noreferrer"
            onMouseEnter={() => circle()}
            onMouseLeave={() => circle()}
            className={s.text}
          >
            Çerez Politikası
          </a>
        </div>
        <div className={s.social}>
          <a
            onMouseEnter={() => circle()}
            onMouseLeave={() => circle()}
            href="https://www.linkedin.com/company/ateon-bt"
            target="_blank"
            rel="noreferrer"
          >
            <IconLinkedin fill={"#1a1818"} className={s.icon}></IconLinkedin>
          </a>
        </div>
      </div>
    </main>
  );
}
