import React, { useRef, useState } from "react";
import s from "../assets/scss/slider.module.scss";

import { Link } from "react-router-dom";

import AnimatedButton from "../components/AnimatedButton";

import bgVideo from "../assets/video/bg-slider.mp4";
import IconArrow from "./Icon/IconArrow";

export default function Slider({
  sliderItems = [
    { id: "", image: "", subtitle: "", summary: "", title: "", url: "" },
  ],
}) {
  const sliderRef = useRef(null);
  // const q = gsap.utils.selector(sliderRef);

  const [currentItem, setCurrentItem] = useState(0);

  const slide = (i) => {
    setCurrentItem(i);
  };

  const handleItemChange = (val) => {
    if (val === "INCREASE") {
      setCurrentItem((currentItem + 1) % sliderItems.length);
    } else if (val === "DECREASE") {
      if (currentItem === 0) {
        setCurrentItem(sliderItems.length - 1);
      } else {
        setCurrentItem(currentItem - 1);
      }
    }
  };

  return (
    <section className={s.slider} ref={sliderRef}>
      {/* <VantaContainer></VantaContainer> */}
      <div className={s.bgVideo}>
        <video
          className={s.video}
          src={bgVideo}
          autoPlay
          loop
          muted
          playsInline
        ></video>
      </div>
      <div className={s.nav}>
        <div className={s.itemWrapper}>
          <ul className={s.navItems}>
            <div
              className={s.bg}
              style={{
                top: `${currentItem * (100 / sliderItems.length)}%`,
                height: `${100 / sliderItems.length}%`,
              }}
            ></div>
            {sliderItems.length !== 0 &&
              sliderItems.map((item, i) => {
                return (
                  <li className={s.navItem} onClick={() => slide(i)} key={i}>
                    <p
                      className={s.text}
                      style={{
                        transform:
                          currentItem === i
                            ? "translateX(1rem)"
                            : "translateX(0)",
                      }}
                    >
                      <span className={s.itemIndex}>{`0${i + 1}`}</span>
                      {item.summary}
                    </p>
                    <div
                      className={s.arrow}
                      style={{
                        transform:
                          currentItem !== i ? "rotate(-45deg)" : "rotate(0)",
                      }}
                    >
                      <IconArrow fill="#00509D" />
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.top}>
          <div className={s.texts}>
            <div className={s.oWrapper}>
              {sliderItems.length !== 0 &&
                sliderItems.map((item, i) => (
                  <div
                    className={s.text}
                    style={{
                      transform: `translateY(${
                        currentItem === i ? 0 : currentItem > i ? -100 : 100
                      }%)`,
                    }}
                    key={i}
                  >
                    <h2
                      className={s.subtitle}
                      data-slider-subtitle
                      style={{
                        transform: `translateY(${
                          currentItem === i ? 0 : currentItem > i ? -100 : 350
                        }%)`,
                        opacity: currentItem === i ? 1 : 0,
                        visibility: currentItem === i ? "visible" : "hidden",
                      }}
                    >
                      {item.subtitle}
                    </h2>

                    <h1
                      className={s.title}
                      data-slider-title
                      style={{
                        transform: `translateY(${
                          currentItem === i ? 0 : currentItem > i ? -200 : 100
                        }%)`,
                        opacity: currentItem === i ? 1 : 0,
                        visibility: currentItem === i ? "visible" : "hidden",
                      }}
                    >
                      {item.title}
                    </h1>
                  </div>
                ))}
            </div>
          </div>
          <div className={s.btnWrapper}>
            <Link to={sliderItems[currentItem]?.url ?? "/"}>
              <AnimatedButton sm text="Hemen İnceleyin"></AnimatedButton>
            </Link>
          </div>
        </div>
        <div className={s.bottom}>
          {sliderItems.map((item, i) => {
            return (
              <div
                className={s.imgWrapper}
                data-slider-img-wrapper
                key={i}
                style={{
                  visibility: currentItem === i ? "visible" : "hidden",
                  transition: `${currentItem === i ? 1 : 1.1}s all ease`,
                  transform: `translateY(${
                    currentItem === i ? 0 : currentItem > i ? -100 : 100
                  }%)`,
                }}
              >
                <img
                  data-slider-img
                  className={s.img}
                  src={item.image}
                  alt=""
                  style={{
                    visibility: currentItem === i ? "visible" : "hidden",
                    transition: `${currentItem === i ? 1 : 1.1}s all ease`,
                    transform: `scale(${currentItem === i ? 1 : 1.5})`,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={s.navMobile}>
        <div className={s.controls}>
          <button
            className={s.btn}
            onClick={() => handleItemChange("DECREASE")}
          >
            <div className={s.arrow}>
              <IconArrow fill="#0466C8" />
            </div>
          </button>
          <button
            className={s.btn}
            onClick={() => handleItemChange("INCREASE")}
          >
            <div className={s.arrow}>
              <IconArrow fill="#0466C8" />
            </div>
          </button>
        </div>
        <ul className={s.navItems}>
          {sliderItems.map((item, i) => {
            return (
              <Link
                to={sliderItems[currentItem].url ?? "/"}
                className={s.navItem}
                onClick={() => slide(i)}
                key={i}
                style={{
                  transform: `translateX(${
                    currentItem === i ? 0 : currentItem > i ? -100 : 350
                  }%)`,
                  visibility: currentItem === i ? "visible" : "hidden",
                }}
              >
                <span className={s.itemIndex}>{`0${i + 1}`}</span>
                <p className={s.text}>{item.summary}</p>
              </Link>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
