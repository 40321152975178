import React from "react";
import s from "../assets/scss/heroServices.module.scss";

import BreadCrumb from "./BreadCrumb";

export default function HeroServices({
  title = "",
  desc = "",
  image = "",
  video = "",
  bottom = { title: "", texts: { part1: "", part2: "" } },
  bg = null,
  floatingImg = null,
}) {
  return (
    <section
      className={s.heroServices}
      style={{ overflow: bg ? "hidden" : "visible" }}
    >
      <div className={s.top}>
        <div className={s.text}>
          <div className={s.bcWrapper}>
            <BreadCrumb></BreadCrumb>
          </div>
          <h1 className={s.title}>{title}</h1>
          <h2 className={s.desc}>{desc}</h2>
        </div>
        <div className={s.videoWrapper}>
          <video
            className={s.video}
            autoPlay
            loop
            muted
            playsInline
            src={video}
          ></video>
        </div>
      </div>
      <div className={s.imgWrapper} data-scaling-img-wrapper>
        <img data-scaling-img className={s.img} src={image} alt="Technology" />
      </div>
      <div className={s.bottom}>
        <h2 className={s.title}>{bottom.title}</h2>
        <div className={s.texts}>
          {bottom.texts.part1 && (
            <p
              className={s.par}
              style={{ marginBottom: bottom.texts.part2 ? "5vh" : "0" }}
            >
              {bottom.texts.part1}
            </p>
          )}
          {bottom.texts.part2 && <p className={s.par}>{bottom.texts.part2}</p>}
        </div>
      </div>
      {bg && (
        <div className={s.bgWrapper}>
          <img src={bg} alt="Background Visual" className={s.bg} />
        </div>
      )}
      {floatingImg && (
        <div className={s.floatingImgWrapper} data-scaling-img-wrapper>
          <img
            src={floatingImg}
            alt="Background Visual"
            className={s.img}
            data-scaling-img
          />
        </div>
      )}
    </section>
  );
}
