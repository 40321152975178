import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import FooterNav from "../../../components/FooterNav";
import Hero from "../../../components/Hero";
import ImageWithText from "../../../components/ImageWithText";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";
import { appRoutes } from "../../../utils/routes";
import { alignmentType } from "../../../utils/types";

import bannerImgV from "../../../assets/img/hero/kurumsal-dosya-yonetimi.jpg";
import imgMobile from "../../../assets/img/hero/kurumsal-dosya-yonetimi-mobil.png";
import middleImg from "../../../assets/img/kurumsal_dosya_alt.jpg";

export default function HomeManagement() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Kurumsal Dosya Yönetimi" });
    navStore.setChildren(appRoutes.solutions.children.management.children);
    navStore.setTitle("Kurumsal dosya yönetimi çözümlerini inceleyin");
    navStore.setNavTitle("Kurumsal dosya yönetimi çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.management.children.index.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgMobile={imgMobile}
        imgV={bannerImgV}
        circledImg
        title={
          <>
            Kurumsal Dosya <br /> Yönetimi
          </>
        }
        desc={
          <>
            Yönetilen hizmetler işletmelerin operasyonlarını iyileştirmelerini,
            maliyetleri azaltmalarını ve insan kaynaklarını daha verimli ve
            etkili bir şekilde kullanmalarına olanak tanıyor.
          </>
        }
        bottom={{
          title: (
            <>
              İşletmeler, yönetilen hizmetler ile çeşitli operasyonların ve
              süreçlerin yürütülmesi hizmetini dışarıdan alarak operasyonlarını
              iyileştirebiliyor ve maliyetlerini önemli ölçüde azaltabiliyor.
            </>
          ),
          text: {
            part1: (
              <>
                Yönetilen hizmetler kapsamında, yönetilen servis sağlayıcı (MSP,
                Managed Service Provider), sistemler ve operasyonları belirli
                şartlar altında müşteri adına yönetir.
              </>
            ),
            part2: (
              <>
                Geçiş, bakım ve optimizasyon dahil olmak üzere tüm platform
                yönetilen servis sağlayıcı tarafından yönetilir ve tüm
                kaynakların verimli ve etkili bir şekilde kullanılması sağlanır.
              </>
            ),
          },
        }}
      ></Hero>
      <ImageWithText
        image={middleImg}
        alignment={alignmentType.right}
        title={
          <>
            Yönetilen hizmetler özellikle BT kaynakları kısıtlı olan KOBİ’ler ve
            diğer işletmelere büyük mali avantajlar sağlıyor.
          </>
        }
        text={
          <>
            Operasyonların ve süreçlerin yönetilen servis sağlayıcı tarafından
            yürütülmesi teknolojik açıdan güncel kalma, kapsamlı bilgi
            birikimine ve becerilere ulaşma, hizmet kalitesi, düşük maliyet ve
            risklerin azalması gibi avantajları da beraberinde getiriyor.
          </>
        }
      ></ImageWithText>
      <RevealWrapper>
        <FooterNav
          title={<>Kurumsal dosya yönetimi çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.management.children}
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
