import React from "react";
import s from "../assets/scss/productInfo.module.scss";

import { productInfoType } from "../utils/types";

export default function ProductInfo({
  type = productInfoType.dualText,
  title = "title",
  desc = "desc",
  icon = "",
  texts = {
    part1: "text part1",
    part2: "text part2",
  },
  dualDesc = {
    part1: {
      title: "dualDesc part1 title",
      text: "dualDesc part1 text",
    },
    part2: {
      title: "dualDesc part2 title",
      text: "dualDesc part2 text",
    },
  },
}) {
  return (
    <section className={s.productInfo}>
      <h2 className={s.title}>{title}</h2>
      {type === productInfoType.dualText ? (
        <div className={s.dualText}>
          <div className={s.iconWrapper}>
            <img
              className={s.icon}
              src={icon}
              alt="Parallax Visual"
              data-parallax
            />
          </div>
          <div className={s.descWrapper}>
            <h3 className={s.desc}>{desc}</h3>
          </div>
          <div className={s.texts}>
            <p className={s.text}>{texts.part1}</p>
            <p className={s.text}>{texts.part2}</p>
          </div>
        </div>
      ) : (
        <div className={s.dualDesc}>
          <div className={s.iconWrapper}>
            <img
              src={icon}
              alt="Parallax Visual"
              className={s.icon}
              data-parallax
            />
          </div>
          <div className={`${s.descWrapper} ${s.left}`}>
            <h3 className={s.desc}>{dualDesc.part1.title}</h3>
            <p className={s.text}>{dualDesc.part1.text}</p>
          </div>
          <div className={`${s.descWrapper} ${s.right}`}>
            <h3 className={s.desc}>{dualDesc.part2.title}</h3>
            <p className={s.text}>{dualDesc.part2.text}</p>
          </div>
        </div>
      )}
    </section>
  );
}
