import gsap, { Expo } from "gsap";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import s from "../assets/scss/preloader.module.scss";

import { ReactComponent as AteonLogo } from "../assets/img/ateon-logo-preloader.svg";
import useLogoStore from "../stores/logoStore";

export default function Preloader() {
  const preloaderWrapperRef = useRef(null);
  const preloaderRef = useRef(null);
  const preloaderShortRef = useRef(null);
  const q = gsap.utils.selector(preloaderWrapperRef);

  const animateHeaderLogo = useLogoStore((state) => state.animate);
  const [preloaderShown, setPreloaderShown] = useState(false);

  const logoTL = useRef(null);
  const [progress, setProgress] = useState(0);

  useLayoutEffect(() => {
    const isShown = sessionStorage.getItem("preloaderShown") || "";
    setPreloaderShown(isShown);

    gsap.set(
      [
        ...q("[data-name='c-a']"),
        ...q("[data-name='c-t']"),
        ...q("[data-name='c-e']"),
        ...q("[data-name='c-o']"),
        ...q("[data-name='c-n']"),
        ...q("[data-name='c-trademark']"),
      ],
      {
        yPercent: 200,
      }
    );

    if (preloaderShown) {
      preloaderShortRef.current &&
        gsap.to(preloaderShortRef.current, {
          autoAlpha: 0,
          delay: 0.5,
          onStart: () => {
            animateHeaderLogo();
          },
        });
    } else {
      document.body.style.overflow = "hidden";

      const easeFunc = Expo.easeInOut;

      logoTL.current = gsap
        .timeline({ paused: true })
        .add("s")
        .to(
          [
            ...q("[data-name='c-a']"),
            ...q("[data-name='c-t']"),
            ...q("[data-name='c-e']"),
            ...q("[data-name='c-o']"),
            ...q("[data-name='c-n']"),
            ...q("[data-name='c-trademark']"),
          ],
          {
            yPercent: 0,
            stagger: 0.1,
            duration: 2,
            ease: easeFunc,
            onStart: () => {
              q("[data-progress-indicator]") &&
                gsap.to(q("[data-progress-indicator]"), {
                  autoAlpha: 0,
                  duration: 0.6,
                  yPercent: -20,
                });
            },
          },
          "s"
        )
        .to(
          [
            ...q("[data-name='c-a']"),
            ...q("[data-name='c-t']"),
            ...q("[data-name='c-e']"),
            ...q("[data-name='c-o']"),
            ...q("[data-name='c-n']"),
            ...q("[data-name='c-trademark']"),
          ],
          {
            yPercent: -110,
            stagger: 0.05,
            duration: 1.75,
            ease: easeFunc,
            onComplete: () => {
              document.body.style.overflow = "auto";

              preloaderRef.current &&
                gsap.to(preloaderRef.current, {
                  autoAlpha: 0,
                  onStart: () => {
                    animateHeaderLogo();
                    console.log("lol");
                  },
                });
            },
          }
        );
    }
  }, [preloaderShown]);

  const up = () => {
    animate();
  };

  // Image load counter
  useEffect(() => {
    let img, c, tot;

    img = document.images;
    c = 0;
    tot = img.length;

    const imgLoaded = () => {
      c += 1;
      setProgress(((100 / tot) * c) << 0);

      if (c === tot) return up();
      if (c === tot) return;
    };

    const counter = () => {
      for (var i = 0; i < tot; i++) {
        var tImg = new Image();
        tImg.onload = imgLoaded;
        tImg.onerror = imgLoaded;
        tImg.src = img[i].src;
      }
    };

    counter();
  }, []);

  const animate = () => {
    logoTL.current.play();
  };

  return (
    <div ref={preloaderWrapperRef}>
      {!preloaderShown ? (
        <div className={s.preloader} ref={preloaderRef}>
          <div className={s.logoWrapper}>
            <AteonLogo className={s.logo}></AteonLogo>
          </div>
          <div className={s.progress}>
            <div data-progress-indicator className={s.indicator}>
              <p className={s.num}>%{progress}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={s.preloaderShort} ref={preloaderShortRef}></div>
      )}
    </div>
  );
}
