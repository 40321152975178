import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import s from "../assets/scss/tableNav.module.scss";

import IconArrowXs from "./Icon/IconArrowXs";

import cx from "classnames";
import IconArrow from "./Icon/IconArrow";
import useWindowSize from "../hooks/useWindowSize";
import { breakpointTypes } from "../utils/types";

export default function TableNav() {
  const location = useLocation();
  const veritasNav = [
    {
      title: "Compliance",
      navItems: [
        {
          title: "Merge 1",
          text: "Kolay bulma için otomatik uyumluluk arşivlemesi.",
          path: "/veritas/compliance/merge1",
        },
        {
          title: "Enterprise Vault",
          text: "Kolay bulma için otomatik uyumluluk arşivlemesi.",
          path: "/veritas/compliance/enterprise-vault",
        },
        {
          title: "Enterprise Vault.cloud",
          text: "Yüksek riskli verileri tüm iletişim platformlarında toplayın ve arşivleyin.",
          path: "/veritas/compliance/enterprise-vault-cloud",
        },
        {
          title: "eDiscovery Platform",
          text: "Yüksek riskli verileri tüm iletişim platformlarında toplayın ve arşivleyin.",
          path: "/veritas/compliance/ediscovery-platform",
        },
      ],
    },
    {
      title: "Data Protection",
      navItems: [
        {
          title: "NetBackup",
          text: "Sınıfının en iyisi kurumsal yedekleme ve kurtarma çözümü.",
          path: "/veritas/data-protection/netbackup",
        },
        {
          title: "NetBackup SaaS Protection",
          text: "Sınıfının en iyisi kurumsal yedekleme ve kurtarma çözümü.",
          path: "/veritas/data-protection/netbackup-saas-protection",
        },
        {
          title: "Backup Exec",
          text: "Küçük ve orta ölçekli işletmelere yönelik veri yedekleme ve kurtarma.",
          path: "/veritas/data-protection/backup-exec",
        },
        {
          title: "NetBackup Appliance",
          text: "Fidye yazılımlarına karşı dirençli NetBackup veri korumasını devreye alın.",
          path: "/veritas/data-protection/netbackup-appliance",
        },
      ],
    },
    {
      title: "Insights",
      navItems: [
        {
          title: "NetBackup IT Analytics",
          text: "Çoklu bulut ortamlarında tümleşik BT analitiği ve öngörüleri.",
          path: "/veritas/insights/netbackup-it-analytics",
        },
        {
          title: "Data Insight",
          text: "Ayrıntılı risk analitiği ile 7/24 veri uyumluluğu.",
          path: "/veritas/insights/data-insights",
        },
      ],
    },
    {
      title: "Availability",
      navItems: [
        {
          title: "InfoScale",
          text: "Çoklu bulut ortamlarında tümleşik BT analitiği ve öngörüleri.",
          path: "/veritas/availability/infoscale",
        },
      ],
    },
  ];

  const windowSize = useWindowSize();

  const [currentItem, setCurrentItem] = useState(null);

  const handleMobileMenu = (index) => {
    if (windowSize.width > breakpointTypes.mobile) {
      return;
    }

    if (currentItem === index) {
      return setCurrentItem(null);
    }

    setCurrentItem(index);
  };

  return (
    <section className={s.infoTable}>
      {veritasNav.map((item, i) => {
        return (
          <div className={s.col} key={i}>
            <div className={s.titleWrapper} onClick={() => handleMobileMenu(i)}>
              <h5 className={s.title}>{item.title}</h5>
              <div className={s.arrowWrapper}>
                <div className={cx(s.arrow, { [s.open]: i === currentItem })}>
                  <IconArrow rotate="45" fill="#b84452" />
                </div>
              </div>
            </div>
            <div className={cx(s.infoWrapper, { [s.open]: i === currentItem })}>
              {item.navItems.map((item, i) => {
                return (
                  <Link
                    to={item.path}
                    className={cx(s.info, {
                      [s.active]: item.path === location.pathname,
                    })}
                    key={i}
                  >
                    <div className={s.infoTitleWrapper}>
                      <div className={s.iconWrapper}>
                        <IconArrowXs></IconArrowXs>
                      </div>
                      <h6 className={s.infoTitle}>{item.title}</h6>
                    </div>
                    <p className={s.infoText}>{item.text}</p>
                  </Link>
                );
              })}
            </div>
          </div>
        );
      })}
    </section>
  );
}
