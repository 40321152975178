import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import Hero from "../../../components/Hero";
import ImageWithText from "../../../components/ImageWithText";
import FeatureCards from "../../../components/FeatureCards";

import { alignmentType } from "../../../utils/types";
import FooterNav from "../../../components/FooterNav";
import { appRoutes } from "../../../utils/routes";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";

import bannerImgV from "../../../assets/img/hero/dell-hiper.jpg";
import imgMiddle from "../../../assets/img/dell-hiper-alt.jpg";

export default function DellHyper() {
  const cards = [
    {
      title: { part1: <>Esnek Dağıtım</>, part2: <>Seçenekleri</> },
      text: (
        <>
          Ortamın ve iş yüklerinin gerektirdiği gücü karşılamak için cihaz, raf
          form faktörü ya da hazır sistem yapı taşları olarak kullanıma
          alınabiliyor.
        </>
      ),
    },
    {
      title: { part1: <>Basit ve Güçlü</>, part2: <>Altyapı</> },
      text: (
        <>
          Bulut benzeri çeviklik, ölçeklenebilirlik ve yerleşik veri korumasıyla
          basitleştirilmiş BT yönetimi sağlamak için yeni nesil PowerEdge
          sunucular üzerine kuruludur.
        </>
      ),
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Donanım Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.hardware.children);
    navStore.setTitle("Diğer donanım çözümlerini inceleyin");
    navStore.setNavTitle("Diğer donanım çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.hardware.children.dellHyper.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        title={
          <>
            Dell Hiper-Bütünleşik <br /> Altyapı Çözümleri
          </>
        }
        desc={
          <>
            İşletmelerin günümüzde BT altyapılarından en büyük beklentisi üst
            düzey esneklik, çeviklik ve iş yükü konsolidasyonudur.
          </>
        }
        bottom={{
          title: (
            <>
              Dell’in kendi kendini yöneten, optimize eden ve iyileştiren yapay
              zeka destekli HCI çözümleri, hiper-bütünleşik altyapının
              basitliğini ve verimliliğini yatırım getirisi yüksek sistemlerde
              bir araya getiriyor.
            </>
          ),
          text: {
            part1: null,
            part2: null,
          },
        }}
      ></Hero>
      <ImageWithText
        image={imgMiddle}
        alignment={alignmentType.left}
        singleText={
          <>
            Dell’in kendi kendini yöneten, optimize eden ve iyileştiren yapay
            zeka destekli HCI çözümleri, hiper-bütünleşik altyapının basitliğini
            ve verimliliğini yatırım getirisi yüksek sistemlerde bir araya
            getiriyor.
          </>
        }
      ></ImageWithText>
      <FeatureCards
        greeting={"Dell Hiper-Bütünleşik Altyapı Çözümlerinin Avantajları"}
        items={cards}
      ></FeatureCards>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer donanım çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.hardware.children}
          hideIndex={
            appRoutes.solutions.children.hardware.children.dellHyper.type
          }
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
