import React from "react";
import s from "../assets/scss/textWithIcon.module.scss";

import ring from "../assets/img/shapes/ring.svg";

export default function TextWithIcon({
  image = ring,
  texts = {
    title: <>title</>,
    desc: { part1: <>desc part1</>, part2: <>desc part2</> },
  },
}) {
  return (
    <section className={s.textWithIcon}>
      <div className={s.imgWrapper}>
        <img
          src={image}
          alt="Abstract Icon"
          className={s.img}
          data-parallax
          data-speed="0.1"
        />
      </div>
      <div className={s.texts}>
        <h3 className={s.title}>{texts.title}</h3>
        <div className={s.desc}>
          <p className={s.par}>{texts.desc.part1}</p>
          <p className={s.par}>{texts.desc.part2}</p>
        </div>
      </div>
    </section>
  );
}
