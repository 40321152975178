import create from "zustand";

const useCursorStore = create((set) => ({
  visible: false,
  scaled: false,
  hidden: false,
  circled: false,
  hide: () => set((state) => ({ hidden: !state.hidden })),
  scale: () => set((state) => ({ scaled: !state.scaled })),
  circle: () => set((state) => ({ circled: !state.circled })),
  toggleVisibility: () => set((state) => ({ visible: !state.visible })),
}));

export default useCursorStore;
