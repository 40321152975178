import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";
import FooterNav from "../../../components/FooterNav";

import Hero from "../../../components/Hero";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";
import { appRoutes } from "../../../utils/routes";

import bannerImgV from "../../../assets/img/hero/ag-cozumleri.jpg";
import bannerVideo from "../../../assets/video/hero/ag-cozumleri.mp4";

export default function Network() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Ağ Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.network.children);
    navStore.setTitle("Ağ çözümlerini inceleyin");
    navStore.setNavTitle("Ağ çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.network.children.index.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        video={bannerVideo}
        title={
          <>
            Ağ <br /> Çözümleri
          </>
        }
        desc={
          <>
            Günümüzün rekabetçi dünyasında bilgisayar ağları hem özel sektörde
            hem de kamu sektöründe kuruluşların en kritik kaynaklarından biri
            olarak öne çıkıyor.
          </>
        }
        bottom={{
          title: (
            <>
              Günümüz dünyasında işletmeler, faaliyet modellerini her
              zamankinden daha hızlı geliştiriyor ve dijital yaklaşımları
              benimsiyor.
            </>
          ),
          text: {
            part1: (
              <>
                Mobilite, uzaktan ve hibrit çalışma, verilere her yerden her
                zaman erişebilme gibi çeşitli nedenlerden dolayı ağlar günümüzde
                işletmelerin faaliyetlerini yürütmeleri ve rekabette öne
                çıkmaları açısından en önemli kaynaklardan biri haline geldi.
              </>
            ),
            part2: (
              <>
                Güvenli, çevik bir ağ, riskleri azaltırken iş çevikliği ve
                esneklik sağlamada büyük bir rol oynuyor. Bu doğrultuda güçlü
                bir ağ altyapısına sahip olmak işletmeler açısından büyük önem
                taşıyor.
              </>
            ),
          },
        }}
      ></Hero>
      <RevealWrapper>
        <FooterNav
          title={<>Ağ çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.network.children}
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
