import React from "react";
import s from "../assets/scss/featureCards.module.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/scss/slickReset.scss";

import Slider from "react-slick";
import bg from "../assets/img/feature-card-bg.png";

export default function FeatureCardsMobile({
  items = [{ title: { part1: "", part2: "" }, text: "" }],
}) {
  const settings = {
    speed: 400,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className={s.featureCardsMobile}>
      <Slider {...settings}>
        {items.map((item, i) => {
          return (
            <div key={i}>
              <div className={s.featureCard}>
                <div className={s.bgWrapper}>
                  <img className={s.bg} src={bg} alt="Background" />
                </div>
                <small className={s.index}>0{i + 1}</small>
                <div className={s.textWrapper}>
                  <h3 className={s.title}>
                    {item.title.part1} <br /> {item.title.part2}
                  </h3>
                  <p className={s.text}>{item.text}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
