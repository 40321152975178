import create from "zustand";

const useStore = create((set) => ({
  modal: {
    visible: false,
    content: "",
  },
  setVisibility: (visible) =>
    set((state) => (state.modal = { ...state.modal, visible })),
  setContent: (content) =>
    set((state) => (state.modal = { ...state.modal, content })),
}));

export const useModalStore = useStore;
