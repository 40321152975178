import React from "react";

export default function IconLinkedin({ fill = "#00509d" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.077"
      height="19.086"
      viewBox="0 0 19.077 19.086"
    >
      <g
        id="Group_104"
        data-name="Group 104"
        transform="translate(-1363.507 -8745.102)"
      >
        <rect
          id="Rectangle_2403"
          data-name="Rectangle 2403"
          width="3.939"
          height="12.724"
          transform="translate(1363.886 8751.463)"
          fill={fill}
        />
        <path
          id="Path_6002"
          data-name="Path 6002"
          d="M68.836,67.8a2.348,2.348,0,1,0-2.329-2.348A2.339,2.339,0,0,0,68.836,67.8"
          transform="translate(1297 8682)"
          fill={fill}
        />
        <path
          id="Path_6003"
          data-name="Path 6003"
          d="M77.12,75.508c0-1.789.823-2.854,2.4-2.854,1.448,0,2.144,1.023,2.144,2.854v6.679h3.92V74.131c0-3.408-1.932-5.056-4.63-5.056a4.435,4.435,0,0,0-3.834,2.1V69.463H73.342V82.187H77.12Z"
          transform="translate(1297 8682)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
