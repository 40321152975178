import React from "react";

const IconInputArrow = ({ fill = "#000000" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 14.442 10.049"
    >
      <path
        id="Path_602"
        data-name="Path 602"
        d="M0,0,6.591,7.221,0,14.442H3.458l6.591-7.221L3.458,0Z"
        transform="translate(14.442) rotate(90)"
        fill={fill}
      />
    </svg>
  );
};

export default IconInputArrow;
