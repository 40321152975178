import React, { useEffect } from "react";
import s from "../assets/scss/stickyNav.module.scss";
import { AnimatePresence, motion } from "framer-motion";

import * as _ from "lodash";

import { Link, useLocation } from "react-router-dom";
import cx from "classnames";
import IconArrow from "./Icon/IconArrow";
import IconClose from "./Icon/IconClose";
import { useStickyNavStore } from "../stores/stickyNavStore";
import { useRef } from "react";

export default function StickyNav() {
  const { isOpen, toggle, setChildren, nav } = useStickyNavStore();
  const location = useLocation();
  const navigationRef = useRef(null);
  const navWrapperRef = useRef(null);

  const touchscreen = {
    initial: { opacity: 0, y: 100 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 100 },
  };

  useEffect(() => {
    if (isOpen) toggle();

    return () => {
      setChildren(null);
    };
  }, [location]);

  useEffect(() => {
    const closeOpenMenus = (e) => {
      if (isOpen && !navWrapperRef.current.contains(e.target)) {
        if (isOpen) toggle();
      }
    };

    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [isOpen]);

  return (
    <div
      className={s.fixedWrapper}
      data-sticky-item-wrapper
      data-route-transition
    >
      <div className={s.stickyNavWrapper} ref={navWrapperRef}>
        <div
          data-sticky-item
          className={s.stickyNav}
          style={{
            transition: "0.2s all ease",
            opacity: nav.children ? "1" : "0",
            visibility: nav.children ? "visible" : "hidden",
          }}
          ref={s.stickyNavRef}
          onClick={() => toggle()}
        >
          <div className={cx(s.title, { [s.open]: isOpen })}>
            <div className={s.arrow}>
              <motion.div
                className={s.iconWrapper}
                key={isOpen}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{
                  duration: 0.3,
                }}
              >
                {isOpen ? (
                  <IconClose fill="#ffffff"></IconClose>
                ) : (
                  <IconArrow fill="#ffffff"></IconArrow>
                )}
              </motion.div>
            </div>
            <div className={s.oWrapper}>
              <motion.p
                key={isOpen}
                initial={{ opacity: 0, y: "100%" }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: "100%" }}
                transition={{
                  duration: 0.6,
                }}
                className={s.textCollapsed}
              >
                {isOpen ? "Kapat" : nav.title}
              </motion.p>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              ref={navigationRef}
              className={s.navigation}
              key={isOpen}
              {...touchscreen}
              initial={{ opacity: 0, y: 100, pointerEvents: "none" }}
              animate={{ opacity: 1, y: 0, pointerEvents: "auto" }}
              exit={{ opacity: 0, y: 100, pointerEvents: "none" }}
              transition={{
                duration: 0.4,
              }}
            >
              <div className={cx(s.oWrapper, s.navTitle)}>
                <motion.h5
                  className={s.navTitle}
                  key={isOpen}
                  {...touchscreen}
                  initial={{ opacity: 0, y: "100%" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: "100%" }}
                  transition={{
                    duration: 0.6,
                  }}
                >
                  {nav.navTitle}
                </motion.h5>
              </div>

              <div className={s.oWrapper}>
                <motion.div
                  className={s.navItems}
                  key={isOpen}
                  initial={{ opacity: 0, y: "20%" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: "20%" }}
                  transition={{
                    duration: 0.6,
                  }}
                >
                  {Object.entries(_.omit(nav.children, ["index"])).map(
                    ([key, value], i) => {
                      return (
                        <Link
                          className={s.navItem}
                          to={`${value.parentPath}/${value.path}`}
                          key={`${i}_${key}_${value}`}
                          style={{
                            opacity:
                              nav.hiddenChild === value.type ? "0.5" : "1",
                            pointerEvents:
                              nav.hiddenChild === value.type ? "none" : "auto",
                          }}
                        >
                          <small className={s.index}>0{i + 1}</small>
                          <p className={s.link}> {value.ui}</p>
                          <div className={s.arrow}>
                            <IconArrow fill="#ffffff"></IconArrow>
                          </div>
                        </Link>
                      );
                    }
                  )}
                </motion.div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
