import React, { useEffect } from "react"
import HeroVeritas from "../../../components/HeroVeritas"
import HorizontalImageText from "../../../components/HorizontalImageText"
import PinnedInfo from "../../../components/PinnedInfo"
import TitleAndText from "../../../components/TitleAndText"

import eDiscovery from "../../../assets/lottie/veritas/eDiscoveryPlatform.json"

import gurl from "../../../assets/img/veritas/ediscovery-platform-banner.jpg"
import useBreadcrumbStore from "../../../stores/breadcrumbStore"
import GridIcons from "../../../components/GridIcons"

import server from "../../../assets/icons/veritas/server.svg"
import production from "../../../assets/icons/veritas/production.svg"
import inspect from "../../../assets/icons/veritas/inspect.svg"

export default function EDiscovery() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Compliance", sub: "eDiscovery Platform" })
  }, [setBc])

  const gridItems = [
    {
      title: { icon: server, text: "Veri Toplamayı Optimize Edin" },
      items: "Karmaşık araştırmalar yürütün ve aynı anda 100 adede kadar aramayı otomatikleştirin.",
    },
    {
      title: { icon: production, text: "Üretimi İyileştirin" },
      items:
        "Yalnızca doğru içeriği izole ederek, önceliklendirerek ve dışa aktararak keşif ve bulma süreçlerini güvenli bir şekilde ölçeklendirin.",
    },
    {
      title: { icon: inspect, text: "İncelemeyi Genişletin" },
      items: "Kolaylaştırılmış iş akışlarıyla 500’den fazla farklı dosya türünü işleyin ve analiz edin.",
    },
  ]

  const veritasSliderItems = [
    {
      title: "Esnek Dağıtım",
      media: null,
      pdf: null,
      detail: {
        text: "Tüm e-keşif yaşam döngüsü için tek bir çözümle fiziksel, sanal ve bulut ortamlarından toplayın.",
        list: [
          "Koleksiyondan üretime baştan sona keşfedin",
          "Tüm veri ortamını kolayca haritalayın",
          "İlgili belgeleri ve iletişimleri bulun",
        ],
        btnText: null,
      },
    },
    {
      title: "Otomatik İş Akışları",
      media: null,
      pdf: null,
      detail: {
        text: "Tedarikçileri veya kurulumları beklemeden hemen keşfetmeye başlayın.",
        list: [
          "Manuel çabaları azaltarak zamandan ve kaynaklardan tasarruf edin",
          "Sistematik iş akışlarıyla insan hatalarını azaltın",
          "Asgari eğitimle karmaşık araştırmalar yapın",
        ],
        btnText: null,
      },
    },
    {
      title: "İleri Düzey Analitik",
      media: null,
      pdf: null,
      detail: {
        text: "Riskleri yönetmek ve kritik kanıtları ortaya çıkarmak için makine öğreniminden yararlanın.",
        list: [
          "Dağıtık mimari yapılandırmasından faydalanınalar kullanın",
          "Binlerce koruyucuyu hızla işleyin ve analiz edin",
          "Daha titiz veri içgörüleri için Veritas ürün entegrasyonlarını kullanın",
          "İşleme ve Analiz Modülü",
        ],
        btnText: null,
      },
    },
    {
      title: "Entegre Sınıflandırma",
      media: null,
      pdf: null,
      detail: {
        text: "Veritas Classification Engine ile Konu Erişim İsteklerini karşılamak için hızlandırılmış incelemeden faydalanın.",
        list: [
          "Kişisel olarak tanımlanabilir içeriğe sahip öğelere öncelik vererek veri toplama ve incelemeyi iyileştirin",
          "Otomatik veri maskeleme ile hassas veri sızıntılarını önleyin",
          "Daha çevik inceleme sürecini destekleyen araçlarla inceleyenler arasındaki işbirliğini optimize edin",
        ],
        btnText: null,
      },
    },
    {
      title: "Uyumluluk",
      media: null,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Entegre sınıflandırma motoru ve gelişmiş redaksiyon özellikleriyle düzenleyici taleplerine (FOIA ve GDPR) yanıt verin.",
          "Dosya türünden bağımsız olarak yüksek riskli içerikleri bulun",
          "Anahtar kelimeleri, kelime öbeklerini ve kişisel bilgileri otomatik olarak tanımlayın ve düzeltin",
          "Otomatik iş akışlarıyla insan hatalarını azaltın",
        ],
        btnText: null,
      },
    },
    {
      title: "Yasal Süreçler",
      media: null,
      pdf: null,
      detail: {
        text: "Uçtan uca EDRM çözümüyle yasal bekletmelere yanıt verin ve vaka stratejilerini hızla değerlendirin.",
        list: [
          "Koleksiyonlarda analiz, inceleme ve üretime kadar keşfi kolaylaştırın",
          "Toplu redaksiyon yetenekleriyle belge üretimini ve dışa aktarmayı basitleştirin",
          "Gözetim zincirini doğrulayın ve savunulabilir raporlamayla şeffaflık sağlayın",
        ],
        btnText: null,
      },
    },
    {
      title: "Dahili",
      media: null,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Tüm araştırma gereksinimleri için tümleşik bir platformla uyumluluk risklerini ve öngörülemeyen maliyetleri azaltın.",
          "Farklı adlara, biçimlere ve oluşturma tarihlerine sahip dosyalar arasında yerleşik içerikleri çıkarın",
          "Tüm dahili ve harici katılımcılardan gelen girdileri belirleyerek araştırmaları genişletin",
          "Fazlalıkları ve önemli bilgileri tam olarak belirlemek için veri ekosisteminizi derinlemesine analiz edin  ",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={<>Veritas - Yasal ve düzenleyici uyumluluk taleplerini kolayca ele alın.</>}
        lottie={eDiscovery}
      ></HeroVeritas>
      <HorizontalImageText
        imgH
        image={gurl}
        title={<>Araştırmaları basitleştirin ve çözüme ulaşma hızını artırın.</>}
        text={
          <>
            eDiscovery Platform, kuruluşların önemli çözüm dosyalarını hızlı bir şekilde tanımlamasına yardımcı olmak
            için büyük ölçekli, veri kümesine dayalı aramalar yapabilmeye olanak tanıyan güçlü bir motordur. Basit,
            sezgisel bir arabirimle uyumluluk görevlilerinin ve yasal BT personelinin riskleri daha iyi yönetmek için
            daha bilinçli kararlar vermesini sağlar.
          </>
        }
      ></HorizontalImageText>
      <TitleAndText
        title={<>E-keşif maliyetlerini %90’a kadar azaltın.</>}
        text={
          <>
            eDiscovery Platform, tüm dijital uyumluluk ihtiyaçlarınız için hızlı ve doğru sonuçlar almanızı
            kolaylaştırıyor.
          </>
        }
      ></TitleAndText>
      <GridIcons vertical={true} items={gridItems}></GridIcons>
      <PinnedInfo items={veritasSliderItems}></PinnedInfo>
    </>
  )
}
