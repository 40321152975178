import React from "react";
import s from "../assets/scss/pinnedMobile.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/scss/slickReset.scss";

import Slider from "react-slick";

import layerBlue from "../assets/video/layer-blue.mp4";
import cylinderBlue from "../assets/video/cylinder-blue.mp4";
import cubeBlue from "../assets/video/cube-blue.mp4";
import sphereBlue from "../assets/video/sphere-blue.mp4";
import AnimatedButton from "./AnimatedButton";
import { Link } from "react-router-dom";

export default function PinnedMobile() {
  const items = [
    {
      title: "İyileştirme Hizmetleri",
      text: "Doğru işletilen bir bilgi teknolojileri altyapısı ile rakiplerinize karşı avantaj elde edin.",
      video: sphereBlue,
      path: "/hizmetler/iyilestirme-hizmetleri",
    },
    {
      title: "Danışmanlık Hizmetleri",
      text: "Detaylı analizler ile en iyi stratejileri oluşturun.",
      video: layerBlue,
      path: "/hizmetler/danismanlik-hizmetleri",
    },
    {
      title: "Teknik Destek ve Bakım Hizmetleri",
      text: "Teknik aksaklıklarla uğraşmadan iş sürekliliğinizi en üst seviyede tutun.",
      video: cubeBlue,
      path: "/hizmetler/teknik-destek-ve-bakim-hizmetleri",
    },
    {
      title: "Kurulum Hizmetleri",
      text: "Sistemlerinizin verimli ve sorunsuz şekilde çalışmasını sağlayın.",
      video: cylinderBlue,
      path: "/hizmetler/kurulum-hizmetleri",
    },
  ];

  const settings = {
    speed: 400,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div
      className={s.pinnedMobile}
      // style={{ display: visible ? "block" : "none" }}
    >
      <Slider {...settings}>
        {items &&
          items.map((item, i) => {
            return (
              <div key={i}>
                <Link to={item.path} className={s.pinnedItem}>
                  <small className={s.title}>{item.title}</small>
                  <p className={s.text}>{item.text}</p>
                  <div className={s.videoWrapper}>
                    <video
                      className={s.video}
                      autoPlay
                      loop
                      muted
                      playsInline
                      src={item.video}
                    ></video>
                  </div>
                  <div className={s.btnWrapper}>
                    <AnimatedButton text="Hemen İnceleyin"></AnimatedButton>
                  </div>
                </Link>
              </div>
            );
          })}
      </Slider>
    </div>
  );
}
