import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import FooterNav from "../../../components/FooterNav";
import Hero from "../../../components/Hero";
import ImageWithText from "../../../components/ImageWithText";
import RevealWrapper from "../../../components/RevealWrapper";
import SolutionsList from "../../../components/SolutionsList";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";
import { appRoutes } from "../../../utils/routes";
import { alignmentType } from "../../../utils/types";

import bannerImgV from "../../../assets/img/hero/antivirus.jpg";
import bannerVideo from "../../../assets/video/hero/antivirus.mp4";
import videoMiddle from "../../../assets/video/antivirus-endpoint.mp4";
import parallaxIcon from "../../../assets/img/shapes/antivirus-sphere.svg";

export default function Antivirus() {
  const solutions = [
    {
      title: "Symantec Endpoint Security",
      desc: "Symantec’in yenilikçi uç nokta güvenlik çözümleri, verilerin bulunduğu her yerde dizüstü bilgisayarları, masaüstü bilgisayarları, mobil cihazları, sunucuları, uygulamaları, bulut iş yüklerini, konteynerları ve depolama cihazlarını koruyor. Symantec’in Endpoint Security Enterprise ve Endpoint Security Complete olmak üzere iki farklı çözümü bulunuyor.",
    },
    {
      title: "Endpoint Security Enterprise",
      desc: "Güvenliği çok katmanlı savunma ile güçlendiren Symantec Endpoint Security Enterprise, bulut üzerinden yönetilebiliyor ve modern uç nokta cihazlarına üst düzey koruma sağlıyor. Fidye yazılımları, virüsler, casus yazılımlar ve diğer bilinmeyen saldırılara karşı üstün koruma sağlayan çözüm, Symantec Global Intelligence Network ile desteklenen makine öğrenimi ile güvenliği en üst düzeye çıkarıyor ve yanlış pozitifleri en aza indiriyor. Sıfırıncı gün saldırılarına karşı güvenlik sağlayan çözüm en iyi güvenlik duruşu için ince ayar yapılmasına olanak tanıyor.",
    },
    {
      title: "Endpoint Security Complete",
      desc: "Endpoint Security Enterprise çözümünü saldırı yüzeyi azaltma, uç nokta tespit ve müdahale (EDR), tehditleri bulma, Active Directory güvenliği ve diğer gelişmiş teknolojilerle daha da ileri taşıyor. Yapay zeka destekli dünyanın en entegre uç nokta güvenlik platformu olarak tek aracı ve tek konsol mimarisiyle bulut tabanlı koruma sağlıyor. Birçok yenilikçi güvenlik katmanını bir araya getirerek kolay dağıtım ve yönetim ile yapılan yatırımın hızla geri dönüşünü sağlıyor.",
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Siber Güvenlik Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.cyberSecurity.children);
    navStore.setTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setNavTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.cyberSecurity.children.antivirus.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        video={bannerVideo}
        title={<>Antivirüs</>}
        desc={
          <>
            İşletmelerin ve çalışanların günümüzde en çok karşılaştıkları
            sorunlardan biri virüsler ve casus yazılımlar gibi zararlı
            yazılımlardır.
          </>
        }
        bottom={{
          title: (
            <>
              Uzaktan çalışmanın yaygınlaştığı günümüzde çalışanlar ofis
              ortamının sağladığı güvenlikten uzakta çalışıyorlar ve dikkatsiz
              davranabiliyorlar.
            </>
          ),
          text: {
            part1: (
              <>
                Bu nedenle siber güvenliğin en önemli bileşenlerinden biri olan
                ve son kullanıcıları{" "}
                <strong>
                  zararlı yazılımlara karşı koruyan antivirüs çözümleri giderek
                  daha fazla önem kazanıyor.
                </strong>
              </>
            ),
            part2: null,
          },
        }}
      ></Hero>
      <ImageWithText
        alignment={alignmentType.center}
        singleText={
          <>
            Siber güvenlik alanında dünya genelinde yaşanan uzman personel
            eksikliği, birden fazla güvenlik ürününü bir arada çalıştırmanın
            getirdiği karmaşıklık, siber güvenlik alanındaki hızlı değişim,
            uzaktan ve hibrit çalışmanın getirdiği riskler, mali açıdan
            yetersizlik gibi sorunlar{" "}
            <strong>
              başta KOBİ’ler olmak üzere işletmeler açısından büyük zorluklara
              neden oluyor.
            </strong>
          </>
        }
        video={videoMiddle}
        icon={parallaxIcon}
      ></ImageWithText>
      <SolutionsList
        preText={
          <>
            Ateon olarak uzman ekiplerimizle zararlı yazılımlara karşı her zaman
            güvende kalmalarını sağlayan <span>dünyanın en iyi</span> antivirüs
            çözümlerini sunuyoruz.
          </>
        }
        items={solutions}
      ></SolutionsList>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer siber güvenlik çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.cyberSecurity.children}
          hideIndex={
            appRoutes.solutions.children.cyberSecurity.children.antivirus.type
          }
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
