import React from "react";
import { Outlet } from "react-router-dom";
import ContactUs from "../../components/ContactUs";

import RevealWrapper from "../../components/RevealWrapper";
import TableNav from "../../components/TableNav";

export default function VeritasLayout() {
  return (
    <>
      <Outlet />
      <TableNav></TableNav>
      <RevealWrapper>
        <ContactUs
          text={
            <>
              Veritas çözümlerinde uzun yıllara dayanan uzmanlığıyla küçükten
              büyüğe tüm şirketlerin verilerini en iyi şekilde yönetmelerine ve
              korumalarına yardımcı oluyoruz.
            </>
          }
        ></ContactUs>
      </RevealWrapper>
    </>
  );
}
