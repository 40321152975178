import React, { useRef, useState } from "react";
import s from "../assets/scss/horizontalImageText.module.scss";

import cx from "classnames";

import videoPlay from "../assets/img/play.svg";

export default function HorizontalImageText({
  image = "",
  title = "",
  text = "",
  imgH = false,
  video = "",
}) {
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideo = () => {
    console.log(videoRef.current);

    if (videoRef.current.playing) {
      setIsPlaying(false);
      videoRef.current.pause();
    } else if (videoRef.current.paused) {
      setIsPlaying(true);
      videoRef.current.play();
    } else {
      setIsPlaying(true);
      videoRef.current.play();
    }
  };

  return (
    <section className={s.horizontalImageText}>
      <div className={cx(s.mediaWrapper, { [s.horizontal]: imgH })}>
        {!image ? (
          <div className={s.videoWrapper}>
            <div
              className={s.thumb}
              style={{
                transition: "0.4s all ease",
                opacity: isPlaying ? "0" : "1",
                visibility: isPlaying ? "hidden" : "visible",
              }}
            >
              <img
                className={s.play}
                src={videoPlay}
                alt="Thumbnail"
                onClick={handleVideo}
              />
            </div>
            <video ref={videoRef} className={s.video} src={video} controls />
          </div>
        ) : (
          <div className={s.imgWrapper} data-scaling-img-wrapper>
            <img
              data-scaling-img
              className={s.img}
              src={image}
              alt="Technology Company"
            />
          </div>
        )}
      </div>

      <div className={cx(s.textWrapper, { [s.horizontal]: imgH })}>
        <h2 className={s.title}>{title}</h2>
        <p className={s.text}>{text}</p>
      </div>
    </section>
  );
}
