import React, { useRef, useState } from "react";
import s from "../assets/scss/singleMedia.module.scss";

import videoPlay from "../assets/img/play.svg";

export default function SingleMedia({
  video = "",
  image = "",
  alt = "",
  height = "",
  width = "",
}) {
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideo = () => {
    console.log(videoRef.current);

    if (videoRef.current.playing) {
      setIsPlaying(false);
      videoRef.current.pause();
    } else if (videoRef.current.paused) {
      setIsPlaying(true);
      videoRef.current.play();
    } else {
      setIsPlaying(true);
      videoRef.current.play();
    }
  };

  return (
    <section className={s.singleMedia}>
      <div className={s.mediaWrapper} style={{ height, width }}>
        {!image ? (
          <div className={s.videoWrapper}>
            <div
              className={s.thumb}
              style={{
                transition: "0.4s all ease",
                opacity: isPlaying ? "0" : "1",
                visibility: isPlaying ? "hidden" : "visible",
              }}
            >
              <img
                className={s.play}
                src={videoPlay}
                alt="Thumbnail"
                onClick={handleVideo}
              />
            </div>
            <video
              style={{
                opacity: !isPlaying ? "0" : "1",
                visibility: !isPlaying ? "hidden" : "visible",
              }}
              ref={videoRef}
              className={s.video}
              src={video}
              controls
            />
          </div>
        ) : (
          <div className={s.imgWrapper}>
            <img className={s.img} src={image} alt="Technology Company" />
          </div>
        )}
      </div>
    </section>
  );
}
