import React, { useEffect } from "react"

import GridIcons from "../../../components/GridIcons"
import HeroVeritas from "../../../components/HeroVeritas"
import HorizontalImageText from "../../../components/HorizontalImageText"
import PinnedInfo from "../../../components/PinnedInfo"
import TitleAndText from "../../../components/TitleAndText"

import enterpriseVaultCloud from "../../../assets/lottie/veritas/enterpriseVaultCloud.json"

// import gurl from "../../../assets/img/veritas-sample.png";
import useBreadcrumbStore from "../../../stores/breadcrumbStore"

import flex from "../../../assets/icons/veritas/flex.svg"
import flexScale from "../../../assets/icons/veritas/flex-scale.svg"
import accessAppliance from "../../../assets/icons/veritas/access-appliance.svg"
import OrderedGrid from "../../../components/OrderedGrid"
import SingleImage from "../../../components/SingleMedia"

import techDoc from "../../../assets/pdf/SB_cloud_scale_technology_V1515.pdf"
import techDoc2 from "../../../assets/pdf/SB_netbackup_kubernetes_data_protection_V1401.pdf"

export default function NetBackup() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Data Protection", sub: "NetBackup" })
  }, [setBc])

  const gridItems = [
    {
      title: { icon: flex, text: "NetBackup Flex" },
      items: "Çok kiracılı yetenekleriyle NetBackup veri korumasını genişletir.",
    },
    {
      title: { icon: flexScale, text: "NetBackup Flex Scale" },
      items: "Ölçeklenebilir bir mimariyle otomatikleştirilmiş veri koruma hizmetleri.",
    },
    {
      title: { icon: accessAppliance, text: "NetBackup Access Appliance" },
      items: "Kanıtlanmış fidye yazılımlarına karşı direnci ile uzun vadeli veri saklama.",
    },
  ]

  const orderedGridItems = [
    {
      text: "Entegre NetBackup SaaS Veri Koruması ile daha düşük toplam sahip olma maliyeti elde edin.",
      clip: null,
      order: 0,
      visible: true,
      customIndex: 1,
    },
    {
      text: "BT analitiği ile performansı optimize edin, riskleri azaltın ve bulut maliyetlerini azaltın.",
      clip: null,
      order: 1,
      visible: true,
      customIndex: 2,
    },
    {
      text: "AWS, Azure ve Google Cloud Platform gibi bulut ortamları genelinde yönetilen iş yükü geçişiyle esneklik sağlayın.",
      clip: null,
      order: 2,
      visible: true,
      customIndex: 3,
    },
    {
      order: 3,
      visible: false,
    },
    {
      text: "Yedekleme görevlerini, implementasyon özelliklerini ve işlevlerini mevcut NetBackup altyapısında kolayca yönetmek ve otomatikleştirmek için 200’den fazla API’den yararlanın.",
      clip: null,
      order: 4,
      visible: true,
      customIndex: 4,
    },
    {
      text: "Tek tıklamayla kurtarma, özel komut dosyası oluşturma ve olağanüstü durum kurtarma senaryolarıyla olağanüstü durum kurtarmayı bulutta uygun ölçekte otomatikleştirin ve yönetin.",
      clip: null,
      order: 5,
      visible: true,
      customIndex: 5,
    },
  ]

  const orderedGridItems2 = [
    {
      text: "Kubernetes platformlarının tek bir platformda buluşması, NetBackup ortamına en uygun dağıtık bulut stratejilerini uygulama seçeneği ve esnekliği sağlar.",
      clip: null,
      order: 0,
      visible: true,
    },
    {
      text: "Platformlar genelinde çoklu bulut kurtarma ile istenilen şeyleri istenilen hızla kurtarmaya olanak tanır.",
      clip: null,
      order: 1,
      visible: true,
    },
    {
      text: "Koruma ve kurtarma işlemleri yerel Kubernetes araçları kullanılarak yönetilebilir.",
      clip: null,
      order: 2,
      visible: true,
    },
  ]

  const veritasSliderItems = [
    {
      title: "Koruma",
      media: null,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Depolamadan bağımsız değişmezlik ile veri bütünlüğü",
          "Rol tabanlı erişim denetimi (RBAC)",
          "Çok faktörlü kimlik doğrulama",
          "Veri taşıma sırasında ve hareketsiz verilerde askeri düzeyde şifreleme",
        ],
        btnText: null,
      },
    },
    {
      title: "Tespit",
      media: null,
      pdf: null,
      detail: {
        text: null,
        list: [
          "Uç noktalardan veri merkezlerine ve buluta kadar tehditlere karşı koruma",
          "Eksiksiz altyapı ve veri görünürlüğü",
          "Yapay zeka destekli anormallik algılama",
          "Otomatik kötü amaçlı yazılım taraması",
        ],
        btnText: null,
      },
    },
    {
      title: "Kurtarma",
      media: null,
      pdf: null,
      detail: {
        text: "Kullanılmayan depolama alanını belirlemek ve geri almak için binlerce benzersiz veri noktası toplayın.",
        list: [
          "Geniş ölçekte yönetilen otomatik kurtarma",
          "Son bilinen kötü amaçlı yazılım içermeyen yedeklemelerin görünürlüğü",
          "Kurtarmadan önce virüslü içeriğin sonlandırılması",
          "Sıfır fidye, temiz kurtarma",
        ],
        btnText: null,
      },
    },
    {
      title: "ChargeBack IT",
      media: null,
      pdf: null,
      detail: {
        text: "Tüm ortamlardan ayrıntılı harcama raporlaması.",
        list: [
          "Tüm depolama varlıklarından ayrıntılı harcama verileri alın",
          "Raporlamayı belirli gereksinimleri karşılayacak şekilde düzenleyin ve ayarlayın",
          "Tüketici maliyet analizini ve dijital ayak izini görüntüleyin",
          "Tüm departman tüketimlerinin doğru bir şekilde raporlanmasını sağlayın",
          "Hizmet Olarak Altyapı (IaaS) maliyet sorumluluğu ile optimizasyon elde edin",
        ],
        btnText: null,
      },
    },
    {
      title: "Otomatikleştirilmiş operasyonlar",
      media: null,
      pdf: null,
      detail: {
        text: "Sürekli koruma ve veri optimizasyonu sağlarken yedekleme ve kurtarma süreçlerini basitleştirir. Daha az gözetim gerektirerek kaynakların daha verimli bir şekilde yönetilmesine ve maliyetlerin azaltılmasına olanak tanır.",
        list: [
          "NetBackup'ın AWS ve Azure için Elastic Cloud Autoscaling özelliği ile bulut sunucu kullanımını %40'a kadar azaltın",
          "Elastic Cloud Data Deduplication ve Snapshot Hizmetleri ile bulut depolama maliyetini ve karbon ayak izini %95'e kadar azaltın",
          "AWS, Azure ve Google Cloud Platform için düzenlenmiş, otomatikleştirilmiş, uygun ölçekte yedekleme ve olağanüstü durum kurtarmadan faydalanın",
          "Karmaşık, çok katmanlı uygulamalar için birden çok hizmet seviyesinden yararlanın",
          "Denetim raporları ve kesintiye yol açmayan kurtarma senaryoları aracılığıyla en katı SLA'larla dahi uyumlu olun",
        ],
        btnText: null,
      },
    },
    {
      title: "Buluttaki sanal ortamlar için yedekleme",
      media: null,
      pdf: null,
      detail: {
        text: "NetBackup, sanal makine ortamınızla ölçeklenebilen bulutta aracısız yedekleme, anında erişim ve güvenilir granüler veri kurtarma olanağı sunuyor.",
        list: [
          "VMware, Microsoft Hyper-V, Nutanix AHV ve Red Hat OpenShift dahil sanal ortamlar için veri koruması",
          "Granular Recovery Technology, tek tek dosya ve klasörlerden sanal makinelere ve VMDK'lere kurtarmayı optimize eder",
          "Yedek disk depolama alanından önyükleyerek sanal makineleri anında kurtarın ve uygulamaları birincil depoya taşırken bunlara erişim sağlayın",
          "Sanal makineyi tam olarak çalışır hale getirmeden ve taramadan dosyaları veya klasörleri bulun ve geri yükleyin",
          "Aracısız yedeklemelerden ve donanım anlık görüntülerinden sanal makine nesnelerini geri yükleyin",
          "Kaynak sınırları, ESXi ana bilgisayarı başına yükü en aza indirir ve ana bilgisayarlar arasında yedeklemeleri dengeler",
          "Ayrı ayrı veya birden çok taşıma yöntemi kullanarak depolama sistemleri ve bulutlar genelinde anlık görüntü entegrasyonu olanağı tanır",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={<>Veritas - Sınıfının en iyisi kurumsal yedekleme ve kurtarma çözümü.</>}
        lottie={enterpriseVaultCloud}
      ></HeroVeritas>
      <HorizontalImageText
        video
        imgH
        title={<>Otomatik ve güvenli çoklu bulut için optimize edilmiş veri yönetimi.</>}
        text={
          <>
            CloudScale teknolojisine sahip NetBackup, tüm ölçeklerde ve tüm bulut ortamlarında çevikliği ve veri
            güvenliğini geliştirmek için otomasyon, yapay zeka ve güçlü bir mimariyi bir araya getiren sektördeki tek
            kurumsal veri yönetimi çözümüdür. Şu an hiçbir çözüm, altında yönetilen 100 eksabaytın üzerinde bilginin
            yanına dahi yaklaşamıyor.
          </>
        }
      ></HorizontalImageText>
      <TitleAndText
        title={<>Fortune Global 500 listesindeki şirketlerin %87’si NetBackup’ı tercih etti.</>}
        text={<>NetBackup’ın entegre yaklaşımı, benzersiz performans ve maliyet tasarrufu sağlıyor.</>}
      ></TitleAndText>
      <GridIcons vertical={true} items={gridItems}></GridIcons>
      <TitleAndText
        title={<>Çoklu bulut optimizasyonu ile veri yönetimini basitleştirin.</>}
        text={
          <>
            Performans ve veri korumayı tüm uygulamalar ve ortamlar genelinde kolayca ölçeklendirin. Maliyetleri
            azaltırken güvenliği artırmak için çoklu bulut zekasından faydalanın.
          </>
        }
      ></TitleAndText>
      <OrderedGrid
        preText={<>NetBackup Avantajları</>}
        preTextWidth="22vw"
        leftAligned
        row={2}
        col={3}
        items={orderedGridItems}
        color={"#B9303F"}
      ></OrderedGrid>
      <SingleImage></SingleImage>
      <TitleAndText
        smallText={<>Cloud Scale Teknolojisi ile tanışın.</>}
        title={<>Sektörün ilk çoklu bulut için optimize edilmiş veri koruma mimarisi.</>}
        btnText={<>Çözüm Özetini Görüntüleyin</>}
        bgColor={"#002855"}
        fontColor={"#ffffff"}
        url={techDoc}
      ></TitleAndText>
      <TitleAndText
        title={<>Yerel Kubernetes desteğiyle çoklu bulut mobilitesinin kilidini açın.</>}
        text={
          <>
            NetBackup, tek bir tümleşik veri koruma çözümü olarak nokta ürünlere ihtiyacı ortadan kaldırıyor ve
            Kubernetes’in kritik görev iş yükleri ve uygulamalarının yedekleme ve kurtarma işlemlerini büyük ölçüde
            basitleştiriyor.
          </>
        }
        btnText={<>Detaylı İncele</>}
        url={techDoc2}
      ></TitleAndText>
      <OrderedGrid row={1} col={3} items={orderedGridItems2} color={"#B9303F"}></OrderedGrid>
      <TitleAndText
        title={<>Siber dayanıklılık stratejinizdeki boşlukları kapatın.</>}
        text={
          <>
            Veri korumasının ötesine geçmek için tasarlanan tümleşik platform yaklaşımıyla verilerinizi koruyun.
            NetBackup, fidye yazılımlarına karşı dayanıklılık sağlayan çok katmanlı, proaktif bir çözüm sunar.
          </>
        }
      ></TitleAndText>
      <PinnedInfo detailsHeight="90vh" items={veritasSliderItems}></PinnedInfo>
      <TitleAndText
        title={<>NetBackup 10 sürümündeki en son yenilikleri keşfedin.</>}
        btnText={<>Detaylı İncele</>}
        bgColor={"#002855"}
        fontColor={"#ffffff"}
        url={"https://www.veritas.com/protection/netbackup/whats-new"}
      ></TitleAndText>
    </>
  )
}
