import React, { useEffect } from "react";
import s from "../../assets/scss/services.module.scss";

import HeroServices from "../../components/HeroServices";
import cylinderWhite from "../../assets/video/cylinder-white.mp4";
import OrderedGrid from "../../components/OrderedGrid";
import RevealWrapper from "../../components/RevealWrapper";
import ContactUs from "../../components/ContactUs";
import FooterNav from "../../components/FooterNav";
import { appRoutes } from "../../utils/routes";
import useBreadcrumbStore from "../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../stores/stickyNavStore";

import heroImg from "../../assets/img/hero-services/teknik.jpg";
import floatingImg from "../../assets/img/teknik_alt.jpg";

export default function Technical() {
  const gridItems = [
    {
      text: "Dizüstü ve masaüstü bilgisayarlar gibi son kullanıcı cihazları için kurulum, bakım ve sorun giderme.",
      visible: true,
    },
    {
      text: "Son kullanıcı cihazlarında siber antivirüs gibi siber güvenlik çözümlerinin kurulumu ve bakımı.",
      visible: true,
    },
    {
      text: "Son kullanıcı cihazları ve sunucular dahil olmak üzere yedekleme ve kurtarma.",
      clip: "topRight",
      visible: true,
    },
    {
      text: "İşletme genelinde veri güvenliği.",
      visible: true,
    },
    {
      text: "Sunucu kurulumu ve bakımı.",
      visible: true,
    },
    {
      text: "Windows, macOS ve Linux dahil işletim sistemi kurulumu, bakımı ve güncellemeleri.",
      visible: true,
    },
    {
      visible: false,
    },
    {
      text: "Kurumsal e-posta sistemleri için kurulum, bakım ve destek.",
      customIndex: 7,
      visible: true,
    },
    {
      text: "BT altyapısının analizi ve iyileştirmeye yönelik öneriler.",
      clip: "bottomRight",
      customIndex: 8,
      visible: true,
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Hizmetler" });
    navStore.setChildren(appRoutes.services.children);
    navStore.setTitle("Diğer hizmetlerimizi inceleyin");
    navStore.setNavTitle("Diğer hizmetlerimizi inceleyin");
    navStore.setHiddenChild(appRoutes.services.children.technical.type);
  }, [setBc]);

  return (
    <main className={s.services}>
      <HeroServices
        image={heroImg}
        title={<>Teknik Destek ve Bakım Hizmetleri</>}
        video={cylinderWhite}
        floatingImg={floatingImg}
        desc={
          <>
            Günümüzde giderek karmaşıklaşan ve dağıtık hale gelen BT
            altyapıları, çok sayıda donanım ve yazılımdan ulaşıyor. Bu,
            sistemlerin bakımını ve herhangi bir sorun ortaya çıkması durumunda
            destek verilmesini zorlaştırıyor.
          </>
        }
        bottom={{
          title: (
            <>
              İş sürekliliğini en üst seviyede tutmak ve kesintileri en aza
              indirmek için alanında uzman kadromuzla planlama, uygulama,
              yönetim dahil olmak üzere üstün destek ve bakım hizmetleri
              sunuyoruz.
            </>
          ),
          texts: {
            part1: (
              <>
                Ateon olarak giderek karmaşıklaşan BT ortamınızı koruyor ve
                işletmenizin asıl işine odaklanmasına olanak tanıyoruz.
              </>
            ),
            part2: (
              <>
                Müşterilerimizin işlerine kesintisiz bir şekilde devam
                edebilmelerine olanak tanıyan çeşitli destek ve bakım hizmetleri
                sunuyoruz. SLA anlaşmaları kapsamında uzaktan ve yerinde destek
                ve bakım hizmetleri sağlıyoruz. Herhangi bir sorun oluştuğunda
                sorunun en kısa sürede çözülmesi için müşterilerimizle yakın
                çalışıyoruz.
              </>
            ),
          },
        }}
      ></HeroServices>
      <OrderedGrid
        preText={<>Teknik destek ve bakım kapsamında verdiğimiz hizmetler.</>}
        items={gridItems}
        chess={true}
        row={3}
        col={3}
      ></OrderedGrid>
      <RevealWrapper>
        <ContactUs
          text={
            <>
              Donanım, yazılım, ağ teknolojileri ve diğer alanlarda uzun yıllara
              dayanan deneyimimizle ayrışan üstün destek ve bakım hizmetleri
              sunuyoruz.
            </>
          }
        ></ContactUs>
      </RevealWrapper>
      <FooterNav
        title={<>Diğer hizmetlerimizi inceleyin</>}
        items={appRoutes.services.children}
        hideIndex={appRoutes.services.children.technical.type}
      ></FooterNav>
    </main>
  );
}
