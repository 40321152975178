import React, { useEffect, useRef, useState } from "react";
import s from "../assets/scss/carousel.module.scss";

import quotationLeft from "../assets/img/quotation-left.svg";
import quotationRight from "../assets/img/quotation-right.svg";
import carouselArrowLeft from "../assets/img/carousel-arrow-left.svg";
import carouselArrowRight from "../assets/img/carousel-arrow-right.svg";

import gsap, { Expo } from "gsap";

export default function Carousel({
  carouselItems = [{ id: "", name: "", role: "", image: "", comment: "" }],
}) {
  const carouselWrapper = useRef(null);
  const q = gsap.utils.selector(carouselWrapper);
  const carouselTL = useRef(null);
  const [currentItem, setCurrentItem] = useState(0);

  const handleItemChange = (val) => {
    if (val === "INCREASE") {
      setCurrentItem((currentItem + 1) % carouselItems.length);
    } else if (val === "DECREASE") {
      if (currentItem === 0) {
        setCurrentItem(carouselItems.length - 1);
      } else {
        setCurrentItem(currentItem - 1);
      }
    }
  };

  useEffect(() => {
    carouselTL.current = gsap.timeline({ paused: true });

    carouselTL.current
      .add("s")
      .fromTo(
        q("[data-quote]"),
        {
          xPercent: 50,
          autoAlpha: 0,
        },
        {
          xPercent: 0,
          autoAlpha: 1,
          duration: 1.2,
          ease: Expo.easeOut,
        },
        "s"
      )
      .fromTo(
        q("[data-img-wrapper]"),
        {
          xPercent: 500,
        },
        {
          xPercent: 0,
          duration: 1.6,
          ease: Expo.easeOut,
        },
        "s"
      )
      .fromTo(
        q("[data-info]"),
        {
          xPercent: 200,
          autoAlpha: 0,
        },
        {
          xPercent: 0,
          autoAlpha: 1,
          duration: 1.6,
          ease: Expo.easeOut,
        },
        "s"
      );
  }, [q]);

  useEffect(() => {
    carouselTL.current.play(0);
  }, [currentItem]);

  return (
    <div className={s.carousel} ref={carouselWrapper}>
      <div className={s.quoteWrapper}>
        <h2 className={s.quote} data-quote>
          "I used to spend hours writing creative copy, but now all I do is tell
          Rytr what I need and it writes everything for me. It's the ultimate AI
          content writer, and a must-have tool for bloggers, marketers, &
          entrepreneurs."
          {/* <span>
            <img
              data-qm
              className={s.qmLeft}
              src={quotationLeft}
              alt="Quotation"
            />
          </span> */}
          {/* "{carouselItems[currentItem].comment}" */}
          {/* <span>
            <img
              data-qm
              className={s.qmRight}
              src={quotationRight}
              alt="Quotation"
            />
          </span> */}
        </h2>
      </div>

      <div className={s.controls}>
        <div className={s.indicator}>
          <p className={s.text}>
            {currentItem + 1}/{carouselItems.length}
          </p>
        </div>
        <div className={s.buttonWrapper}>
          <button
            className={s.btn}
            onClick={() => handleItemChange("INCREASE")}
          >
            <img className={s.arrow} src={carouselArrowLeft} alt="Arrow" />
          </button>
          <button
            className={s.btn}
            onClick={() => handleItemChange("DECREASE")}
          >
            <img className={s.arrow} src={carouselArrowRight} alt="Arrow" />
          </button>
        </div>
        <div className={s.author}>
          <div className={s.imgWrapper} data-img-wrapper>
            <img
              className={s.img}
              src={carouselItems[currentItem].image}
              alt="Person"
            />
          </div>
          <div className={s.info} data-info>
            <h5 className={s.personName}>{carouselItems[currentItem].name}</h5>
            <h6 className={s.personTitle}>{carouselItems[currentItem].role}</h6>
          </div>
        </div>
      </div>
    </div>
  );
}
