import React, { useEffect, useRef } from "react";
import s from "../assets/scss/achievementCard.module.scss";

import useIntersection from "../hooks/useIntersection";
import gsap from "gsap";
import { CustomEase } from "gsap/all";
import { breakpointTypes } from "../utils/types";
import useWindowSize from "../hooks/useWindowSize";

export default function AchievementCard({
  item = {
    tag: "",
    icon: "",
    numbers: [],
    text: "",
  },
}) {
  const windowSize = useWindowSize();
  const videoRef = useRef(null);
  const numRef = useRef(null);
  const q = gsap.utils.selector(numRef);
  const onScreen = useIntersection(numRef);
  const animatedRef = useRef(false);

  useEffect(() => {
    if (onScreen && !animatedRef.current) {
      animate();
    }
  }, [onScreen]);

  const counterTL = useRef(null);

  const y = () => {
    return windowSize.width > breakpointTypes.tablet
      ? "-=100"
      : windowSize.width > breakpointTypes.mobile
      ? "-=60"
      : "-=65";
  };

  useEffect(() => {
    const items = q("[data-num]");
    // const itemWrappers = q("[data-num-wrapper]");

    const easeFunc = CustomEase.create("easeName", "0.43, 0.13, 0.23, 0.96");
    const duration = 0.8;

    counterTL.current = gsap.timeline({ paused: true }).add("sync").to(
      items,
      {
        y: y(),
        ease: easeFunc,
        duration,
        stagger: 0.1,
      },
      "sync"
    );
  }, []);

  const animate = () => {
    counterTL.current.play();
    videoRef.current.play();
    animatedRef.current = true;
  };

  return (
    <div className={s.cardWrapper} onClick={animate}>
      <small className={s.tag}>{item.tag}</small>
      <div className={s.bottom}>
        <div className={s.animatedArea}>
          <div className={s.iconWrapper}>
            {/* <img data-gif className={s.icon} src={item.icon} alt="Card Icon" /> */}
            <video
              ref={videoRef}
              data-parallax-rect-outer
              className={s.icon}
              src={item.icon}
              muted
            />
          </div>
          <div className={s.oWrapper} ref={numRef}>
            <div className={s.numbers}>
              {item.numbers.map((num, i) => {
                return (
                  <div className={s.number} key={i}>
                    {num.map((digit, i) => {
                      return (
                        <div className={s.digit} data-num-wrapper key={i}>
                          <h3 data-num>{digit}</h3>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={s.description}>
          <p className={s.asterisk}>*</p>
          <p className={s.text}>{item.text}</p>
        </div>
      </div>
    </div>
  );
}
