import React from "react";
import Pinned from "../components/Pinned";
import PinnedMobile from "../components/PinnedMobile";

export default function PinnedWrapper() {
  return (
    <>
      <PinnedMobile />
      <Pinned />
    </>
  );
}
