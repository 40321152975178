import React, { useEffect, useState } from "react";
import s from "../assets/scss/pinnedInfo.module.scss";

import cx from "classnames";

import video from "../assets/video/bg-slider.mp4";
import AnimatedButton from "../components/AnimatedButton";
import IconArrow from "./Icon/IconArrow";
import IconArrowXs from "./Icon/IconArrowXs";
import { useModalStore } from "../stores/modalStore";
import { useRef } from "react";

export default function PinnedInfo({
  items = [
    {
      title: "Güvenlik ve Uyumluluk",
      media: "",
      pdf: "lol",
      detail: {
        text: "Bulut yapılandırmalarından (AWS, Azure, Google vb.)",
        list: [
          "Bulut altyapısını doğru duruma getirerek ve optimize ederek maliyetleri azaltın",
          "Coklu bulut yapılandırmalarından(AWS, Azure, Google vb.) tümleşik öngörüler alın",
          "Kesin maliyetleri anlayın ve genel bulut faturalarını birleştirin",
          "Tüm veri kümeleri için tek bir kayıt kaynağıyla riski azaltın(hibrit ortamlarda)",
        ],
        btnText: "İnfografiği İncele",
      },
    },
    {
      title: "120'nin Üzerinde İçerik Kaynağı",
      media: video,
      pdf: "",
      detail: {
        text: "Tüm BT platformlarında uygulama performansı SLA’larını aşın.",
        list: [
          "Bulut altyapısını doğru duruma getirerek ve optimize ederek maliyetleri azaltın",
        ],
        btnText: "Videoyu İzle",
      },
    },
    {
      title: "Kritik Bilgileri Sınıflandırın",
      media: "",
      pdf: "lol",
      detail: {
        text: "Tüm BT platformlarında uygulama performansı SLA’larını aşın.",
        list: [
          "Sıfır kesinti süresi gerektiren birinci katman uygulamaları koruyun.",
          "Yüksek kullanılabilirlik ve olağanüstü durum kurtarma senaryolarını güvenle yürütün.",
        ],
        btnText: "İnfografiği İncele",
      },
    },
  ],
}) {
  const [currentItem, setCurrentItem] = useState(0);
  const { setVisibility, setContent } = useModalStore();
  const detailsRef = useRef(null);

  const modalContent = (
    <div className={s.videoWrapper}>
      <video
        className={s.video}
        src={items[currentItem].media}
        controls
        autoPlay
        playsInline
      />
    </div>
  );

  const handleModal = () => {
    setVisibility(true);
    setContent(modalContent);
  };
  const h = useRef(0);
  const [itemsHeight, setItemsHeight] = useState(0);

  useEffect(() => {
    h.current = 0;

    const items = Array.from(
      document.querySelectorAll(["[data-detail-item='true']"])
    );
    // console.log(items);

    items.forEach((item) => {
      h.current += item.getBoundingClientRect().height;
    });

    setItemsHeight(h.current);
    // console.log(h.current);
  }, [currentItem]);

  return (
    <div className={s.pinnedInfo}>
      <div className={s.nav}>
        <ul className={s.navItems}>
          {items.map((item, i) => {
            return (
              <li
                key={i}
                className={cx(s.navItem, { [s.active]: currentItem === i })}
                onClick={() => {
                  setCurrentItem(i);
                }}
              >
                <p className={s.itemText}>{item.title}</p>
                <div className={s.arrow}>
                  <IconArrow></IconArrow>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className={s.details}
        ref={detailsRef}
        style={{ height: itemsHeight }}
      >
        {items.map((item, i) => {
          return (
            <div
              key={i}
              className={cx(s.detail, { [s.active]: currentItem === i })}
            >
              <div
                className={s.heightWrapper}
                data-detail-item={currentItem === i ? true : false}
              >
                {item.detail.text && (
                  <p className={s.detailText}>{item.detail.text}</p>
                )}
                <ul className={s.detailList}>
                  {item.detail.list.map((listItem, i) => {
                    return (
                      <li className={s.detailItem} key={i}>
                        <div className={s.arrow}>
                          <IconArrowXs fill={"#EB3F51"}></IconArrowXs>
                        </div>
                        <p className={s.detailItemText}>{listItem}</p>
                      </li>
                    );
                  })}
                </ul>

                {item.media && (
                  <div className={s.btnWrapper} onClick={handleModal}>
                    <AnimatedButton
                      onClick={handleModal}
                      sm
                      text={item.detail.btnText}
                    ></AnimatedButton>
                  </div>
                )}

                {item.pdf && (
                  <a
                    href={item.pdf}
                    target="_blank"
                    rel="noreferrer"
                    className={s.btnWrapper}
                  >
                    <AnimatedButton
                      sm
                      text={item.detail.btnText}
                    ></AnimatedButton>
                  </a>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
