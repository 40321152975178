import React, { useEffect } from "react";
import s from "../assets/scss/pinned.module.scss";

import gsap from "gsap";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";

import scrollDownAnim from "../assets/lottie/scroll-down.json";

import layerBlue from "../assets/video/layer-blue.mp4";
import cylinderBlue from "../assets/video/cylinder-blue.mp4";
import cubeBlue from "../assets/video/cube-blue.mp4";
import sphereBlue from "../assets/video/sphere-blue.mp4";
import AnimatedButton from "./AnimatedButton";
import { appRoutes } from "../utils/routes";

export default function Pinned() {
  useEffect(() => {
    return () => {
      gsap.to("body", {
        background: "#ffffff",
      });
    };
  }, []);

  const services = appRoutes.services;

  const items = [
    {
      title: services.children.recruitment.ui,
      desc: (
        <>
          Doğru işletilen bir bilgi teknolojileri altyapısı ile rakiplerinize
          karşı avantaj elde edin.
        </>
      ),
      path: services.children.recruitment.path,
      media: sphereBlue,
    },
    {
      title: services.children.advisory.ui,
      desc: <>Detaylı analizler ile en iyi stratejileri oluşturun.</>,
      path: services.children.advisory.path,
      media: layerBlue,
    },
    {
      title: services.children.technical.ui,
      desc: (
        <>
          Teknik aksaklıklarla uğraşmadan iş sürekliliğinizi en üst seviyede
          tutun.
        </>
      ),
      path: services.children.technical.path,
      media: cylinderBlue,
    },
    {
      title: services.children.setup.ui,
      desc: (
        <>Sistemlerinizin verimli ve sorunsuz şekilde çalışmasını sağlayın.</>
      ),
      path: services.children.setup.path,
      media: cubeBlue,
    },
  ];

  return (
    <section
      className={s.pinned}
      data-pinned
      // style={{ display: visible ? "flex" : "none" }}
    >
      <div className={s.itemWrapper}>
        {items &&
          items.map((item, i) => {
            return (
              <div className={s.item} data-pinned-item key={i}>
                <div className={s.content} data-pinned-content>
                  <small className={s.index}>
                    {i + 1}/{items.length}
                  </small>
                  <div className={s.titleWrapper}>
                    <h2 className={s.title}>{item.title}</h2>
                    <div className={s.bg}></div>
                  </div>
                  <p className={s.text}>{item.desc}</p>
                  <Link to={item.path} className={s.btnWrapper}>
                    <AnimatedButton text="Hemen İnceleyin"></AnimatedButton>
                  </Link>
                </div>
                <div className={s.videoWrapper} data-pinned-video-wrapper>
                  <video
                    className={s.video}
                    autoPlay
                    loop
                    muted
                    playsInline
                    src={item.media}
                  ></video>
                </div>
              </div>
            );
          })}
      </div>
      <div className={s.lottieWrapper}>
        <Lottie
          className={s.lottie}
          loop={true}
          animationData={scrollDownAnim}
          autoPlay={true}
        ></Lottie>
      </div>
    </section>
  );
}
