import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";
import FooterNav from "../../../components/FooterNav";
import Hero from "../../../components/Hero";
import ProductInfo from "../../../components/ProductInfo";
import { appRoutes } from "../../../utils/routes";

import shape from "../../../assets/img/shapes/shape-data-insight.svg";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";

import bannerImgV from "../../../assets/img/hero/izleme-ve-log-cozumleri.jpg";
import bannerVideo from "../../../assets/video/hero/izleme-ve-log-cozumleri.mp4";

export default function TrackingAndLog() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Siber Güvenlik Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.cyberSecurity.children);
    navStore.setTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setNavTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.cyberSecurity.children.trackingAndLog.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        video={bannerVideo}
        title={
          <>
            İzleme ve Log <br /> Çözümleri
          </>
        }
        desc={
          <>
            Tüm ağ ve cihazlar genelinde bilişim sistemleri tarafından
            oluşturulan günlük kayıtlarının yani logların işletmenin
            ihtiyaçlarına göre belirlenen kurallara göre analiz edilmesi büyük
            önem taşıyor.
          </>
        }
        bottom={{
          title: (
            <>
              Günlük kayıtların düzenli olarak izlenmesi ve tehditlere yönelik
              gerçek zamanlı uyarılar oluşturulması gerekmektedir.
            </>
          ),
          text: {
            part1: (
              <>
                Günlük kayıtlarının ayrıntılı bir şekilde toplanması, bir araya
                getirilmesi, saklanması, analiz edilmesi ve sunulması
                gerçekleşebilecek siber saldırıların göstergelerini ve
                kanıtlarını elde edilmesini sağlıyor. Ayrıca bu kayıtların
                toplanması adli olarak inceleme konusunda da yardımcı oluyor.
              </>
            ),
            part2: null,
          },
        }}
      ></Hero>
      <ProductInfo
        title={<>Veritas Data Insight</>}
        desc={
          <>
            Veritas’ın güçlü raporlama platformu Data Insight, uyumlulukla
            ilgili riskleri bulmayı kolaylaştırıyor ve ayrıntılı risk analiziyle
            sürekli uyumluluk sağlıyor.
          </>
        }
        texts={{
          part1: (
            <>
              Yapılandırılmamış dosyaları tarayarak ve değerlendirerek riskleri
              görselleştiriyor ve verilerle ilgili yükümlülükleri ortaya
              koyuyor.
            </>
          ),
          part2: (
            <>
              Önceliklendirme özelliği sayesinde yüksek riskli içeriklerin
              belirlenmesine ve bu içeriklerin bulunduğu dosyaları kimlerin
              kullandığının belirlenmesine yardımcı oluyor. Tahmine dayalı
              analitikle dosya içeriklerini proaktif bir şekilde yönetiyor ve
              denetliyor. Üstün veri yönetişimi ile maliyetleri azaltıyor ve
              kullanımı kolay raporlamalarla veri sınıflandırma politikalarının
              kolayca uygulanabilmesine olanak tanıyor.
            </>
          ),
        }}
        icon={shape}
      ></ProductInfo>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer siber güvenlik çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.cyberSecurity.children}
          hideIndex={
            appRoutes.solutions.children.cyberSecurity.children.trackingAndLog
              .type
          }
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
