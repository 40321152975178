import React from "react";
import s from "../assets/scss/techDetail.module.scss";

export default function TechDetails({ items = [] }) {
  return (
    <section className={s.techDetails}>
      {items.map((item, i) => {
        return (
          <div className={s.detail} key={i}>
            <div className={s.imgWrapper} data-scaling-img-wrapper>
              <img
                data-scaling-img
                className={s.img}
                src={item.image}
                alt="Abstract Tech Visual"
              />
            </div>
            <div className={s.text}>
              <h3 className={s.title}>
                <small className={s.index}>0{i + 1}</small>
                {item.title.part1} <br /> <span> {item.title.part2}</span>
              </h3>
              {item.text.map((p, i) => {
                return (
                  <p key={i} className={s.text}>
                    {p}
                  </p>
                );
              })}
            </div>
          </div>
        );
      })}
    </section>
  );
}
