import React, { useEffect } from "react"

import GridIcons from "../../../components/GridIcons"
import HeroVeritas from "../../../components/HeroVeritas"
import HorizontalImageText from "../../../components/HorizontalImageText"
import PinnedInfo from "../../../components/PinnedInfo"
import TitleAndText from "../../../components/TitleAndText"

import mergeOne from "../../../assets/lottie/veritas/mergeOne.json"

// import gurl from "../../../assets/img/veritas-sample-3.png";
import useBreadcrumbStore from "../../../stores/breadcrumbStore"

import server from "../../../assets/icons/veritas/server.svg"
import risk from "../../../assets/icons/veritas/risk.svg"
import roadmap from "../../../assets/icons/veritas/roadmap.svg"

import finance from "../../../assets/icons/veritas/finance.svg"
import medicine from "../../../assets/icons/veritas/medicine.svg"
import publicIcon from "../../../assets/icons/veritas/public.svg"
import energy from "../../../assets/icons/veritas/energy.svg"
import SingleImage from "../../../components/SingleMedia"

import icons from "../../../assets/img/icons-group.png"

export default function MergeOne() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Compliance", sub: "Merge 1" })
  }, [setBc])

  const gridItems = [
    {
      title: { icon: server, text: "Her Şeyi Toplayın" },
      items: "Güçlü iş ortağı sistemiyle metin, ses, video ve telefon kayıtlarını arşivleyin.",
    },
    {
      title: { icon: risk, text: "Riskleri Minimize Edin" },
      items: "İnandırıcı bilgilerle dahili soruşturmaları ve harici davaları destekleyin.",
    },
    {
      title: { icon: roadmap, text: "Karmaşıklığı Azaltın" },
      items: "Arşivleme ve yönetimi otomatikleştirin, önemsiz verileri ortadan kaldırın ve maliyetleri azaltın.",
    },
  ]

  const gridItems2 = [
    {
      title: { icon: finance, text: "Finans" },
      items: "SEC, FINRA, CFTC, SOX, APRA, MiFID II, IIROC, ESMA",
    },
    {
      title: { icon: medicine, text: "İlaç ve Sağlık" },
      items: "FDA, HIPPA, FDCA, ARRA",
    },
    {
      title: { icon: publicIcon, text: "Kamu ve Özel" },
      items: "FOIA, FISMA, CCPA, GDPR",
    },
    {
      title: { icon: energy, text: "Enerji" },
      items: "FERC, DOE, NERC, CFTC, Eyalet Regülasyonları",
    },
  ]

  const veritasSliderItems = [
    {
      title: "Tamamen özelleştirilebilir arşivleme",
      media: null,
      pdf: null,
      detail: {
        text: "Merge1, sorunsuz arşivleme, bulma ve denetim özelliklerine sahip Veritas Digital Compliance paketine tamamen entegredir. Merge1 arşivden bağımsızdır ve çeşitli kaynaklardan yerel veri toplama yeteneği de dahil olmak üzere hem bulut hem de şirket içi arşivleri destekler.",
        list: [
          "Microsoft 365, Google, Bloomberg, Salesforce, ServiceNow ve daha fazlasından veri toplayın",
          "Entegre Veri Toplama: Sorunsuz bir veri toplama ve arşiv deneyimi için Merge1'in 120'den fazla veri kaynağına doğrudan Enterprise Vault.cloud gibi bulut arşivlerinden erişin",
          "İki arşivi paralel çalıştırmanın ek yükünden ve karmaşıklığından kaçının",
          "Üçüncü bir tarafa güvenmek yerine mevcut altyapıya arşivleyerek riski en aza indirin",
        ],
        btnText: null,
      },
    },
    {
      title: "Tüm verileri arşivleyin",
      media: null,
      pdf: null,
      detail: {
        text: "Özel dosya kaynakları da dahil olmak üzere tüm içerik türleri için destek alın.",
        list: [
          "Çeşitli iletişim verilerini alın ve birleştirin",
          "Uyumlu kalmak için yeni mesaj platformlarına hızla uyum sağlayın",
          "Gelecekteki aramaları geliştirmek için belirli bilgileri işaretleyin",
        ],
        btnText: null,
      },
    },
    {
      title: "Esnek Alım",
      media: null,
      pdf: null,
      detail: {
        text: "Merge1'i şirket içinde veya yönetilen SaaS ortamında çalıştırın.",
        list: [
          "Buluta geçiş stratejinize yönelik veriye dayalı kararlar alın",
          "Taşıma süresini ve maliyetlerini azaltmak için önemsiz dosyaları önceden bulun ve silin",
          "Verileri depolama katmanları arasında taşımadan önce verileri düzenli aralıklarla filtreleyin",
        ],
        btnText: null,
      },
    },
    {
      title: "Kolayca Kullanıma Alın",
      media: null,
      pdf: null,
      detail: {
        text: "Merge1'i mantıklı olan her yerde çalıştırın. Kendi donanımınızla şirket içinde ya da SaaS tabanlı bir konfigürasyonda bulut üzerinden erişerek Merge1'in tüm özelliklerinden faydalanın.",
        list: [
          "Hızlı bir sistem kurulumuyla başlayın",
          "Alım döngülerini çalıştırmaya başlamak için eğitim gerektirmez",
          "Sunucu güncelleme ve bakımı ihtiyacı yoktur",
          "Tümleşik platform çok fazla yer kaplamaz",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={<>Veritas - Yüksek riskli verileri tüm iletişim platformlarında toplayın ve arşivleyin.</>}
        lottie={mergeOne}
      ></HeroVeritas>
      <HorizontalImageText
        video
        imgH
        title={<>Uyumluluk ve e-keşif sonuçlarını optimize edin.</>}
        text={
          <>
            Yüksek riskli içerik, kullanılan iletişim platformlarının her geçen gün artmasıyla daha çok dağılıyor ve bu,
            arşivlemeyi hem zaman alıcı hem de maliyetli bir hale getiriyor. Merge1, mevcut altyapıyı kullanarak işle
            ilgili tüm verileri topluyor ve uyumluluk risklerini hızla tanımlıyor.
          </>
        }
      ></HorizontalImageText>
      <TitleAndText
        title={<>E-keşif maliyetlerini %90’a kadar azaltın.</>}
        text={
          <>Merge1, veriler nerede tutuluyor olursa olsun tüm yüksek riskli verileri bulur ve bağlama göre arşivler.</>
        }
      ></TitleAndText>
      <GridIcons vertical={true} items={gridItems}></GridIcons>
      <PinnedInfo detailsHeight={"45vh"} items={veritasSliderItems}></PinnedInfo>
      <TitleAndText
        title={<>Merge1 bağlayıcıları, arşivi popüler içerik türleriyle genişletir.</>}
        text={
          <>
            Merge1, sosyal medya, sohbet, finansal ve bulut tabanlı içerik dahil olmak üzere kurumsal iletişim
            platformlarında tüm içerikleri arşivlemek için özelleştirilebilir.
          </>
        }
      ></TitleAndText>
      <SingleImage image={icons} height="35vh"></SingleImage>
      <TitleAndText
        title={<>Önemli düzenleyici kurumlarla uyumluluk sağlayın. </>}
        text={
          <>
            Kurumsal yönetişim için iletişimleri koruyun, yasal BT için e-keşif araştırmalarını destekleyin ve diğer tüm
            sektör regülasyonlarını karşılayın.
          </>
        }
      ></TitleAndText>
      <GridIcons items={gridItems2}></GridIcons>
    </>
  )
}
