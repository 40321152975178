import React from "react";
import { Outlet } from "react-router-dom";
import s from "../assets/scss/detail.module.scss";

export default function DetailLayout() {
  return (
    <div className={s.detail}>
      <Outlet />
    </div>
  );
}
