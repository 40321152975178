import React, { useEffect } from "react"
import HeroVeritas from "../../../components/HeroVeritas"

import infoscale from "../../../assets/lottie/veritas/infoscale.json"
import useBreadcrumbStore from "../../../stores/breadcrumbStore"
import HorizontalImageText from "../../../components/HorizontalImageText"

import gurl from "../../../assets/img/veritas/infoscale-banner.jpg"
import TitleAndText from "../../../components/TitleAndText"
import FieldsGrid from "../../../components/FieldsGrid"

import PinnedInfo from "../../../components/PinnedInfo"

import energy from "../../../assets/icons/veritas/energy.svg"
import comms from "../../../assets/icons/veritas/comms.svg"
import finance from "../../../assets/icons/veritas/finance.svg"
import health from "../../../assets/icons/veritas/health.svg"
import medicine from "../../../assets/icons/veritas/medicine.svg"
import tech from "../../../assets/icons/veritas/tech.svg"

import video from "../../../assets/video/bg-slider.mp4"

export default function Infoscale() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Availability", sub: "Infoscale" })
  }, [setBc])

  const gridItems = [
    { icon: energy, text: "Enerji" },
    { icon: comms, text: "Telekomünikasyon" },
    { icon: finance, text: "Finans" },
    { icon: health, text: "Sağlık" },
    { icon: medicine, text: "İlaç" },
    { icon: tech, text: "Teknoloji" },
  ]

  const veritasSliderItems = [
    {
      title: "Uygulama Esnekliği",
      media: video,
      pdf: null,
      detail: {
        text: "Tüm BT platformlarında uygulama performansı SLA'larını aşın.",
        list: [
          "Sıfır kesinti süresi gerektiren birinci katman uygulamaları koruyun",
          "Yüksek kullanılabilirlik ve olağanüstü durum kurtarma senaryolarını güvenle yürütün",
          "Tüm fiziksel, bulut ve şirket içi ortamlarda görev açısından kritik hizmetleri yönetin",
        ],
        btnText: "Videoyu İzle",
      },
    },
    {
      title: "Hibrit bulut ortamına hazır",
      media: null,
      pdf: null,
      detail: {
        text: "Tüm bulut modellerinde uygulama performansı ve kullanılabilirliği.",
        list: [
          "Görev kritik uygulamalar için iş sürekliliği",
          "Bulut ortamları genelinde akıllı, politika tabanlı veri hareketi",
          "Karmaşık, çok katmanlı hizmetlerin basit yönetimi",
        ],
        btnText: null,
      },
    },
    {
      title: "İş Çevikliği",
      media: null,
      pdf: null,
      detail: {
        text: "Optimum performans ve öngörülebilir işletim giderleri için uygulama taşınabilirliğini güçlendirin.",
        list: [
          "Uygulamaları ve verileri buluta taşıyarak masrafları azaltın",
          "Fiziksel, sanal, bulut ve konteyner platformları arasında geçiş yapın",
          "Tek tedarikçiye bağımlı kalmaktan kaynaklanan verimsizliklerinden kaçının",
        ],
        btnText: null,
      },
    },
    {
      title: "Konteyner Desteği",
      media: null,
      pdf: null,
      detail: {
        text: "Kurumsal uygulamalar için gereken her zaman açık esneklik ve koruma ile konteyner performansını optimize edin.",
        list: [
          "Red Hat OpenShift ve yerel Kubernetes desteği",
          "Konteynerlarda çalışan uygulamalar için görev kritik veri hizmetleri sunar",
          "Artık Red Hat Catalog'da da mevcut",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={<>Veritas - Bulut ve şirket içi uygulamalar için kullanılabilirlik ve performans.</>}
        subTitle={
          <>
            InfoScale, fiziksel, sanal ve bulut altyapılarında ortak kullanılabilirlik platformu sunan, başarısı
            kanıtlanmış bir çözümdür.
          </>
        }
        lottie={infoscale}
      ></HeroVeritas>
      <HorizontalImageText
        image={gurl}
        title={<>Uygulamalar ve BT altyapısı arasında yazılım köprüsü.</>}
        text={
          <>
            Kurumsal uygulamalarla doğrudan entegrasyon sayesinde, çok çeşitli kritik iş hizmetlerine yönelik yüksek
            kullanılabilirlik ve olağanüstü durum kurtarma sağlar. InfoScale sayesinde uygulama çalışma süresini
            artırabilir ve performansı güvenle ve kolaylıkla optimize edebilirsiniz.
          </>
        }
      ></HorizontalImageText>
      <TitleAndText
        title={<>BT altyapısı genelinde uygulama kullanılabilirliği için tek çözüm.</>}
        text={
          <>
            Küçük işletmelerden çok uluslu sektör liderlerine kadar InfoScale'i kullanmak, dijital dönüşümün tüm
            aşamalarında her zaman bir adım önde olmak demektir.
          </>
        }
      ></TitleAndText>
      <PinnedInfo detailsHeight={"40vh"} items={veritasSliderItems}></PinnedInfo>
      <TitleAndText
        title={<>Dünya genelinde aşağıdaki sektörlerde ilk 10’da yer alan şirketler Veritas ile çalışıyor.</>}
      ></TitleAndText>
      <FieldsGrid items={gridItems}></FieldsGrid>
    </>
  )
}
