import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";
import FeatureCards from "../../../components/FeatureCards";

import Hero from "../../../components/Hero";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";

import bannerImgV from "../../../assets/img/hero/fileorbis.jpg";
import bannerImgH from "../../../assets/img/hero/fileorbis-h.jpg";
import imgMobile from "../../../assets/img/hero/fileorbis-mobil.png";

export default function FileOrbis() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Kurumsal Dosya Yönetimi" });
  }, []);

  const cards = [
    {
      title: { part1: <>Uzaktan ve hibrit çalışma performansında artış</> },
      text: (
        <>
          FileOrbis, kullanıcıların uzaktan çalışırken dahi tüm içeriğe kolayca
          erişmelerini sağlıyor.
        </>
      ),
    },
    {
      title: { part1: <>Güvenlik ve uyumluluk risklerinde azalma</> },
      text: (
        <>
          Güvenlik ve uyumluluk riskleri işletmelerin karşı karşıya kaldıkları
          ne önemli riskler arasında yer alıyor. FileOrbis, işletmelerin
          dosyalar üzerinde tam kontrol elde etmelerini sağlayarak riskleri
          azaltıyor.
        </>
      ),
    },
    {
      title: {
        part1: (
          <>Dağınık, bağlantısız dosya ortamlarının bakım maliyetinde azalma</>
        ),
      },
      text: (
        <>
          Mobil uygulamalardan dosyalara erişimden, aynı dosyaları SFTP
          üzerinden üçüncü taraflarla paylaşmaya kadar kapsamlı özellikler
          sağlayarak maliyetleri azaltıyor.
        </>
      ),
    },
    {
      title: { part1: <>Yazılım geliştirme maliyetlerinde azalma</> },
      text: (
        <>
          İçerik akışının kontrolünü sağlayan özel bir platform olan FileOrbis,
          kapsamlı API kütüphanesi ve SDK’ler ile iş uygulamaları geliştirmeye
          olanak tanıyor. Bu API’ler ve SDK’ler sayesinde dahili yazılım
          kontrolleri ve süreçlerini iyileştirmenin maliyeti önemli ölçüde
          düşüyor.
        </>
      ),
    },
  ];

  return (
    <main className={s.solutions}>
      <Hero
        imgMobile={imgMobile}
        imgV={bannerImgV}
        imgH={bannerImgH}
        circledImg
        title={
          <>
            FileOrbis <br />
            Dosya Yönetimi
          </>
        }
        desc={
          <>
            FileOrbis, son kullanıcıların artan dosya erişim ve paylaşım
            taleplerini karşılarken, BT departmanlarının harcadıkları zamanı,
            teknolojik tehditleri, mevzuat ve sertifikasyon kaynaklı riskleri en
            aza indirmeyi amaçlar.
          </>
        }
        bottom={{
          title: (
            <>
              FileOrbis, mevcut dosya ortamlarına, kullanıcı dizinlerine, erişim
              yetkilerine ve güvenlik sistemlerine entegre edilerek çalışıyor.
            </>
          ),
          text: {
            part1: (
              <>
                Etki alanı ve DMZ olmak üzere farklı katmanlarda farklı görevler
                için kullanılabiliyor.
              </>
            ),
          },
        }}
      ></Hero>
      <FeatureCards
        greeting={"FileOrbis'in Sağladığı Faydalar"}
        items={cards}
      ></FeatureCards>
    </main>
  );
}
