import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";
import FooterNav from "../../../components/FooterNav";

import Hero from "../../../components/Hero";
import ImageWithText from "../../../components/ImageWithText";
import ProductInfo from "../../../components/ProductInfo";
import { appRoutes } from "../../../utils/routes";
import { alignmentType } from "../../../utils/types";

import shape from "../../../assets/img/shapes/shape-data-loss.svg";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";

import bannerImgV from "../../../assets/img/hero/veri-sizintisi.jpg";
import imgMiddle from "../../../assets/img/veri-sizintisi-middle.jpg";

export default function DataBreach() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Siber Güvenlik Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.cyberSecurity.children);
    navStore.setTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setNavTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.cyberSecurity.children.dataBreach.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        title={
          <>
            Veri Sızıntısı Önleme <br /> ve Sınıflandırma
          </>
        }
        desc={
          <>
            Veriler işletmelerin işlerini yürütebilmeleri ve rekabet
            edebilmeleri açısından son dönemde en önemli unsurlardan biri haline
            geldi.
          </>
        }
        bottom={{
          title: (
            <>
              Bu değerli varlık, kasıtlı bir şekilde ya da yanlışlıkla iş yeri
              dışına çıkarılabiliyor.
            </>
          ),
          text: {
            part1: (
              <>
                Veriler işletme dışına USB, CD, DVD ya da SD kart gibi medyalara
                kopyalanarak, ekran görüntüleri alınarak, gerekli güvenlik
                yapılandırılmaları yapılmamış bulut ortamından, e-postayla,
                anlık mesajlaşma yazılımlarıyla, yazdırma yoluyla ya da Web
                üzerinden çıkarılabiliyor.
              </>
            ),
            part2: null,
          },
        }}
      ></Hero>
      <ImageWithText
        image={imgMiddle}
        alignment={alignmentType.right}
        title={
          <>
            Veriler aynı zamanda cihazların çalınması gibi fiziksel hırsızlık
            gibi nedenlerden dolayı da başkalarının eline geçebiliyor.
          </>
        }
        text={
          <>
            Bu gibi durumlardan kaçınmak adına verilerin işletme dışına
            sızdırılmasını önlemeyi amaçlayan Veri Kaybını Önleme (Data Loss
            Prevention) ve veri sınıflandırma çözümleri sunuyoruz.
          </>
        }
      ></ImageWithText>
      <ProductInfo
        title={<>Symantec Data Loss Prevention</>}
        desc={
          <>
            Symantec’in veri sızıntılarını ve kaybını önlemeye yönelik çözümü,
            verilere üst düzey koruma sağlayarak işletmelerin siber saldırılar,
            dijital dönüşüm ve KVKK gibi gizlilik yasalarıyla ilgili
            endişelerini ortadan kaldırıyor.
          </>
        }
        texts={{
          part1: (
            <>
              Çözüm, işletmelere ait tüm veriler ve bilgiler üzerinde tam
              görünürlük ve kontrol sağlayan kapsamlı keşif, izleme ve koruma
              özellikleri sağlıyor.
            </>
          ),
          part2: (
            <>
              İşletmelerin gizli kalması gereken verilerini kazayla ya da kasten
              sızmasını önlüyor. Depolama alanları, e-posta, bulut, anlık
              mesajlaşma gibi veri hareketinin bulunduğu yerlerde görünürlük ve
              kontrol sağlıyor. KVKK ve GDPR gibi veri gizliliğine yönelik
              kanunlarla uyumluluğu artırıyor.
            </>
          ),
        }}
        icon={shape}
      ></ProductInfo>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer siber güvenlik çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.cyberSecurity.children}
          hideIndex={
            appRoutes.solutions.children.cyberSecurity.children.dataBreach.type
          }
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
