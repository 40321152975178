import React from "react";
import s from "../assets/scss/breadcrumb.module.scss";
import useWindowSize from "../hooks/useWindowSize";
import useBreadcrumbStore from "../stores/breadcrumbStore";
import { breakpointTypes } from "../utils/types";

export default function BreadCrumb({
  color = "#0466c8",
  bordered = true,
  visible = true,
}) {
  const { breadcrumb } = useBreadcrumbStore();

  const windowSize = useWindowSize();

  return (
    <>
      {visible && (
        <div
          className={s.breadcrumb}
          style={{
            border:
              bordered && windowSize.width > breakpointTypes.mobile + 1
                ? `1px solid ${color}`
                : "none",
          }}
        >
          <p className={s.first} style={{ color: color }}>
            {breadcrumb.main}
          </p>
          {breadcrumb.sub && (
            <>
              <span className={s.seperator} style={{ color: color }}>
                |
              </span>
              <p className={s.second} style={{ color: color }}>
                {breadcrumb.sub}
              </p>
            </>
          )}
        </div>
      )}
    </>
  );
}
