import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";
import FooterNav from "../../../components/FooterNav";
import Hero from "../../../components/Hero";
import ProductInfo from "../../../components/ProductInfo";
import { appRoutes } from "../../../utils/routes";
import { productInfoType } from "../../../utils/types";

import shape from "../../../assets/img/shapes/shape-symantec-vip.svg";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";

import bannerImgV from "../../../assets/img/hero/iki-asamali-kimlik-dogrulama.jpg";
import imgMobile from "../../../assets/img/hero/iki-asamali-kimlik-dogrulama-mobil.png";

import bannerVideo from "../../../assets/video/hero/iki-asamali-kimlik-dogrulama.mp4";

export default function TwoStepVerification() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Siber Güvenlik Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.cyberSecurity.children);
    navStore.setTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setNavTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.cyberSecurity.children.twoStepVerification
        .type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgMobile={imgMobile}
        imgV={bannerImgV}
        video={bannerVideo}
        title={
          <>
            İki Aşamalı Kimlik <br /> Doğrulama
          </>
        }
        desc={
          <>
            İki aşamalı kimlik doğrulama, kullanıcıların belirttikleri kişi olup
            olmadıklarından emin olmaya yarayan ekstra bir güvenlik katmanıdır.
          </>
        }
        bottom={{
          title: (
            <>
              Kullanıcıların eriştikleri kaynakların güvenliğinin sağlanmasında
              büyük önem taşıyan bir unsurdur.
            </>
          ),
          text: {
            part1: (
              <>
                Herhangi bir kullanıcının parolası siber saldırganlar tarafından
                erişilse dahi kaynaklara erişilmemesini sağlar. Hassas
                sistemlere ve verilere erişimi kontrol almak için kullanılan iki
                aşamalı kimlik doğruma giderek daha da önem kazanıyor.
              </>
            ),
            part2: null,
          },
        }}
      ></Hero>
      <ProductInfo
        type={productInfoType.dualDesc}
        title={<>Symantec VIP</>}
        desc={
          <>
            Veritas’ın güçlü raporlama platformu Data Insight, uyumlulukla
            ilgili riskleri bulmayı kolaylaştırıyor ve ayrıntılı risk analiziyle
            sürekli uyumluluk sağlıyor.
          </>
        }
        texts={null}
        dualDesc={{
          part1: {
            title: (
              <>
                Son yıllarda tüketici davranışları ve beklentileri büyük ölçüde
                değişti.
              </>
            ),
            text: (
              <>
                Artık kullanıcılar verilere ve hizmetlere her yerde her zaman
                erişmek istiyor ve bunu uzun güvenlik süreçlerinden geçmek
                yerine hızla yapabilmek istiyor. Bu durumu kabullenen işletmeler
                yetkisiz erişime karşı koruma sağlamak için doğru güvenlik
                önlemleri uyguluyor ve hem çalışanlarına hem de müşterilerine
                telefonlarından kimliklerini doğrulama kolaylığı sunuyor.
              </>
            ),
          },
          part2: {
            title: (
              <>
                Symantec’in VIP çözümü, işletmelerin çalışanlarını ve
                müşterilerini çok faktörlü kimlik doğrulama ile koruyor.
              </>
            ),
            text: (
              <>
                Çözüm, siber saldırganların kullanıcı ve müşteri hesaplarını ele
                geçirme girişimlerini önleyerek kaynaklara güvenli bir şekilde
                erişim sunuyor. Kullanıcılar güçlü çok faktörlü kimlik
                doğrulaması sayesinde daha iyi bir oturum açma deneyimi yaşıyor.
                Ayrıca Sıfır Güven yaklaşımıyla daha etkin bir güvenlik sunuyor.
              </>
            ),
          },
        }}
        icon={shape}
      ></ProductInfo>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer siber güvenlik çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.cyberSecurity.children}
          hideIndex={
            appRoutes.solutions.children.cyberSecurity.children
              .twoStepVerification.type
          }
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
