import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";
import FooterNav from "../../../components/FooterNav";
import Hero from "../../../components/Hero";
import RevealWrapper from "../../../components/RevealWrapper";
import SolutionsList from "../../../components/SolutionsList";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";
import { appRoutes } from "../../../utils/routes";

import bannerImgV from "../../../assets/img/hero/sifreleme-cozumleri.jpg";
import imgMobile from "../../../assets/img/hero/sifreleme-cozumleri-mobil.png";
import bannerVideo from "../../../assets/video/hero/sifreleme-cozumleri.mp4";

export default function Encryption() {
  const solutions = [
    {
      title: "Symantec Endpoint Encryption",
      desc: "Günümüzde çalışanların daha verimli ve üretken olabilmeleri için veriye her yerden her zaman ulaşabilmeleri büyük önem taşıyor. Ancak verilere yalnızca yetkisi olan kişilerin erişmesi gerekiyor. Symantec’in Endpoint Encryption çözümü, maksimum güvenlik için sürücü üzerindeki tüm dosyaları şifreliyor. Çözüm USB belleklerden sabit disklere ve DVD’lere kadar birçok medyayı destekliyor ve Windows, Mac ve diğer işletim sistemlerinde çalışıyor. Maksimum uyumluluk için aynı zamanda bitLocker ve File Vault ile korunun cihazları da yönetebiliyor. Çözüm tek bir konsol üzerinden yönetiliyor ve cihazlarla çıkarılabilir diskler ve belleklerle ilgili riskleri en aza indiriyor.",
    },
    {
      title: "Mobil Cihaz Yönetimi Çözümleri",
      desc: "Akıllı telefon, tablet ve dizüstü bilgisayar gibi cihazlar günümüzde işletmelerin en önemli üretkenlik araçları arasında yer alıyor ve bu cihazların yönetilmesi ve güvenliğinin sağlanması büyük önem taşıyor. Bu cihazların marka ve modeli, işletim sistemleri, seri numaraları, IMEI bilgileri, IP adresleri ve diğer bilgilerin tutulması ve çeşitli politikaların ve kuralların uygulanması gerekiyor.",
    },
    {
      title: "VMware Workspace ONE",
      desc: "VMware Workspace One çözümü, herhangi bir uygulamayı, herhangi bir cihazda ve herhangi bir yerde güvenli bir şekilde teslim etmenize ve yönetmenize olanak tanıyan gelişmiş bir dijital çalışma alanı platformu sunuyor. Çözüm, erişim denetimi, uygulama yönetimi ve çok platformlu uç nokta yönetimini tek bir platform üzerinde buluşturuyor. Bulut üzerinden ya da şirket içi altyapı üzerinde kullanılabiliyor.",
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Siber Güvenlik Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.cyberSecurity.children);
    navStore.setTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setNavTitle("Diğer siber güvenlik çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.cyberSecurity.children.encryption.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgMobile={imgMobile}
        imgV={bannerImgV}
        video={bannerVideo}
        title={
          <>
            Şifreleme <br /> Çözümleri
          </>
        }
        desc={
          <>
            Veri ihlalleri, işletmeler açısından hem operasyonel hem de finansal
            açıdan çok yıkıcı olabiliyor.
          </>
        }
        bottom={{
          title: (
            <>
              Veri ihlalleri, işletmeler açısından hem operasyonel hem de
              finansal açıdan çok yıkıcı olabiliyor.
            </>
          ),
          text: {
            part1: (
              <>
                Dizüstü bilgisayarlar, masaüstü bilgisayarlar, mobil cihazlar,
                USB bellekler, epostalar ve bulut ortamı gibi konumlardaki
                verilerin şifrelenmesi büyük önem taşıyor. Bu sayede verilere
                yalnızca yetkisi olan kullanıcılar erişebiliyor.
              </>
            ),
            part2: null,
          },
        }}
      ></Hero>
      <SolutionsList
        preText={
          <>
            Siber saldırılara karşı işletmelerin güvende kalabilmelerini
            sağlayan uçtan uca çözümler sunuyoruz.
          </>
        }
        items={solutions}
      ></SolutionsList>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer siber güvenlik çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.cyberSecurity.children}
          hideIndex={
            appRoutes.solutions.children.cyberSecurity.children.encryption.type
          }
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
