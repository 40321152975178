import React from "react";
import s from "../assets/scss/revealWrapper.module.scss";

export default function RevealWrapper({ children }) {
  return (
    <div className={s.revealWrapper} data-reveal>
      <div className={s.overlay} data-overlay></div>
      {children}
    </div>
  );
}
