import React from "react";
import { Link } from "react-router-dom";
import s from "../assets/scss/notFound.module.scss";

export default function NotFound() {
  return (
    <div className={s.notFound}>
      <h1 className={s.text}>Sayfa Bulunamadı</h1>
      <Link className={s.link} to="/">
        Anasayfa
      </Link>
    </div>
  );
}
