import React from "react";
import s from "../assets/scss/textL.module.scss";

const TextL = ({ items = [] }) => {
  return (
    <section className={s.textL}>
      {items.map((item, i) => {
        return (
          <h4 key={i} className={s.text}>
            {item}
          </h4>
        );
      })}
    </section>
  );
};

export default TextL;
