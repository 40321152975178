import React, { useState } from "react";
import s from "../assets/scss/featureCards.module.scss";

import plus from "../assets/icons/plus.svg";
import minus from "../assets/icons/minus.svg";
import arrowSquare from "../assets/icons/arrow-square.svg";
import bg from "../assets/img/feature-card-bg.png";

import cx from "classnames";
import FeatureCardsMobile from "./FeatureCardsMobile";
import useWindowSize from "../hooks/useWindowSize";
import { breakpointTypes } from "../utils/types";

const FeatureCard = ({
  item = { title: { part1: null, part2: null }, text: "" },
  index,
}) => {
  const windowSize = useWindowSize();

  const [isOpen, setisOpen] = useState(false);

  const handleSelect = () => {
    if (windowSize.width <= breakpointTypes.tablet) {
      return;
    }

    setisOpen((prev) => !prev);
  };

  return (
    <div
      className={cx(s.featureCard, {
        [s.open]: isOpen,
      })}
      onClick={handleSelect}
    >
      <img className={s.bg} src={bg} alt="Background" />
      <small className={s.index}>0{index + 1}</small>
      <div className={s.textWrapper}>
        <h3 className={s.title}>
          {item.title.part1 && item.title.part1} <br />
          {item.title.part2 && item.title.part2}
        </h3>
        <p className={s.text}>{item.text}</p>
      </div>
      <div className={s.iconsWrapper}>
        <div className={s.iconWrapper}>
          <img
            src={minus}
            className={cx(s.icon, s.minus)}
            alt="Toggle Icon"
          ></img>
        </div>
        <div className={s.iconWrapper}>
          <img
            src={plus}
            className={cx(s.icon, s.plus)}
            alt="Toggle Icon"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default function FeatureCards({
  greeting = "",
  items = [{ title: { part1: null, part2: null }, text: "" }],
}) {
  const windowSize = useWindowSize();

  return (
    <section className={s.featureCards}>
      <div className={s.greeting}>
        <div className={s.iconWrapper}>
          <img src={arrowSquare} className={s.icon} alt="Toggle Icon"></img>
        </div>
        <h2 className={s.greetingText}>{greeting}</h2>
      </div>
      {windowSize.width > breakpointTypes.mobile ? (
        <div className={s.cardsWrapper}>
          {items.map((item, i) => {
            return <FeatureCard key={i} item={item} index={i}></FeatureCard>;
          })}
        </div>
      ) : (
        <FeatureCardsMobile items={items}></FeatureCardsMobile>
      )}
    </section>
  );
}
