import React from "react";

const IconArrow = ({
  fill = "#000000",
  width = "100%",
  height = "100%",
  rotate = "0",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12.717 12.717"
    >
      <path
        id="Path_6643"
        data-name="Path 6643"
        d="M.914,8.993H8.808a.17.17,0,0,0,.183-.153V.945A.2.2,0,0,0,8.808.763H7.071v5L1.311,0,0,1.342,5.761,7.1h-5V8.841a.144.144,0,0,0,.152.153Z"
        transform="translate(0 6.358) rotate(-45)"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default IconArrow;
