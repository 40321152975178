import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import Hero from "../../../components/Hero";
import ImageWithText from "../../../components/ImageWithText";
import SolutionsList from "../../../components/SolutionsList";
import FooterNav from "../../../components/FooterNav";

import { alignmentType } from "../../../utils/types";
import { appRoutes } from "../../../utils/routes";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";

import bannerImgV from "../../../assets/img/hero/siber-guvenlik-cozumleri.jpg";
import middleImg from "../../../assets/img/siber-alt.jpg";
import bannerVideo from "../../../assets/video/hero/siber-guvenlik-cozumleri.mp4";
import parallaxIcon from "../../../assets/img/shapes/cyber-sphere.svg";

export default function CyberSecurityHome() {
  const solutions = [
    {
      title: "Veri Güvenliği Çözümleri",
      desc: "Veriler, günümüzde işletmelerin en önemli varlıkları arasında yer alıyor. Bu nedenle, verilerin gizliliği, bütünlüğü, kullanılabilirliği ve bu verileri her türlü yetkisiz erişime karşı korumak büyük önem taşıyor. Türkiye’de KVKK ve Avrupa Birliği’nde GDPR gibi kanunlar veri güvenliğini daha da kritik bir hale getiriyor. İşletmelere tüm yaşam döngüsü boyunca verileri güvende tutmalarını sağlayan çözümler sunuyoruz.",
    },
    {
      title: "Ağ Güvenliği Çözümleri",
      desc: "İşletme genelinde internet üzerinden veri alışverişi yapan tüm donanımların ve yazılımların güvenliğinin sağlanması güvenlik açısından kritik önem taşıyor. Sunduğumuz çözümlerle ağları ve ağ üzerinden erişilebilen tüm kaynaklara sahte kimlikler, kötüye kullanım, yetkisiz erişim, değiştirilme ve DDoS gibi tehditlere koruma sağlıyoruz. İşletmelerin hassas verilerini ve kaynaklarını korumalarına olanak tanıyarak mevcut güvenlik düzeylerini artırıyoruz.",
    },
    {
      title: "İnternet Güvenliği Çözümleri",
      desc: "Siber güvenlikle ilgili tehditler daha etkili ve yaygın hale geldikçe uygulamalarda bulunan güvenlik açıklarının ele alınması önem kazanıyor. İşletmeler açısından önem taşıyan uygulamaların güvenliği geliştirme ortamlarıyla entegre şekilde çalışan, güvenli uygulama ve kod geliştirmeyi destekleyen çözümler sunuyoruz. Aynı zamanda kod yazan geliştiren ekiplerin eğitimine yönelik danışmanlık hizmetleri veriyoruz.",
    },
    {
      title: "Uç Nokta Güvenliği",
      desc: "Uç nokta güvenliğine yönelik virüsler, casus yazılımlar ve diğer tehditlere karşı üstün koruma sağlayan alanında dünyanın önde gelen şirketlerinin çözümlerini uzun yıllara dayanan deneyimimizle bir araya getirerek sunuyoruz. Ayrıca işletmelerde kullanıcı farkındalığını artıran, kullanıcılardan kaynaklanan zafiyetlerini giderilmesine yönelik danışmanlık hizmetleri veriyoruz.",
    },
  ];

  const navStore = useStickyNavStore();
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Siber Güvenlik Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.cyberSecurity.children);
    navStore.setTitle("Siber güvenlik çözümlerini inceleyin");
    navStore.setNavTitle("Siber güvenlik çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.cyberSecurity.children.index.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        video={bannerVideo}
        title={
          <>
            Siber Güvenlik <br /> Çözümleri
          </>
        }
        desc={
          <>
            Son dönemde her geçen gün daha da artan ve daha da sofistike bir
            hale gelen siber saldırılar dijital dönüşümün en önemli parçası olan
            siber güvenliği daha da önemli bir hale getiriyor.
          </>
        }
        bottom={{
          title: (
            <>
              Dijital dönüşümün en önemli parçalarından biri olan{" "}
              <span>siber güvenlik,</span> günümüzde işletmeler açısından çok
              büyük önem taşıyor.
            </>
          ),
          text: {
            part1: (
              <>
                Siber saldırılar işletmelerin
                <strong> yalnızca para kaybetmesine</strong> ya da belirli bir
                süre hizmet verememesine değil aynı zamanda
                <strong> itibarlarını kaybetmesine neden oluyor.</strong>
              </>
            ),
            part2: (
              <>
                Ayrıca yaşanabilecek herhangi bir veri sızıntısı ya da güvenlik
                ihlali işletmelerin{" "}
                <strong>
                  {" "}
                  son derece yüksek cezalarla karşılaşmalarıyla
                  sonuçlanabiliyor.
                </strong>
              </>
            ),
          },
        }}
      ></Hero>
      <ImageWithText
        alignment={alignmentType.left}
        singleText={
          <>
            Siber güvenlik alanında dünya genelinde yaşanan uzman personel
            eksikliği, birden fazla güvenlik ürününü bir arada çalıştırmanın
            getirdiği karmaşıklık, siber güvenlik alanındaki hızlı değişim,
            uzaktan ve hibrit çalışmanın getirdiği riskler, mali açıdan
            yetersizlik gibi sorunlar{" "}
            <strong>
              başta KOBİ’ler olmak üzere işletmeler açısından büyük zorluklara
              neden oluyor.
            </strong>
          </>
        }
        image={middleImg}
        icon={parallaxIcon}
      ></ImageWithText>
      <SolutionsList
        preText={
          <>
            Siber saldırılara karşı işletmelerin güvende kalabilmelerini
            sağlayan uçtan uca çözümler sunuyoruz.
          </>
        }
        items={solutions}
      ></SolutionsList>
      <RevealWrapper>
        <FooterNav
          title={<>Siber güvenlik çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.cyberSecurity.children}
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
