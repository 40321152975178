import React, { useEffect } from "react"

import GridIcons from "../../../components/GridIcons"
import HeroVeritas from "../../../components/HeroVeritas"
import HorizontalImageText from "../../../components/HorizontalImageText"
import PinnedInfo from "../../../components/PinnedInfo"
import TitleAndText from "../../../components/TitleAndText"

import enterpriseVaultCloud from "../../../assets/lottie/veritas/enterpriseVaultCloud.json"

import gurl from "../../../assets/img/veritas/data-insights.jpg"
import useBreadcrumbStore from "../../../stores/breadcrumbStore"

import threat from "../../../assets/icons/veritas/threat.svg"
import priority from "../../../assets/icons/veritas/priority.svg"
import optimize from "../../../assets/icons/veritas/optimize.svg"

import reportPdf from "../../../assets/pdf/DS_data_insight_V0130.pdf"

export default function DataInsights() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Insights", sub: "Data Insights" })
  }, [setBc])

  const gridItems = [
    {
      title: { icon: threat, text: "Riskleri Görselleştirin" },
      items: "Yapılandırılmamış dosyaları tarayın, değerlendirin ve veri yükümlülüklerini ortaya çıkarın.",
    },
    {
      title: { icon: priority, text: "Hassas Dosyaları Önceliklendirin" },
      items: "Yüksek riskli içerikleri hızla bulun, sınıflandırın ve her bir dosyayı kimin kullandığını belirleyin.",
    },
    {
      title: { icon: optimize, text: "Uyumluluğu Optimize Edin" },
      items: "Tahmine dayalı analitik ile içerik dosyalarını proaktif olarak yönetin ve kontrol altına alın.",
    },
  ]

  const veritasSliderItems = [
    {
      title: "Otomatik Veri Yönetişimi",
      media: null,
      pdf: null,
      detail: {
        text: "İsrafı ortadan kaldırmak, maliyetleri azaltmak ve veri sınıflandırma politikalarını tutarlı bir şekilde uygulamak için kullanımı kolay raporlamadan yararlanın.",
        list: [
          "Veritas Enterprise Vault'un arşivleme özelliğini regülasyonlarla uyumlu olacak şekilde optimize edin",
          "Saklamanız gereken ve silebileceğiniz verileri tanımlayın",
          "Yinelenen, eski, artık ve karanlık verileri en aza indirin",
        ],
        btnText: null,
      },
    },
    {
      title: "İç Tehditleri Önleyin",
      media: null,
      pdf: null,
      detail: {
        text: "Kullanıcı davranışlarını analiz edin ve kuruluşunuzdaki veri hırsızlığına karşı koruma sağlayın",
        list: [
          "Meta verilere ve etkinliğe dayalı olarak olası erişim sorunlarını veya şirket içi kötü niyetli davranışları belirleyin",
          "Kullanıcıların sosyal etkileşimlerine ve rollerine göre profilini çıkarın, şüpheli ilişkileri belirleyin",
          "Potansiyel tehditleri değerlendirmek ve yüksek riskli verilere öncelik vermek için Kullanıcı Risk Puanlarından yararlanın",
          "Önceden oluşturulmuş bir düzeltme iş akışıyla kullanıcı davranışı değişikliklerini kolaylaştırın",
        ],
        btnText: null,
      },
    },
    {
      title: "Yüksek riskleri azaltın",
      media: null,
      pdf: null,
      detail: {
        text: "Anormallikleri otomatik olarak işaretleyin ve şüpheli veya kötü niyetli kullanıcı davranışlarını belirleyin.",
        list: [
          "Çalışan profillerini, davranışlarını ve içerik hassasiyetini harmanlamak için özel algoritmalar kullanın",
          "Entegre dosya analizi, veri kaybı önleme (DLP) ve arşivleme çözümlerinden faydalanın",
          "İhmalkar çalışanlardan veya yöneticilerin paylaşımlarından kaynaklanan kötü amaçlı olmayan erişim risklerini vurgulayın",
          "Eğilim çizgilerini ve aykırı değerleri belirlemek için milyarlarca eyleme yönelik kullanıcı etkinliklerini toplayın",
        ],
        btnText: null,
      },
    },
    {
      title: "Hızlı tehdit tespiti ve müdahalesi",
      media: null,
      pdf: null,
      detail: {
        text: "Küresel erişim izinlerini kontrol edin ve dosyalarda gizlenen olası fidye yazılımlarını belirleyin",
        list: [
          "Okuma ve yazma sayı değişkenleri aracılığıyla fidye yazılımlarını tespit etmek için algılama şablonlarından yararlanın",
          "Verilere kimin eriştiğine ve ne zaman değişiklik yaptığına dair ayrıntılı bir denetim izi oluşturun",
          "Politika ve uyumluluk çalışmaları için bağlantı kurmanız gereken veri sahiplerini ve koruyucuları belirleyin",
          "Yetkilendirme incelemesi için veri sahipliği kimliklerini ve erişim izinlerinin görünürlüğünü kullanın",
        ],
        btnText: null,
      },
    },
    {
      title: "Akıllı Entegrasyonlar",
      media: null,
      pdf: null,
      detail: {
        text: "Akıllı Entegrasyonlar",
        list: [
          "Veritas Usage Insights aracılığıyla tüm veri ekosisteminin konsolide raporları",
          "NetBackup SaaS Koruması ile Microsoft 365, Google Cloud ve Salesforce dahil olmak üzere SaaS iş yükleriniin ve uygulamalarının korunması",
          "Anında bulut tabanlı olağanüstü durum kurtarma için Azure Site Recovery ile entegrasyon",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={<>Veritas - Ayrıntılı risk analitiği ile 7/24 veri uyumluluğu.</>}
        lottie={enterpriseVaultCloud}
      ></HeroVeritas>
      <HorizontalImageText
        imgH
        image={gurl}
        title={<>Yapılandırılmamış verileri sınıflandırın ve kontrol altına alın. </>}
        text={
          <>
            Tüm kurumsal verilerin yarısından fazlası “karanlık” veya bilinmiyor. Bu, regülasyonlar açısından riskler
            yaratıyor. Data Insight, uyumluluk ekiplerine içeriği sınıflandırma ve bağlamsallaştırma yeteneği vererek
            veriler genelinde arama yapmayı, içgörüler elde etmeyi ve yasal gereksinimleri karşılamayı kolaylaştırıyor.
          </>
        }
      ></HorizontalImageText>
      <TitleAndText
        title={<>Güçlü dosya analiziyle içgörülerinizi en üst düzeye çıkarın.</>}
        text={<>Güçlü raporlama platformu, uyumluluk riskleri bulmayı basitleştiriyor ve eDiscovery’yi geliştiriyor.</>}
      ></TitleAndText>
      <GridIcons vertical={true} items={gridItems}></GridIcons>
      <PinnedInfo items={veritasSliderItems}></PinnedInfo>
      <TitleAndText
        smallText={<>Gartner Peer Insights ‘Voice of the Customer’ (Müşterinin Sesi)</>}
        title={
          <>Veritas, Dosya Analizi Yazılımı kategorisinde Müşterinin Seçimi (Customers’ Choice) olarak belirledi.</>
        }
        btnText={<>Raporu Okuyun</>}
        bgColor={"#002855"}
        fontColor={"#ffffff"}
        url={reportPdf}
      ></TitleAndText>
    </>
  )
}
