import React from "react";
import s from "../assets/scss/gridIcons.module.scss";

import cx from "classnames";

export default function GridIcons({
  vertical = false,
  verticalTitle = false,
  items = [
    {
      title: { icon: "", text: "" },
      items: "",
    },
  ],
}) {
  return (
    <div className={cx(s.gridIcons, { [s.vertical]: vertical })}>
      {items.length !== 0 &&
        items.map((item, i) => {
          return (
            <div
              className={cx(s.itemWrapper, {
                [s.verticalTitle]: verticalTitle,
              })}
              key={i}
            >
              <div className={s.title}>
                <div className={s.iconWrapper}>
                  <img className={s.icon} src={item.title.icon} alt="Icon" />
                </div>
                <h5 className={s.text}>{item.title.text}</h5>
              </div>
              <p className={s.itemText}>{item.items}</p>
            </div>
          );
        })}
    </div>
  );
}
