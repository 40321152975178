import React, { useEffect } from "react"

import GridIcons from "../../../components/GridIcons"
import HeroVeritas from "../../../components/HeroVeritas"
import HorizontalImageText from "../../../components/HorizontalImageText"
import PinnedInfo from "../../../components/PinnedInfo"
import TitleAndText from "../../../components/TitleAndText"

import enterpriseVaultCloud from "../../../assets/lottie/veritas/enterpriseVaultCloud.json"

import gurl from "../../../assets/img/veritas/enterprise-vault-banner.jpg"
import useBreadcrumbStore from "../../../stores/breadcrumbStore"

import server from "../../../assets/icons/veritas/server.svg"
import archive from "../../../assets/icons/veritas/archive.svg"
import find from "../../../assets/icons/veritas/find.svg"
import publicIcon from "../../../assets/icons/veritas/public.svg"

import finance from "../../../assets/icons/veritas/finance.svg"
import medicine from "../../../assets/icons/veritas/medicine.svg"
import energy from "../../../assets/icons/veritas/energy.svg"

export default function EnterpriseVaultCloud() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Compliance", sub: "Enterprise Vault.Cloud" })
  }, [setBc])

  const gridItems = [
    {
      title: { icon: server, text: "Her Şeyi Toplayın" },
      items: "Sosyal medya, ekip işbirliği, e-posta, anlık mesajlaşma, sohbet, sesli iletişim ve daha fazlası.",
    },
    {
      title: { icon: archive, text: "Her Yerde Arşivleyin" },
      items:
        "Geniş dağıtım seçeneği yelpazesi: Şirket içi, karma, kendi kiracınızda dağıtma ve yerel çok kiracılı SaaS.",
    },
    {
      title: { icon: find, text: "Önemli Olan Şeyleri Bulun" },
      items: "Denetim, gizlilik ve yasal zorunluluklar için ilgili verileri hızla bulun.",
    },
  ]

  const gridItems2 = [
    {
      title: { icon: finance, text: "Finans" },
      items: "SEC, FINRA, CFTC, SOX, APRA, MiFID II, IIROC, ESMA",
    },
    {
      title: { icon: medicine, text: "İlaç ve Sağlık" },
      items: "FDA, HIPPA, FDCA, ARRA",
    },
    {
      title: { icon: publicIcon, text: "Kamu ve Özel" },
      items: "FOIA, FISMA, CCPA, GDPR",
    },
    {
      title: { icon: energy, text: "Enerji" },
      items: "FERC, DOE, NERC, CFTC, Eyalet Regülasyonları",
    },
  ]

  const veritasSliderItems = [
    {
      title: "Bulut Tabanlı SaaS Uyumluluğu",
      media: null,
      pdf: null,
      detail: {
        text: "Veri yönetimi gereksinimlerini verimli bir şekilde karşılamak için tüm depolama ortamlarında yüksek riskli verileri arşivleyin ve yönetin.",
        list: [
          "Bilgileri, güvenli ve özel çok kiracılı veri merkezlerimizde tek, aranabilir bir çevrimiçi depolama alanında saklayın",
          "Tutarlı bir aylık abonelik modeliyle maliyetleri azaltın",
          "Yapılandırılmamış verilerinizi koruyun ve sınıflandırma ve indeksleme yoluyla bulunabilir hale getirin",
        ],
        btnText: null,
      },
    },
    {
      title: "Tüm Görüşmeleri Yakalayın",
      media: null,
      pdf: null,
      detail: {
        text: "Günümüzün en iyi iş uygulamalarından içerik alan bir platformla tam uyumluluk sağlayın.",
        list: [
          "Eşsiz görünürlük ve kontrol sağlayan Veritas Merge1 ile tam entegrasyon",
          "İşbirliği içeriği, sohbet, e-posta, sosyal medya, sesli iletişim ve daha fazlası",
          "Düzenli olarak eklenen yeni içerik kaynakları ve işlevler",
        ],
        btnText: null,
      },
    },
    {
      title: "İleri Düzey eDiscovery",
      media: null,
      pdf: null,
      detail: {
        text: "Tüm içerik kaynaklarının toplanması, hızlı arama ve büyük miktarda verinin amaca yönelik olarak oluşturulmuş incelemesi ile uyumluluk araştırmalarını optimize edin.",
        list: [
          "İlgili verileri, hassas verileri, COVID-19 verilerini ve fidye yazılımına eğilimli verileri barındıran tüm içerik kaynaklarını yakalayın",
          "Hızlı araştırmalar ve erken vaka değerlendirmesi için içeriği sınıflandırın",
          "Tutarlı, savunulabilir bulma ve silme uygulamaları sağlayan yasal bekletme politikaları uygulayın",
          "Amaca yönelik inceleme ve gelişmiş vaka yönetimi iş akışlarıyla üretkenliği artırın",
        ],
        btnText: null,
      },
    },
    {
      title: "İleri Düzey Denetim",
      media: null,
      pdf: null,
      detail: {
        text: "Tüm uyumluluk iletişimlerinin izlenmesiyle ilişkili maliyeti ve karmaşıklığı azaltın.",
        list: [
          "Sınıflandırma, denetim için içeriği tanımlar ve önceliklendirir",
          "Makine öğrenimi, akıllı inceleme sayesinde incelemeyi optimize eder",
          "İşbirliği içeriği için yerel incelemeye sahip modern, zengin özelliklere sahip kullanıcı arayüzü, incelemeyi daha da verimli hale getirir",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={<>Veritas - Kolaylaştırılmış uyumluluk için çevik arşivleme ve keşif.</>}
        lottie={enterpriseVaultCloud}
      ></HeroVeritas>
      <HorizontalImageText
        image={gurl}
        title={<>Veri yönetimini tüm platformlarda geliştirin.</>}
        text={
          <>
            Enterprise Vault.cloud, üst düzeyde regülasyonlara tabi kuruluşların sorunsuz geçiş ve önemli içeriğin
            otomatik olarak tanımlanması için tüm iletişim platformlarında verileri arşivlemesine yardımcı olur.
            Uyumluluk görevlileri ve kurumsal yasal BT için Enterprise Vault.cloud, denetim ve bulma gereksinimlerini
            karşılamaya yönelik kanıtlanmış bir çözüm olarak öne çıkıyor.
          </>
        }
      ></HorizontalImageText>
      <TitleAndText title={<>Uyumluluğu buluta özel çeviklikle optimize edin.</>}></TitleAndText>
      <GridIcons vertical={true} items={gridItems}></GridIcons>
      <PinnedInfo items={veritasSliderItems}></PinnedInfo>
      <TitleAndText
        smallText={<>Radicati Group Market Quadrant Report 2022</>}
        title={<>Veritas Technologies, Bilgi Arşivlemesi kategorisinde “Top Player” olarak belirlendi.</>}
        btnText={<>Raporu Okuyun</>}
        bgColor={"#002855"}
        fontColor={"#ffffff"}
        url={"https://www.veritas.com/form/whitepaper/radicati-market-quadrant-for-information-archiving"}
      ></TitleAndText>
      <TitleAndText
        title={<>Önemli düzenleyici kurumlarla uyumluluk sağlayın.</>}
        text={
          <>
            Kurumsal yönetişim için iletişimleri koruyun, yasal BT için e-keşif araştırmalarını destekleyin ve diğer tüm
            sektör regülasyonlarını karşılayın.
          </>
        }
      ></TitleAndText>
      <GridIcons items={gridItems2}></GridIcons>
    </>
  )
}
