import React, { useEffect } from "react"

import GridIcons from "../../../components/GridIcons"
import HeroVeritas from "../../../components/HeroVeritas"
import HorizontalImageText from "../../../components/HorizontalImageText"
import PinnedInfo from "../../../components/PinnedInfo"
import TitleAndText from "../../../components/TitleAndText"

import enterpriseVaultCloud from "../../../assets/lottie/veritas/enterpriseVaultCloud.json"

import gurl from "../../../assets/img/veritas/netbackup-appliance-banner.jpg"
import useBreadcrumbStore from "../../../stores/breadcrumbStore"

import flex from "../../../assets/icons/veritas/flex.svg"
import flexScale from "../../../assets/icons/veritas/flex-scale.svg"
import accessAppliance from "../../../assets/icons/veritas/access-appliance.svg"

export default function NetBackupAppliance() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Data Protection", sub: "NetBackup Appliance" })
  }, [setBc])

  const gridItems = [
    {
      title: { icon: flex, text: "NetBackup Flex" },
      items: "Çok kiracılı yetenekleriyle NetBackup veri korumasını genişletir.",
    },
    {
      title: { icon: flexScale, text: "NetBackup Flex Scale" },
      items: "Ölçeklenebilir bir mimariyle otomatikleştirilmiş veri koruma hizmetleri.",
    },
    {
      title: { icon: accessAppliance, text: "NetBackup Access Appliance" },
      items: "Kanıtlanmış fidye yazılımlarına karşı direnci ile uzun vadeli veri saklama.",
    },
  ]

  const veritasSliderItems = [
    {
      title: "Hızlı",
      media: null,
      pdf: null,
      detail: {
        text: "Fidye yazılımlarına karşı dirençli NetBackup veri korumasını dakikalar içinde devreye alın.",
        list: [
          "Kutudan çıkarılır çıkarılmaz NetBackup yazılımının ve donanımın optimize edilmiş entegrasyonundan faydalanın",
          "Mevcut NetBackup dağıtımlarını kolayca ölçeklendirin",
          'Appliance Services ile "koruma süresini" en aza indirin',
        ],
        btnText: null,
      },
    },
    {
      title: "Dayanıklı",
      media: null,
      pdf: null,
      detail: {
        text: "Çok katmanlı güvenliğin avantajlarından faydalanın.",
        list: [
          "Fidye yazılımlarına karşı direnç için değişmez depolama kullanın",
          "Konteyner izolasyonu ve uyumluluk denetiminden faydalanın",
          "Entegre izinsiz giriş algılama ve önlemeden faydalanın",
        ],
        btnText: null,
      },
    },
    {
      title: "Uygun maliyetli",
      media: null,
      pdf: null,
      detail: {
        text: "Uç noktalardan veri merkezlerine ve bulut ortamlarına kadar tüm iş modellerini destekleyin.",
        list: [
          "Entegre cihazlarla BT merkezindeki siloları ortadan kaldırın",
          "İşletmeniz büyüdükçe kolayca ölçeklendirin",
          "NetInsights Konsolu ile yaşam döngüsü maliyetlerini azaltın",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={
          <>Veritas - Fidye yazılımlarına karşı dirençli NetBackup veri korumasını kolayca devreye alın ve yönetin.</>
        }
        lottie={enterpriseVaultCloud}
      ></HeroVeritas>
      <HorizontalImageText
        image={gurl}
        title={<>NetBackup dağıtımlarını yalnızca dakikalar içinde oluşturun ve ölçeklendirin.</>}
        text={
          <>
            NetBackup Appliance Çözümü, fidye yazılımlarına karşı dirençli olduğu kanıtlanmış kurumsal sınıf veri
            korumasının hızlı dağıtımını sağlamak için NetBackup yazılımının gücünü son teknoloji sunucular ve depolama
            teknolojisiyle bir araya getiriyor. Kurulumla başlayan eksiksiz yaşam döngüsü desteği ve her ortamda koruma
            sağlayan tümleşik yaklaşımıyla NetBackup Appliance Çözümü, maliyetleri düşürürken kurumsal veri yönetimini
            basitleştiriyor.
          </>
        }
      ></HorizontalImageText>
      <TitleAndText title={<>İş yüklerini dağıtmanın, ölçeklendirmenin ve korumanın üç güçlü yolu..</>}></TitleAndText>
      <GridIcons vertical={true} items={gridItems}></GridIcons>
      <TitleAndText title={<>Altyapınızı optimize edin ve maliyetleri azaltın.</>}></TitleAndText>
      <PinnedInfo detailsHeight={"40vh"} items={veritasSliderItems}></PinnedInfo>
      <TitleAndText
        smallText={<>Sektörde Lider</>}
        title={<>Gartner Magic Quadrant’ta 2021 yılında lider olarak belirlendi.</>}
        btnText={<>Raporu Okuyun</>}
        url={"https://www.veritas.com/form/whitepaper/gartner-mq-data-center-backup"}
        bgColor={"#002855"}
        fontColor={"#ffffff"}
      ></TitleAndText>
    </>
  )
}
