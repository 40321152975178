import React, { useEffect, useRef } from "react";
import s from "../assets/scss/infiniteMarquee.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function InfiniteMarquee({ children }) {
  const marqueeInnerRef = useRef(null);

  useEffect(() => {
    gsap.set(marqueeInnerRef.current, { xPercent: -50 });
  }, []);

  return (
    <div className={s.marquee}>
      <div className={s.marqueeInner} ref={marqueeInnerRef} data-marquee-slide>
        {[...Array(5).keys()].map((i) => {
          return (
            <div className={s.marqueeItem} key={i}>
              {children}
            </div>
          );
        })}
      </div>
    </div>
  );
}
