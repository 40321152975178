import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import Hero from "../../../components/Hero";
import ImageWithText from "../../../components/ImageWithText";
import SolutionsList from "../../../components/SolutionsList";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { alignmentType } from "../../../utils/types";

import bannerImgV from "../../../assets/img/hero/cisco-ag-cozumleri.jpg";
import bannerVideo from "../../../assets/video/hero/cisco-ag-cozumleri.mp4";
import imgMiddle from "../../../assets/img/cisco-middle.jpg";

export default function CiscoNetwork() {
  const solutions = [
    {
      title: "Cisco Ağ Çözümleri",
      desc: "Yazılım destekli anahtarlama ve kablosuz çözümler, ağ deneyiminizi basitleştiriyor, otomatikleştiriyor ve güvenceye almanızı sağlıyor. Cisco’nun erişim ağları çözümleri arasında anahtarlama (switching), kablosuz, yazılım ve yönetim platformları yer alıyor. Cisco Access Networking ve Cisco Meraki Access ürünleri işletmelerin ağlarını bir sonraki düzeye taşımalarına olanak tanıyor.",
    },
    {
      title: "SD-WAN Çözümleri",
      desc: "Günümüzde modern işgücü daha mobil hale geldi ve işletmeler iş açısından kritik uygulamaları bulut üzerinden kullanıcılarına sunuyor. Ancak, geleneksel WAN mimarileri, bant genişliğinin tam anlamıyla kullanılamaması, güvenliğin yetersiz olması ve giderek artan karmaşıklık nedeniyle ihtiyaçları karşılayamaz bir hale geldi. Bu, BT altyapısının işletmenin ihtiyaçlarına daha hızlı karşılık vermesini engelliyor. Cisco’nun SD-WAN çözümleri işletmelerin bu ihtiyaçlarını karşılıyor ve öngörülebilir bir uygulama deneyimi sunuyor. Ayrıca kurulumu ve güvenliği kolaylaştırarak yönetimi merkezileştiriyor.",
    },
    {
      title: "Anahtarlar (Switch)",
      desc: "Cisco’nun sürekli öğrenen, uyum sağlayan ve üst düzey güvenlik sağlayan anahtarları işletmelerin günümüz gerekliliklerine uygun bir ağ oluşturmalarında kritik önem taşıyan bir bileşen olarak öne çıkıyor.",
    },
    {
      title: "Yönlendiriciler (Router)",
      desc: "Cisco'nun yönlendirme çözümleri, WAN, LAN ve bulut genelinde amaca dayalı ağ iletişimi sunuyor ve gelişmiş analitik, uygulama optimizasyonu, otomatik provizyon ve güvenlik içeriyor. Cisco’nun eksiksiz yönlendirici çözümleri, üretkenliği artırmaya, iş ihtiyaçlarını karşılamaya ve işletme maliyetlerini düşürmeye yardımcı olan yeni nesil hizmetler sunuyor.",
    },
    {
      title: "Kablosuz (Wireless)",
      desc: "Cisco’nun kablosuz ağ çözümleri, kablolu ağlarla eş düzeyde bir deneyim kalitesiyle ağ kaynaklarına istenilen yerden istenilen zamanda erişim sağlıyor.",
    },
    {
      title: "Ağ Güvenliği",
      desc: "Dünyanın en büyük kurumsal siber güvenlik teknolojileri sağlayıcılarından biri olan Cisco, SASE, XDR ve sıfır güven alanında sektörü bir sonraki adıma taşıyan çözümlere öncülük ediyor. Cisco SecureX, güvenlik altyapısına basitlik, görünürlük ve verimlilik sağlıyor.",
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Ağ Çözümleri" });
  }, [setBc]);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        video={bannerVideo}
        title={
          <>
            Cisco Ağ <br /> Çözümleri
          </>
        }
        desc={
          <>
            Cisco ağ çözümleri, işletmelerin değişime hızla uyum sağlamalarına,
            işlemleri basitleştirmelerine, ölçeklendirmelerine ve tehditlere
            karşı koruma elde etmelerine olanak tanır.
          </>
        }
        bottom={{
          title: (
            <>
              Cisco’nun ağ çözümleri portföyü, modern bir işletmenin tüm
              taleplerini karşılamak üzere tasarlanmıştır.
            </>
          ),
          text: {
            part1: (
              <>
                Sektörler ve segmentler genelinde en kapsamlı ürünlere sahip
                olan Cisco ağ çözümleri kullanıcıları, cihazları, uygulamaları
                ve iş yüklerini her yerde birbirine bağlamak üzere erişim, WAN,
                veri merkezi ve bulut ortamlarını kapsıyor ve hem şirket içi hem
                de bulut tabanlı yönetim modlarını destekliyor.
              </>
            ),
            part2: null,
          },
        }}
      ></Hero>
      <ImageWithText
        image={imgMiddle}
        alignment={alignmentType.right}
        title={
          <>
            Cisco’nun programlanabilir ağ cihazları temeli ve açık platform
            genişletilebilirliği ağın değerini önemli ölçüde artırıyor.
          </>
        }
        text={
          <>
            Politika tabanlı otomasyon, entegre güvenlik ve yapay zeka destekli
            analitik sağlıyor. Bugünün ve geleceğin yenilikçi uygulamalarının,
            süreçlerinin ve çözümlerinin kolayca devreye alınmasını sağlıyor.
            Geniş bir ekosistem sayesinde ortamların ve süreçlerin etkili bir
            şekilde entegre olmasını mümkün kılıyor.
          </>
        }
      ></ImageWithText>
      <SolutionsList preText={null} items={solutions}></SolutionsList>
    </main>
  );
}
