import React from "react";
import s from "../assets/scss/hero.module.scss";

import cx from "classnames";
import BreadCrumb from "./BreadCrumb";
import useWindowSize from "../hooks/useWindowSize";
import { breakpointTypes } from "../utils/types";

export default function Hero({
  title = "title",
  desc = "desc",
  bottom = { title: "bottom title", text: { part1: "text", part2: "text" } },
  whiteBg = false,
  circledImg = false,
  imgV = null,
  imgH = null,
  imgMobile = null,
  video = null,
}) {
  const windowSize = useWindowSize();

  return (
    <section className={cx(s.hero, { [s.whiteBg]: whiteBg })}>
      <div className={s.top}>
        <div className={s.bcWrapper}>
          <BreadCrumb></BreadCrumb>
        </div>
        <div className={s.text}>
          <h1 className={s.title}>{title}</h1>
          <h2 className={s.desc}>{desc}</h2>
        </div>

        <div className={s.imgWrapper} data-parallax-rect-inner-wrapper>
          <img
            data-parallax-rect-inner
            className={s.img}
            src={
              windowSize.width > breakpointTypes.mobile
                ? imgV
                : imgMobile ?? imgV
            }
            alt="Abstract Tech Visual"
          />
        </div>

        <div
          className={cx(s.imgWrapper2, { [s.circle]: circledImg })}
          data-parallax-rect-outer-wrapper
        >
          {video ? (
            <video
              data-parallax-rect-outer
              className={s.img}
              src={video}
              autoPlay
              loop
              muted
            />
          ) : (
            <img
              data-parallax-rect-inner
              className={s.img}
              src={imgH || imgV}
              alt="Abstract Tech Visual"
            />
          )}
        </div>
      </div>
      <div className={s.bottom}>
        <h2 className={s.desc}>{bottom.title}</h2>
        {(bottom.text.part1 || bottom.text.part2) && (
          <div className={s.texts}>
            {bottom.text.part1 && <p className={s.text}>{bottom.text.part1}</p>}
            {bottom.text.part2 && <p className={s.text}>{bottom.text.part2}</p>}
          </div>
        )}
      </div>
    </section>
  );
}
