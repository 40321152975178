import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import FooterNav from "../../../components/FooterNav";
import Hero from "../../../components/Hero";
import ImageWithText from "../../../components/ImageWithText";
import RevealWrapper from "../../../components/RevealWrapper";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";
import { appRoutes } from "../../../utils/routes";
import { alignmentType } from "../../../utils/types";

import bannerImgV from "../../../assets/img/hero/dell-depolama-cozumleri.jpg";
import imgMiddle from "../../../assets/img/dell-depolama-alt.jpg";

export default function DellStorage() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Donanım Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.hardware.children);
    navStore.setTitle("Diğer donanım çözümlerini inceleyin");
    navStore.setNavTitle("Diğer donanım çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.hardware.children.dellStorage.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        title={
          <>
            Dell Depolama <br /> Çözümleri
          </>
        }
        desc={
          <>
            Günümüzün depolama ile ilgili zorluklarının farkında olan Dell,
            doğru zamanda, doğru verilere ulaşılabilmesini sağlayan, yerleşik
            verimlilik ve çeviklik sağlayan kurumsal sınıf depolama çözümleri
            sunuyor.
          </>
        }
        bottom={{
          title: (
            <>
              Dell, modern mimariler, müşteri iş yüklerine odaklanma ve uçtan
              uca entegre sunucu, depolama, ağ, yazılım ve hizmet portföyüyle
              depolama ekonomisini yeniden tanımlıyor.
            </>
          ),
          text: {
            part1: null,
            part2: null,
          },
        }}
      ></Hero>
      <ImageWithText
        image={imgMiddle}
        alignment={alignmentType.right}
        title={
          <>
            Veri miktarının ve değerinin hızla artması nedeniyle maliyetli ve
            karmaşık depolama yaklaşımlarından uzaklaşmak kritik önem taşıyor.
          </>
        }
        text={
          <>
            Dell, daha hızlı yanıt süresi ve daha düşük toplam sahip olma
            maliyeti için iş yüklerine dayalı olarak işletmelerin doğru veri
            merkezi ve sanallaştırma stratejileri geliştirmelerine yardımcı
            oluyor. Dell’in performans açısından optimize edilmiş flaş
            teknolojilerinden ultra yoğun depolama kasalarına kadar uçtan uca
            depolama portföyü iş gereksinimlerine en uygun çözümleri sunuyor.
          </>
        }
      ></ImageWithText>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer donanım çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.hardware.children}
          hideIndex={
            appRoutes.solutions.children.hardware.children.dellStorage.type
          }
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
