import React from "react";
import s from "../assets/scss/doubleTitle.module.scss";

export default function DoubleTitle({ title1 = null, title2 = null }) {
  return (
    <div className={s.doubleTitle}>
      <h3 className={s.title}>{title1}</h3>
      <h3 className={s.title}>{title2}</h3>
    </div>
  );
}
