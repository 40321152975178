import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";

import FooterNav from "../../../components/FooterNav";
import Hero from "../../../components/Hero";
import RevealWrapper from "../../../components/RevealWrapper";
import TextL from "../../../components/TextL";
import TextWithIcon from "../../../components/TextWithIcon";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";
import { appRoutes } from "../../../utils/routes";

import bannerImgV from "../../../assets/img/hero/sanallastirma.jpg";

export default function HomeVirtual() {
  const text = [
    <>
      Ateon, sanallaştırma ve yönetim analizleri ile doğru bir sanallaştırma
      çözümü için ihtiyaçları belirliyor ve{" "}
      <span>en iyi çözümü en uygun maliyetlerle</span> sunuyor.
    </>,
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Sanallaştırma Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.virtual.children);
    navStore.setTitle("Sanallaştırma çözümlerini inceleyin");
    navStore.setNavTitle("Sanallaştırma çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.virtual.children.index.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        whiteBg={true}
        title={
          <>
            Sanallaştırma <br /> Çözümleri
          </>
        }
        desc={
          <>
            Sunucular, masaüstü bilgisayarlar ve diğer donanımları sanal hale
            getiren çözümler, yönetilebilirlik ve maliyet avantajları nedeniyle
            günümüzde giderek daha yaygın olarak kullanılıyor.
          </>
        }
        bottom={{
          title: (
            <>
              Sanallaştırma çözümleri, fiziksel donanımlara ve uygulamalara olan
              bağımlılığı azaltıyor ve özellikle yönetim ve maliyet açısından
              çok önemli avantajlar sağlıyor.
            </>
          ),
          text: {
            part1: (
              <>
                Karmaşık BT altyapısının konsolide edilmesine ve mevcut
                altyapıdan en üst düzeyde performans elde edilmesine olanak
                tanıyor.
              </>
            ),
            part2: null,
          },
        }}
      ></Hero>
      <TextWithIcon
        texts={{
          title: (
            <>
              Sanal makineler üzerinde bellek yükseltme, işlemci sayısın
              artırma, disk ekleme ve çıkarma, disk boyutunu büyütme ve azaltma,
              anlık görüntü (snapshot) alma gibi işlemler{" "}
              <span>sistemde herhangi bir kesintiye neden olmadan</span> kolayca
              yapılabiliyor.
            </>
          ),
          desc: {
            part1: (
              <>
                Sanallaştırma çözümleri sunucular, bilgisayarlar, ağlar,
                uygulamalar ve depolamalar için uygulanabiliyor.
              </>
            ),
            part2: (
              <>
                Sanallaştırma ile işletmeler mevcut donanımlarından{" "}
                <strong>maksimum verim</strong> elde ediyor, donanım yatırımları{" "}
                <strong>“gerçek değere”</strong> dönüşüyor ve sistemler en az
                hizmet kesintisi süresiyle çalışıyor. Aynı zamanda işletmenin
                büyümesiyle birlikte artan iş yüklerine{" "}
                <strong>kolayca uyum sağlanmasına</strong> olanak tanıyor.
              </>
            ),
          },
        }}
      ></TextWithIcon>
      <TextL items={text}></TextL>
      <RevealWrapper>
        <FooterNav
          title={<>Sanallaştırma çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.virtual.children}
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
