export const appRoutes = {
  about: {
    type: "about",
    ui: "Hakkımızda",
    path: "hakkimizda",
    children: null,
  },
  solutions: {
    type: "solutions",
    ui: "Çözümler",
    path: null,
    children: {
      cyberSecurity: {
        type: "cyberSecurity",
        ui: "Siber Güvenlik Çözümleri",
        path: "siber-guvenlik-cozumleri",
        children: {
          index: {
            type: "index",
            ui: "Siber Güvenlik Çözümleri",
            path: "",
            parentPath: "siber-guvenlik-cozumleri",
          },
          antivirus: {
            type: "antivirus",
            ui: "Antivirus",
            path: "antivirus",
            parentPath: "siber-guvenlik-cozumleri",
          },
          dataBreach: {
            type: "dataBreach",
            ui: "Veri Sızıntısı Önleme ve Sınıflandırma",
            path: "veri-sizintisi-onleme-ve-siniflandirma",
            parentPath: "siber-guvenlik-cozumleri",
          },
          emailSecurity: {
            type: "emailSecurity",
            ui: "Eposta Güvenliği",
            path: "eposta-guvenligi-cozumleri",
            parentPath: "siber-guvenlik-cozumleri",
          },
          trackingAndLog: {
            type: "trackingAndLog",
            ui: "İzleme ve Log Çözümleri",
            path: "izleme-ve-log-cozumleri",
            parentPath: "siber-guvenlik-cozumleri",
          },
          twoStepVerification: {
            type: "twoStepVerification",
            ui: "İki Aşamalı Kimlik Doğrulama",
            path: "iki-asamali-kimlik-dogrulama",
            parentPath: "siber-guvenlik-cozumleri",
          },
          encryption: {
            type: "encryption",
            ui: "Şifreleme Çözümleri",
            path: "sifreleme-cozumleri",
            parentPath: "siber-guvenlik-cozumleri",
          },
        },
      },
      network: {
        type: "network",
        ui: "Ağ Çözümleri",
        path: "ag-cozumleri",
        children: {
          index: {
            type: "index",
            ui: "Ağ Çözümleri",
            path: "",
            parentPath: "ag-cozumleri",
          },
          ciscoNetwork: {
            type: "ciscoNetwork",
            ui: "Cisco Ağ Çözümleri",
            path: "cisco-ag-cozumleri",
            parentPath: "ag-cozumleri",
          },
        },
      },
      hardware: {
        type: "hardware",
        ui: "Donanım Çözümleri",
        path: "donanim-cozumleri",
        children: {
          index: {
            type: "index",
            ui: "Donanım Çözümleri",
            path: "",
            parentPath: "donanim-cozumleri",
          },
          dellServer: {
            type: "dellServer",
            ui: "Dell Sunucu Çözümleri",
            path: "dell-sunucu-cozumleri",
            parentPath: "donanim-cozumleri",
          },
          dellStorage: {
            type: "dellStorage",
            ui: "Dell Depolama Çözümleri",
            path: "dell-depolama-cozumleri",
            parentPath: "donanim-cozumleri",
          },
          dellHyper: {
            type: "dellHyper",
            ui: "Dell Hiper-Bütünleşik Altyapı Çözümleri",
            path: "dell-hiper-butunlesik-altyapi-cozumleri",
            parentPath: "donanim-cozumleri",
          },
        },
      },
      virtual: {
        type: "virtual",
        ui: "Sanallaştırma Çözümleri",
        path: "sanallastirma-cozumleri",
        children: {
          index: {
            type: "index",
            ui: "Sanallaştırma Çözümleri",
            path: "",
            parentPath: "sanallastirma-cozumleri",
          },
          infrastructure: {
            type: "infrastructure",
            ui: "Altyapı Sanallaştırma",
            path: "altyapi-sanallastirma",
            parentPath: "sanallastirma-cozumleri",
          },
          desktop: {
            type: "desktop",
            ui: "Masaüstü Sanallaştırma",
            path: "masaustu-sanallastirma",
            parentPath: "sanallastirma-cozumleri",
          },
          vRealizeAndCloud: {
            type: "vRealizeAndCloud",
            ui: "vRealize ve vCloud Suite",
            path: "v-realize-ve-v-cloud-suite",
            parentPath: "sanallastirma-cozumleri",
          },
        },
      },
      management: {
        type: "management",
        ui: "Kurumsal Dosya Yönetimi",
        path: "kurumsal-dosya-yonetimi",
        children: {
          index: {
            type: "index",
            ui: "Kurumsal Dosya Yönetimi",
            path: "",
            parentPath: "kurumsal-dosya-yonetimi",
          },
          fileOrbis: {
            type: "fileOrbis",
            ui: "FileOrbis",
            path: "file-orbis",
            parentPath: "kurumsal-dosya-yonetimi",
          },
        },
      },
    },
  },
  services: {
    type: "services",
    ui: "Hizmetler",
    path: "hizmetler",
    children: {
      recruitment: {
        type: "recruitment",
        ui: "İyileştirme Hizmetleri",
        path: "iyilestirme-hizmetleri",
        parentPath: "hizmetler",
      },
      setup: {
        type: "setup",
        ui: "Kurulum Hizmetleri",
        path: "kurulum-hizmetleri",
        parentPath: "hizmetler",
      },
      advisory: {
        type: "advisory",
        ui: "Danışmanlık Hizmetleri",
        path: "danismanlik-hizmetleri",
        parentPath: "hizmetler",
      },
      technical: {
        type: "technical",
        ui: "Teknik Destek ve Bakım Hizmetleri",
        path: "teknik-destek-ve-bakim-hizmetleri",
        parentPath: "hizmetler",
      },
    },
  },
  veritas: {
    type: "veritas",
    ui: "Veritas",
    path: "veritas",
    children: {
      veritas: {
        type: "veritas",
        ui: "Veritas",
        path: "veritas",
        children: {
          veritas: {
            type: "veritas",
            ui: "Veritas",
            path: "",
          },
        },
      },
      availability: {
        type: "availability",
        ui: "Availability",
        path: "veritas/availability",
        children: {
          infoscale: {
            type: "infoScale",
            ui: "Infoscale",
            path: "infoscale",
          },
        },
      },
      compliance: {
        type: "compliance",
        ui: "Compliance",
        path: "veritas/compliance",
        children: {
          eDiscovery: {
            type: "eDiscovery",
            ui: "eDiscovery Platform",
            path: "ediscovery-platform",
          },
          enterpriseVaultCloud: {
            type: "enterpriseVaultCloud",
            ui: "Enterprise Vault.Cloud",
            path: "enterprise-vault-cloud",
          },
          enterpriseVault: {
            type: "enterpriseVault",
            ui: "Enterprise Vault",
            path: "enterprise-vault",
          },
          merge1: {
            type: "merge1",
            ui: "Merge 1",
            path: "merge1",
          },
        },
      },
      dataProtection: {
        type: "dataProtection",
        ui: "Data Protection",
        path: "veritas/data-protection",
        children: {
          backupExec: {
            type: "backupExec",
            ui: "Backup Exec",
            path: "backup-exec",
          },
          netBackupAppliance: {
            type: "netBackupAppliance",
            ui: "NetBackup Appliance",
            path: "netbackup-appliance",
          },
          netBackupSaasProtection: {
            type: "netBackupSaasProtection",
            ui: "NetBackup Saas Protection",
            path: "netbackup-saas-protection",
          },
          netBackup: {
            type: "netBackup",
            ui: "NetBackup",
            path: "netbackup",
          },
        },
      },
      insights: {
        type: "insights",
        ui: "Insights",
        path: "veritas/insights",
        children: {
          dataInsights: {
            type: "dataInsights",
            ui: "Data Insights",
            path: "data-insights",
          },
          netBackupItAnalytics: {
            type: "netBackupItAnalytics",
            ui: "NetBackup IT Analytics",
            path: "netbackup-it-analytics",
          },
        },
      },
    },
  },
  blog: {
    type: "blog",
    ui: "Blog",
    path: "blog",
    children: null,
  },
  contact: {
    type: "contact",
    ui: "İletişim",
    path: "iletisim",
    children: null,
  },

  
 language: {
    type: "language",
    ui: (
      <div style={{display:"flex",alignItems:"center",marginLeft:"auto"}}>
      <img style={{width:20,height:20}} src="http://purecatamphetamine.github.io/country-flag-icons/3x2/TR.svg" />
      <span style={{marginLeft:5}}>TR</span>
    </div>
    ),
    path: null,
    children: {
      en: {
        type: "TR",
        ui: (
          <div style={{display:"flex",alignItems:"center"}}>
          <img style={{width:20,height:20}} src="http://purecatamphetamine.github.io/country-flag-icons/3x2/TR.svg" />
<span style={{marginLeft:5}}>TR</span>
  </div>
        ),
        path: "",
        children:null,
      },
      tr: {
        type: "EN",
        ui: (
          <div style={{display:"flex",alignItems:"center"}}>
                  <img style={{width:20,height:20}} src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" />
      <span style={{marginLeft:5}}>EN</span>
          </div>
        ),
        path: "en",
        children:null
      },
    }
  },
}
