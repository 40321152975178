import React, { useEffect } from "react"

import GridIcons from "../../../components/GridIcons"
import HeroVeritas from "../../../components/HeroVeritas"
import HorizontalImageText from "../../../components/HorizontalImageText"
import PinnedInfo from "../../../components/PinnedInfo"
import TitleAndText from "../../../components/TitleAndText"

import enterpriseVaultCloud from "../../../assets/lottie/veritas/enterpriseVaultCloud.json"

import gurl from "../../../assets/img/veritas/analytics-banner.jpg"
import useBreadcrumbStore from "../../../stores/breadcrumbStore"

import analise from "../../../assets/icons/veritas/analise.svg"
import operational from "../../../assets/icons/veritas/operational.svg"
import access from "../../../assets/icons/veritas/access-appliance.svg"

import video from "../../../assets/video/bg-slider.mp4"
import DoubleTitle from "../../../components/DoubleTitle"

export default function NetBackupItAnalytics() {
  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb)

  useEffect(() => {
    setBc({ main: "Insights", sub: "NetBackup IT Analiytics" })
  }, [setBc])

  const gridItems = [
    {
      title: { icon: analise, text: "Analiz" },
      items: "Yapılandırılmamış dosyaları tarayın, değerlendirin ve veri yükümlülüklerini ortaya çıkarın.",
    },
    {
      title: { icon: operational, text: "Optimizasyon" },
      items: "Yüksek riskli içerikleri hızla bulun, sınıflandırın ve her bir dosyayı kimin kullandığını belirleyin.",
    },
    {
      title: { icon: access, text: "Hafifletme" },
      items: "Tahmine dayalı analitik ile içerik dosyalarını proaktif olarak yönetin ve kontrol altına alın.",
    },
  ]

  const veritasSliderItems = [
    {
      title: "Çoklu Bulut",
      media: video,
      pdf: null,
      detail: {
        text: "Daha fazla BT görünürlüğü ile bulut iş değerini yönetin ve optimize edin.",
        list: [
          "Bulut altyapısını doğru duruma getirerek ve optimize ederek maliyetleri azaltın",
          "Çoklu bulut yapılandırmalarından (AWS, Azure, Google vb.) tümleşik öngörüler alın",
          "Kesin maliyetleri anlayın ve genel bulut faturalarını birleştirin",
          "Tüm veri kümeleri için tek bir kayıt kaynağıyla riski azaltın (hibrit ortamlarda)",
        ],
        btnText: "Videoyu İzle",
      },
    },
    {
      title: "Veri Merkezi Optimizasyonu",
      media: null,
      pdf: null,
      detail: {
        text: "Mevcut varlıklarınızı besleyen anlamlı raporlarla yedekleme denetimini kolaylaştırın.",
        list: [
          "Mevcut SLA'ları karşılamak için BT esnekliğini optimize edin",
          "Korunmayan veri kümelerini ve siber saldırıları belirleyin",
          "Başarısız veya kısmi yedeklemeleri ortadan kaldırın",
          "Depolama altyapıları genelinde maliyetlerde %35'e kadar tasarruf edin",
          "Risk boşluklarını veya korumaya yönelik aşırı depolama maliyetlerini belirleyin",
        ],
        btnText: null,
      },
    },
    {
      title: "Depolama Optimizasyonu",
      media: video,
      pdf: null,
      detail: {
        text: "Kullanılmayan depolama alanını belirlemek ve geri almak için binlerce benzersiz veri noktası toplayın.",
        list: [
          "Depolama altyapıları genelinde maliyetlerde %35'e kadar tasarruf edin",
          "Varlık kullanımını optimize etmek için kapasite planlamasını basitleştirin",
          "Bulut ortamını modernize etmek için şirket içi tasarruflar oluşturun ve tasarrufları kullanın",
          "Tüketilen depolama kaynaklarında %24'e varan azalma elde edin",
          "Şirket içi ve bulut ortamında aracısız veri toplayıcılardan yararlanın",
        ],
        btnText: "Videoyu İzle",
      },
    },
    {
      title: "ChargeBack IT",
      media: null,
      pdf: null,
      detail: {
        text: "Tüm ortamlardan ayrıntılı harcama raporlaması.",
        list: [
          "Tüm depolama varlıklarından ayrıntılı harcama verileri alın",
          "Raporlamayı belirli gereksinimleri karşılayacak şekilde düzenleyin ve ayarlayın",
          "Tüketici maliyet analizini ve dijital ayak izini görüntüleyin",
          "Tüm departman tüketimlerinin doğru bir şekilde raporlanmasını sağlayın",
          "Hizmet Olarak Altyapı (IaaS) maliyet sorumluluğu ile optimizasyon elde edin",
        ],
        btnText: null,
      },
    },
    {
      title: "Fidye yazılımlarına karşı direnç",
      media: video,
      pdf: null,
      detail: {
        text: "Risk altındaki uygulamaları ve hizmetleri hızla belirleyin.",
        list: [
          "Tüm ortamlarda zamanlanmış yedeklemeleri izleyin ve optimize edin",
          "Etkilenen ana bilgisayarları konuma, ortama veya uygulamaya göre verimli bir şekilde bulun",
          "Mali kayıp olmadan saldırılardan hızla kurtulun",
          "Öğe düzeyinde meta veri görünürlüğünü raporlayın ve korunan verilere aktarın",
          "Eski verileri, bireysel dosyaları ve olası fidye yazılımı dosyalarını kolayca tanımlayın",
        ],
        btnText: "Videoyu İzle",
      },
    },
    {
      title: "NetBackup IT Analytics Foundation",
      media: null,
      pdf: null,
      detail: {
        text: "Hibrit ve çoklu bulut ortamlarında NetBackup operasyonları için kapsamlı BT analitiği ve raporlama. ",
        list: [
          "BT ortamınızdaki tüm anormalliklere ve tüm yedekleme hatalarına ilişkin görünürlük elde edin",
          "NetBackup ortamındaki yedeklemeleri optimize edin",
          "Sorunların hızlı bir şekilde çözülmesi için mevcut uyarıların, geçmişin ve kritik eğilimlerin tek bir yerden görün",
          "SLA'ların karşılandığından emin olmak için hatalı pozitifleri veya başarısız yedeklemeleri belirleyin",
          "Hizmet Olarak Altyapı (IaaS) maliyet sorumluluğu ile optimizasyon elde edin",
        ],
        btnText: null,
      },
    },
  ]

  return (
    <>
      <HeroVeritas
        title={<>Veritas - Çoklu bulut ortamlarında tümleşik BT analitiği ve öngörüleri.</>}
        lottie={enterpriseVaultCloud}
      ></HeroVeritas>
      <HorizontalImageText
        imgH
        image={gurl}
        title={<>BT ortamı genelinde tümleşik içgörüler.</>}
        text={
          <>
            NetBackup IT Analytics, şirket içi ve bulut hizmetlerinde heterojen BT ortamları için tümleşik yedekleme ve
            depolama öngörüleri sunuyor. Nerede olursa olsun kurumsal veriler için tek ekrandan eyleme dönüştürülebilir
            içgörüler sağlıyor.
          </>
        }
      ></HorizontalImageText>
      <DoubleTitle
        title1="BT yönetim maliyetlerinde %90 azalma."
        title2="Tüketilen kaynaklarda %24 azalma."
      ></DoubleTitle>
      <TitleAndText
        title={<>Tek platform üzerinden raporlama ile karmaşıklığı azaltın.</>}
        text={
          <>Tek bir tümleşik arabirim aracılığıyla tüm ortamlardan içgörüleri hızlı ve etkili bir şekilde toplayın.</>
        }
      ></TitleAndText>
      <GridIcons vertical={true} items={gridItems}></GridIcons>
      <PinnedInfo detailsHeight="60vh" items={veritasSliderItems}></PinnedInfo>
      <TitleAndText
        title={
          <>
            NetBackup IT Analytics Foundation, NetBackup 10 aboneliğine dahildir. Raporlama, uyarı, gösterge panoları ve
            NetBackup WebUI’ye erişim için bir alt kümedir.
          </>
        }
        text={<></>}
        bgColor={"#002855"}
        fontColor={"#ffffff"}
      ></TitleAndText>
    </>
  )
}
