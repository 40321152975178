import React from "react";
import s from "../assets/scss/orderedGrid.module.scss";

import arrowSquare from "../assets/icons/arrow-square.svg";

import cx from "classnames";
import useWindowSize from "../hooks/useWindowSize";
import { breakpointTypes } from "../utils/types";

export default function OrderedGrid({
  preText = null,
  preTextWidth = null,
  vertical = false,
  chess = false,
  items = [{ text: "", order: 0, clip: "" }],
  bgImg = null,
  leftAligned = false,
  row = 3,
  col = 3,
  color = "#1365b5",
}) {
  const windowSize = useWindowSize();

  return (
    <section className={s.orderedGrid}>
      {bgImg && (
        <div className={s.bgWrapper}>
          <img className={s.bg} src={bgImg} alt="Abstract Tech Visual"></img>
        </div>
      )}

      {preText && (
        <div
          className={cx(s.pre, {
            [s.leftAligned]: leftAligned,
            [s.vertical]: vertical,
          })}
        >
          <div className={s.arrow}>
            <img className={s.icon} src={arrowSquare} alt="Arrow" />
          </div>
          <div
            className={s.text}
            style={{
              width:
                windowSize.width > breakpointTypes.mobile
                  ? preTextWidth
                  : "100%",
            }}
          >
            {preText}
          </div>
        </div>
      )}

      <div className={s.itemsWrapper}>
        <div
          className={s.items}
          style={{
            gridTemplateColumns:
              windowSize.width > breakpointTypes.mobile
                ? `repeat(${col},1fr)`
                : "1",
            gridTemplateRows:
              windowSize.width > breakpointTypes.mobile
                ? `repeat(${row},1fr)`
                : "auto",
          }}
        >
          {items.map((item, i) => {
            return (
              <div
                className={cx(s.item, {
                  [s.topLeft]: item.clip === "topLeft",
                  [s.topRight]: item.clip === "topRight",
                  [s.bottomLeft]: item.clip === "bottomLeft",
                  [s.bottomRight]: item.clip === "bottomRight",
                  [s.bg]: chess,
                })}
                style={{
                  order: item.order,
                  visibility: item.visible === false && "hidden",
                  display:
                    windowSize.width < breakpointTypes.mobile &&
                    !item.visible &&
                    "none",
                }}
                key={i}
              >
                <small className={s.index} style={{ color }}>
                  0{item.customIndex || i + 1}
                </small>
                <p
                  className={s.text}
                  style={{
                    color:
                      windowSize.width > breakpointTypes.mobile
                        ? color
                        : "#1a1818",
                  }}
                >
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
