import { useEffect, useRef } from "react";
import s from "../assets/scss/magnetCursor.module.scss";

import gsap from "gsap";
import cx from "classnames";

// import { ReactComponent as CursorDefault } from "../assets/icons/cursor-default.svg";
// import { ReactComponent as CursorCircle } from "../assets/icons/cursor-circle.svg";

import useMousePosition from "../hooks/useMousePosition";
import useCursorStore from "../stores/cursorStore";
import { useLocation } from "react-router-dom";

export default function MagnetCursor() {
  const cursorDefaultRef = useRef(null);
  const magnetCursorRef = useRef(null);

  const {
    scaled,
    hidden,
    toggleVisibility,
    visible,
    hide,
    scale,
    circle,
    circled,
  } = useCursorStore();
  const { clientX, clientY } = useMousePosition();
  const location = useLocation();

  // control screen display
  useEffect(() => {
    const handleMouseEnter = () => {
      if (!visible) toggleVisibility();
    };
    const handleMouseLeave = () => {
      if (visible) toggleVisibility();
    };

    document.body.addEventListener("mouseenter", handleMouseEnter);
    document.body.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.body.removeEventListener("mouseenter", handleMouseEnter);
      document.body.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [visible]);

  // useEffect(() => {
  //   if (visible) {
  //     cursorDefaultRef.current &&
  //       gsap.set(cursorDefaultRef.current, {
  //         // scale: 0.2,
  //         opacity: 1,
  //         duration: 0,
  //       });
  //   }
  // }, [visible]);

  useEffect(() => {
    if (visible) {
      magnetCursorRef.current &&
        gsap.to(magnetCursorRef.current, {
          x: () => clientX - 8,
          y: () => clientY - 10,
          duration: 0,
        });
    }
  }, [clientX, clientY, visible]);

  useEffect(() => {
    if (hidden) hide();
    if (scaled) scale();
    if (circled) circle();
    if (!visible) toggleVisibility();
  }, [location]);

  // useEffect(() => {
  //   if (visible) {
  //     cursorDefaultRef.current &&
  //       gsap.to(cursorDefaultRef.current, {
  //         scale: () => (scaled ? 1.2 : 1),
  //         opacity: () => (scaled ? 0.5 : 1),
  //         duration: 0.3,
  //         ease: Power2.easeOut,
  //       });
  //   }
  // }, [scaled, visible]);

  // useEffect(() => {
  //   if (visible) {
  //     cursorDefaultRef.current &&
  //       gsap.to(cursorDefaultRef.current, {
  //         scale: () => (hidden ? 1.2 : 1),
  //         opacity: () => (hidden ? 0 : 1),
  //         duration: 0.15,
  //         ease: Power2.easeOut,
  //       });
  //   }
  // }, [hidden, visible]);

  // useEffect(() => {
  //   if (cursorCircleRef.current && cursorDefaultRef.current) {
  //     if (circled) {
  //       cursorDefaultRef.current.style.opacity = "0";
  //       cursorCircleRef.current.style.opacity = "1";
  //     } else {
  //       cursorDefaultRef.current.style.opacity = "1";
  //       cursorCircleRef.current.style.opacity = "0";
  //     }
  //   }
  // }, [circled]);

  return (
    <div className={s.cursorWrapper}>
      <div className={s.magnetCursor} ref={magnetCursorRef}>
        <div
          className={cx(s.cursorDefault, {
            [s.circled]: circled,
            [s.scaled]: scaled,
            [s.hidden]: hidden,
            [s.visible]: visible,
          })}
          alt="Cursor"
          ref={cursorDefaultRef}
        ></div>
        {/* <CursorDefault
              className={s.cursorDefault}
              alt="Cursor"
              ref={cursorDefaultRef}
            />
            <CursorCircle
              className={s.cursorCircle}
              alt="Cursor"
              ref={cursorCircleRef}
            /> */}
      </div>
    </div>
  );
}
