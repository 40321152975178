import React, { useEffect, useRef } from "react"
import s from "../assets/scss/footer.module.scss"

import { Link } from "react-router-dom"

import logo from "../assets/img/ateon-logo-white.svg"
import cerez from "../assets/pdf/cerez-politikasi.pdf"
import kvkk from "../assets/pdf/websitesi-aydinlatma-metni.pdf"
import iso from "../assets/pdf/ATEON_27001_Sertifika.pdf"

import IconLinkedin from "./Icon/IconLinkedin"
import { appRoutes } from "../utils/routes"
import useCursorStore from "../stores/cursorStore"
import useIntersection from "../hooks/useIntersection"

export default function Footer() {
  const { circle } = useCursorStore()

  // // HIDE STICKY LOGIC ON TABLET AND MOBILE
  const footerRef = useRef(null)
  const onScreen = useIntersection(footerRef)

  useEffect(() => {
    const zoho = document.querySelector("#zsiqbtn")

    if (onScreen) {
      if (zoho) zoho.style.opacity = "0"
      if (zoho) zoho.style.visibility = "hidden"
    } else {
      if (zoho) zoho.style.opacity = "1"
      if (zoho) zoho.style.visibility = "visible"
    }
  }, [onScreen])

  return (
    <footer className={s.footer} ref={footerRef}>
      <div className={s.top}>
        <div className={s.left}>
          <div className={s.col}>
            <Link to="/">
              <div className={s.logoWrapper}>
                <img className={s.logo} src={logo} alt="Ateon Logo" />
              </div>
            </Link>
          </div>
        </div>

        <div className={s.right}>
          <div className={s.col}>
            <h6 className={s.title}>Kurumsal</h6>
            <div className={s.links}>
              <Link className={s.link} to="/hakkimizda">
                Hakkımızda
              </Link>
              <Link className={s.link} to="/iletisim">
                İletişim
              </Link>
              <a href={kvkk} target="_blank" rel="noreferrer" className={s.link}>
                KVKK Aydınlatma Metni
              </a>
              <a className={s.link} href={cerez} target="_blank" rel="noreferrer">
                Çerez Politikası
              </a>
              <a className={s.link} href={iso} target="_blank" rel="noreferrer">
                ISO 27001 Belgesi
              </a>
              <Link className={s.link} to="/blog">
                Blog
              </Link>
            </div>
          </div>
          <div className={s.col}>
            <h6 className={s.title}>{appRoutes.solutions.ui}</h6>
            <div className={s.links}>
              {Object.values(appRoutes.solutions.children).map((value, i) => {
                return (
                  <Link className={s.link} to={value.path} key={i}>
                    {value.ui}
                  </Link>
                )
              })}
            </div>
          </div>
          <div className={s.col}>
            <h6 className={s.title}>{appRoutes.services.ui}</h6>
            <div className={s.links}>
              {Object.values(appRoutes.services.children).map((value, i) => {
                return (
                  <Link className={s.link} to={value.parentPath + "/" + value.path} key={i}>
                    {value.ui}
                  </Link>
                )
              })}
            </div>
          </div>
          <div className={s.col}>
            <h6 className={s.title}>{appRoutes.veritas.ui}</h6>
            <div className={s.links}>
              {Object.values(appRoutes.veritas.children).map((value, i) => {
                if (value.type === "veritas") {
                  return (
                    <Link className={s.link} to={value.path} key={i}>
                      {value.ui}
                    </Link>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>

      <div className={s.middle}>
        <div className={s.left}>
          <div className={s.col}>
            <div className={s.addressWrapper}>
              <h6 className={s.title}>Adres:</h6>
              <div className={s.links}>
                <p className={s.link}>Meydan İstanbul AVM, Balkan Cad. JUSTWork Ofis Kampüs NO:62</p>
                <a
                  onMouseEnter={() => circle()}
                  onMouseLeave={() => circle()}
                  className={s.map}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/place/Ateon+Bilgi+Teknolojileri/@41.0233974,29.1270844,15z/data=!4m5!3m4!1s0x0:0x8cecab889355e119!8m2!3d41.0233974!4d29.1270844"
                  style={{ cursor: "pointer" }}
                >
                  Yol Tarifi Al
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={s.right}>
          <div className={`${s.col} ${s.phone}`}>
            <h6 className={s.title}>Telefon:</h6>
            <div className={s.links}>
              <a onMouseEnter={() => circle()} onMouseLeave={() => circle()} className={s.link} href="tel:02169122566">
                0216 912 25 66
              </a>
            </div>
          </div>
          <div className={`${s.col} ${s.email}`}>
            <h6 className={s.title}>Eposta:</h6>
            <div className={s.links}>
              <a
                onMouseEnter={() => circle()}
                onMouseLeave={() => circle()}
                className={s.link}
                href="mailto:bilgi@ateon.com.tr"
              >
                bilgi@ateon.com.tr
              </a>
            </div>
          </div>
        </div>
        <div className={s.socialMobile}>
          <a href="https://www.linkedin.com/company/ateon-bt" target="_blank" rel="noreferrer">
            <IconLinkedin fill="#fff" className={s.icon}></IconLinkedin>
          </a>
        </div>
      </div>

      <div className={s.bottom}>
        <div className={s.copyright}>
          <small className={s.cText}>
            <span className={s.text}>© Ateon Bilgi Teknolojileri 2022</span> <span className={s.seperator}>|</span>{" "}
            <span className={s.text}>Tüm Hakları Saklıdır</span> <span className={s.seperator}>|</span>{" "}
            <span className={s.text}>
              {" "}
              Made by{" "}
              <a
                onMouseEnter={() => circle()}
                onMouseLeave={() => circle()}
                className={s.link}
                href="https://justdesignfx.com"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                JUST DESIGN FX®
              </a>
            </span>{" "}
          </small>
        </div>
        <div className={s.social}>
          <a
            onMouseEnter={() => circle()}
            onMouseLeave={() => circle()}
            href="https://www.linkedin.com/company/ateon-bt"
            target="_blank"
            rel="noreferrer"
          >
            <IconLinkedin className={s.icon}></IconLinkedin>
          </a>
        </div>
      </div>
    </footer>
  )
}
