import React from "react";
import s from "../assets/scss/solutionsList.module.scss";

import arrowSquare from "../assets/icons/arrow-square.svg";

import cx from "classnames";

export default function SolutionsList({
  preText = "pre text",
  items = [{ title: "title", desc: "desc" }],
  blueBg = false,
  leftAligned = false,
}) {
  return (
    <section className={cx(s.solutionsList, { [s.blueBg]: blueBg })}>
      {preText && (
        <div className={cx(s.pre, { [s.leftAligned]: leftAligned })}>
          <div className={s.arrow}>
            <img className={s.icon} src={arrowSquare} alt="Arrow" />
          </div>
          <div className={s.text}>{preText}</div>
        </div>
      )}

      {items.length &&
        items.map((item, i) => {
          return (
            <div className={s.listItem} key={i}>
              <div className={s.indexWrapper}>
                <small className={s.itemIndex}>0{i + 1}</small>
              </div>
              <h2 className={s.title}>{item.title}</h2>
              <p className={s.desc}>{item.desc}</p>
            </div>
          );
        })}
    </section>
  );
}
