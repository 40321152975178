import React, { useEffect } from "react";
import s from "../../../assets/scss/solutions.module.scss";
import FooterNav from "../../../components/FooterNav";

import Hero from "../../../components/Hero";
import RevealWrapper from "../../../components/RevealWrapper";
import SolutionsList from "../../../components/SolutionsList";
import useBreadcrumbStore from "../../../stores/breadcrumbStore";
import { useStickyNavStore } from "../../../stores/stickyNavStore";
import { appRoutes } from "../../../utils/routes";

import bannerImgV from "../../../assets/img/hero/sanallastirma-vrealize.jpg";

export default function VRealizeAndCloud() {
  const advantages = [
    {
      title: <>Altyapı İçin DevOps</>,
      desc: (
        <>
          Kod olarak altyapı, altyapı üretim hatları, GitOps, yayın otomasyonu
          ve kod olarak ardışık düzen ile DevOps tabanlı altyapı sunuyor.
        </>
      ),
    },
    {
      title: <>Modern Altyapı Otomasyonu</>,
      desc: (
        <>
          Onay politikaları, API’ler aracılığıyla tedarik, bildirime dayalı
          durum zorlama, iş akışı düzenlemesi, 2. gün otomasyonu ve otomatik
          iyileştirme BT hizmetlerinin yaşam döngüsü boyunca yönetimi
          otomatikleştiriyor.
        </>
      ),
    },
    {
      title: <>Otonom Operasyonlar</>,
      desc: (
        <>
          Uygulamaların farkında olan akıllı iyileştirme ve entegre uyumluluk,
          uygulamalardan bulut ortamındaki altyapıya kadar performansı,
          kapasiteyi ve maliyetleri sürekli optimize ediyor.
        </>
      ),
    },
    {
      title: <>Veri Merkezi Sanallaştırma (Yalnızca vCloud Suite)</>,
      desc: (
        <>
          vRealize Suite’e vSphere eklendiğinde uygulama odaklı yönetim ve içsel
          güvenlik üretkenliği artırıyor ve yapay zeka, makine öğrenimi,
          veritabanı, analitik ve zaman açısından kritik uygulamalar genelinde
          inovasyonları hızlandırıyor.
        </>
      ),
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Çözümler", sub: "Sanallaştırma Çözümleri" });
    navStore.setChildren(appRoutes.solutions.children.virtual.children);
    navStore.setTitle("Diğer sanallaştırma çözümlerini inceleyin");
    navStore.setNavTitle("Diğer sanallaştırma çözümlerini inceleyin");
    navStore.setHiddenChild(
      appRoutes.solutions.children.virtual.children.vRealizeAndCloud.type
    );
  }, []);

  return (
    <main className={s.solutions}>
      <Hero
        imgV={bannerImgV}
        whiteBg={true}
        title={
          <>
            vRealize ve <br /> vCloud Suite
          </>
        }
        desc={
          <>
            VMware Cloud ve ötesi için bir self servis tüketim katmanı, bir
            otomasyon çerçevesi ve kendi kendine çalışan 2. Gün operasyonları
            sunuyor.
          </>
        }
        bottom={{
          title: (
            <>
              Bulut yönetim çözümü olan VMware vRealize Suite, otomasyon,
              operasyonlar, günlük analitikler ve şirket içi yaşam döngüsü
              yönetimi sağlayan VMware vRealize Bulut Yönetimi ürünlerini
              entegre ediyor.
            </>
          ),
          text: {
            part1: (
              <>
                Kurumsal düzeyde bulut altyapı ve yönetim çözümü olan VMware
                vCloud Suite ise vRealize Suite’i sektör lideri bilgi işlem
                sanallaştırma platformu olan vSphere ile birleştiriyor.
              </>
            ),
            part2: null,
          },
        }}
      ></Hero>

      <SolutionsList
        preText={
          <>
            vRealize ve vCloud Suite, çeşitli avantajları beraberinde getiriyor.
          </>
        }
        items={advantages}
      ></SolutionsList>
      <RevealWrapper>
        <FooterNav
          title={<>Diğer sanallaştırma çözümlerini inceleyin</>}
          items={appRoutes.solutions.children.virtual.children}
          hideIndex={"vRealizeAndCloud"}
        ></FooterNav>
      </RevealWrapper>
    </main>
  );
}
