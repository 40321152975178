import React from "react";

export default function IconClose({
  fill = "#000000",
  width = "100%",
  height = "100%",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14.717 14.717"
    >
      <path
        id="Path_6643"
        data-name="Path 6643"
        d="M6.836-6.546v-4.366h4.222v-1.5H6.836v-4.346h-1.5v4.346h-4.2v1.5h4.2v4.366Z"
        transform="translate(-5.429 11.053) rotate(45)"
        fill={fill}
      />
    </svg>
  );
}
