import React, { useEffect } from "react";
import HeroServices from "../../components/HeroServices";
import s from "../../assets/scss/services.module.scss";

import OrderedGrid from "../../components/OrderedGrid";

import shapeBg from "../../assets/img/shapes/shape-ring-l.png";
import ContactUs from "../../components/ContactUs";
import RevealWrapper from "../../components/RevealWrapper";
import FooterNav from "../../components/FooterNav";
import { appRoutes } from "../../utils/routes";
import useBreadcrumbStore from "../../stores/breadcrumbStore";
import sphereWhite from "../../assets/video/sphere-white.mp4";
import { useStickyNavStore } from "../../stores/stickyNavStore";

import heroImg from "../../assets/img/hero-services/iyilestirme.jpg";

export default function Recruitment() {
  const gridItems = [
    {
      text: "Toplam BT altyapı harcamalarının azalması.",
      clip: null,
      order: 0,
      visible: true,
      customIndex: 1,
    },
    {
      text: "Altyapıdan en iyi şekilde faydalanılabilmesi için BT operasyonlarını kolaylaştırması.",
      clip: null,
      order: 1,
      visible: true,
      customIndex: 2,
    },
    {
      text: "",
      clip: null,
      order: 2,
      visible: false,
    },
    {
      text: "",
      clip: null,
      order: 3,
      visible: false,
    },
    {
      text: "Toplam sahip olma maliyetinin azalması.",
      clip: null,
      order: 4,
      visible: true,
      customIndex: 3,
    },
    {
      text: "Operasyonel sorunların giderilmesi.",
      clip: null,
      order: 5,
      visible: true,
      customIndex: 4,
    },
  ];

  const setBc = useBreadcrumbStore((state) => state.setBreadcrumb);
  const navStore = useStickyNavStore();

  useEffect(() => {
    setBc({ main: "Hizmetler" });
    navStore.setChildren(appRoutes.services.children);
    navStore.setTitle("Diğer hizmetlerimizi inceleyin");
    navStore.setNavTitle("Diğer hizmetlerimizi inceleyin");
    navStore.setHiddenChild(appRoutes.services.children.recruitment.type);
  }, [setBc]);

  return (
    <main className={s.services}>
      <HeroServices
        image={heroImg}
        title={<>İyileştirme Hizmetleri</>}
        video={sphereWhite}
        desc={
          <>
            Günümüzde teknoloji ve BT altyapısı, yalnızca işlerin kesintisiz bir
            şekilde yürütülmesi için değil aynı zamanda rekabette öne çıkmak ve
            hatta hayatta kalmak için işletmelerin en önemli varlıklarından biri
            haline geldi.
          </>
        }
        bottom={{
          title: (
            <>
              Yeni teknolojik gelişmelerle beraber eskiden 5-10 yıl
              kullanılabilen sistemlerin günümüzde <span>2-5 yıl</span> arasında
              güncellenmesi gerekmektedir.
            </>
          ),
          texts: {
            part1: (
              <>
                Bu nedenle altyapıdan maksimum verim alınabilmesi için BT
                altyapısının düzenli olarak güncellenmesi ve optimizasyonu büyük
                önem taşıyor.
              </>
            ),
            part2: (
              <>
                Yeni yatırımlar için artan maliyet baskısı da BT altyapısının
                çok daha verimli kullanılmasını zorunlu kılıyor. Bu nedenle BT
                altyapı optimizasyonu birçok işletmenin en önemli gündem
                maddeleri arasında yer alıyor.
              </>
            ),
          },
        }}
      ></HeroServices>
      <OrderedGrid
        preText={
          <>
            BT altyapısında ve süreçlerinde yapılan iyileştirmelerin faydaları
          </>
        }
        preTextWidth="50vw"
        vertical
        items={gridItems}
        chess={false}
        bgImg={shapeBg}
        leftAligned={true}
        row={2}
        col={3}
      ></OrderedGrid>
      <RevealWrapper>
        <ContactUs
          text={
            <>
              Ateon olarak müşterilerimizin mevcut BT altyapılarından en iyi
              faydayı elde etmeleri ve verimliliği artırmaları için uygulama ve
              danışmanlık hizmetleri veriyoruz.
            </>
          }
        ></ContactUs>
      </RevealWrapper>
      <FooterNav
        title={<>Diğer hizmetlerimizi inceleyin</>}
        items={appRoutes.services.children}
        hideIndex={appRoutes.services.children.recruitment.type}
      ></FooterNav>
    </main>
  );
}
